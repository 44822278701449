/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import {
  HomeMenuComponent,
  PathNode,
  SubMenu,
} from './../home-menu/home-menu.component';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { SwitchType } from 'src/model/app.switchType';
import { User, userTypeString } from '../advanced/advanced-user/user';
import { Firmware } from '../advanced/advanced-general/firmware';
import { DeviceManager } from 'src/model/app.deviceManager';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(HomeMenuComponent) menu!: HomeMenuComponent;

  public component = new SwitchType(16, ComponentType.HOME);

  public available_firmware_table: Map<number, Firmware> = new Map<
    number,
    Firmware
  >();

  public title_path: PathNode[] = [];

  public user_type: userTypeString = userTypeString.enduser;
  public user_name: string = '';
  public user_email: string = ' ';
  public user_initials: string = ' ';
  public is_developer: boolean = false;

  protected ComponentType = ComponentType;
  protected SubMenu = SubMenu;

  private interval_home: any;

  constructor(
    protected app: AppRoutingModule,
    protected devMan: DeviceManager,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.app.check_session('home');
    this.get_user_parameters();
    this.get_available_firmware();
  }

  /**
   * Update token every minute
   */
  ngOnInit(): void {
    this.interval_home = setInterval(() => {
      this.app.check_session('home');
    }, 60 * 1000);
  }

  ngAfterViewInit(): void {
    this.change_component(ComponentType.HOME);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_home);
    this.app.sessionOut();
  }

  public change_component(component: ComponentType) {
    // Update component type, title path, and path
    this.component.change_types(component);
    this.title_path = this.menu.getPathByComponentType(component);
    this.menu.outnav(); // Ensure the menu is updated
  }

  public get_user_parameters() {
    this.http
      .post<any>(this.app.server_domain + '/user_setting.php', {
        request: 'get_user_type_info',
        token: this.app.token,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            console.log('Internal server error');
            return;
          }
          let tmp = this.splitMulti(result[0], [
            '=',
            ';',
            ':',
            '.',
            '-',
            '_',
            ',',
            ' ',
          ]);
          this.user_initials =
            tmp.length > 0 ? tmp[0].substring(0, 1).toUpperCase() : ' ';
          if (tmp.length > 1)
            this.user_initials =
              this.user_initials + tmp[1].substring(0, 1).toUpperCase();
          this.user_name = result[0];
          this.user_email = result[1];
          this.is_developer = result[2];
          this.user_type = User.user_type_from_number_to_string(result[3]);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  protected view_path() {
    let w = window.innerWidth;
    if (w > 1200) {
      return true;
    }
    return false;
  }

  protected change_folder(folder: string) {
    for (let i = this.title_path.length - 1; i >= 0; i--) {
      const node = this.title_path[i];

      if (node.label === folder) {
        if (node.componentType != undefined)
          this.change_component(node.componentType);
        break;
      }
      node.goBack();
    }
  }

  private async get_available_firmware() {
    this.http
      .post<String | boolean>(this.app.server_domain + '/advanced.php', {
        request: 'get_update_firmware',
        token: this.app.token,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            //server error
            return;
          }
          if (result == null)
            result = JSON.stringify(new Map<number, Firmware>());
          let tmp = JSON.parse(JSON.stringify(result));

          for (const key in tmp) {
            let k = Number(key);
            this.available_firmware_table.set(
              k,
              new Firmware(
                tmp[k].firmware_name,
                tmp[k].url,
                tmp[k].firmware_signature,
                tmp[k].certificate,
                tmp[k].firmware_version,
                tmp[k].firmware_architecture,
                tmp[k].firmware_branch,
                tmp[k].firmware_upload_time
              )
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private splitMulti(str: string, tokens: string[]) {
    var tempChar = tokens[0]; // We can use the first token as a temporary join character
    for (var i = 1; i < tokens.length; i++) {
      str = str.split(tokens[i]).join(tempChar);
    }
    return str.split(tempChar);
  }
}

export enum ComponentType {
  HOME,
  DEVICE_LIST,
  BATTERY_DETAILS,
  PROFILE,
  PASSWORD,
  SETTINGS,
  ADMIN_AREA,
  LOG_ACCESS,
  SERVER_CONSOLE,
  MANAGE_USERS,
  MANAGE_DEVICES,
  DASHBOARD_ADMIN_AREA,
  ADVANCED_CONSOLE,
  DEVICE_SETTINGS,
  ADVANCED_GENERAL,
  ADVANCED_TOOLS,
}
