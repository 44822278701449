<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->

<div id="body">
  <div class="central_block">
    <div>
      <p class="title">Change password</p>
    </div>
    <div class="form" (window:keypress)="this.keyDownFunction($event)">
      <div>
        <p class="end_label">
          After a successful password update, you will be redirected to the
          login page where you can log in with your new password.
        </p>
      </div>
      <div *ngIf="this.update_token">
        <p class="label">Current password</p>
        <p class="required">*</p>
      </div>
      <input
        *ngIf="this.update_token"
        id="old_password"
        type="password"
        class="input"
        (input)="
          this.data_registration.parameter_required(this.index.old_password);
          this.data_registration.warning.setViewWarning(false)
        "
      />
      <p
        class="message"
        *ngIf="
          this.data_registration.data_required_check[this.index.old_password] &&
          this.update_token
        "
      >
        This field is required
      </p>

      <div>
        <p class="label">New password</p>
        <p class="required">*</p>
      </div>
      <input
        id="new_password"
        type="password"
        class="input"
        (input)="
          this.data_registration.parameter_required(this.index.new_password);
          this.data_registration.password_confirmation(
            this.data_registration.data_required[this.index.new_password],
            this.data_registration.data_required[this.index.password_c]
          );
          this.data_registration.warning.setViewWarning(false)
        "
      />
      <p
        class="message"
        *ngIf="
          this.data_registration.data_required_check[this.index.new_password]
        "
      >
        This field is required
      </p>
      <div>
        <p class="label">Repeat new password</p>
        <p class="required">*</p>
      </div>
      <input
        id="password_c"
        type="password"
        class="input"
        (input)="
          this.data_registration.parameter_required(this.index.password_c);
          this.data_registration.password_confirmation(
            this.data_registration.data_required[this.index.new_password],
            this.data_registration.data_required[this.index.password_c]
          )
        "
      />
      <p
        class="message"
        *ngIf="
          this.data_registration.data_required_check[this.index.password_c] &&
          !this.data_registration.password_confirmation_bool
        "
      >
        This field is required
      </p>
      <p
        class="message"
        *ngIf="this.data_registration.password_confirmation_bool"
      >
        Password doesn't match
      </p>
      <div>
        <button class="submit" (click)="this.onSubmit()">Save password</button>
      </div>
    </div>
    <app-warning
      [title]="'Server response'"
      [background_color]="data_registration.warning.getWarningBackgroundColor()"
      [message]="data_registration.warning.getWarningMessage()"
      [warning]="data_registration.warning.getViewWarning()"
      (response)="data_registration.warning.setViewWarning($event)"
    ></app-warning>
  </div>
</div>
