/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
export class Firmware {
  public firmware_name = '';
  public url = '';
  public firmware_signature = '';
  public certificate = '';
  public firmware_version = '';
  public firmware_architecture = '';
  public firmware_branch = '';
  public firmware_upload_time = 0;

  constructor(
    firmware_name = '',
    url = '',
    firmware_signature = '',
    certificate = '',
    firmware_version = '',
    firmware_architecture = '',
    firmware_branch = '',
    firmware_upload_time = 0
  ) {
    this.firmware_name = firmware_name;
    this.url = url;
    this.firmware_signature = firmware_signature;
    this.certificate = certificate;
    this.firmware_version = firmware_version;
    this.firmware_architecture = firmware_architecture;
    this.firmware_branch = firmware_branch;
    this.firmware_upload_time = firmware_upload_time;
  }
}
