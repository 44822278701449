/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ManageUsers } from '../manage-users/manageUsers';
import { ManageDevices } from '../manage-devices/ManageDevices';
import { ComponentType, HomeComponent } from 'src/app/home/home.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public users: ManageUsers = new ManageUsers(this.app, this.http);
  public devices: ManageDevices = new ManageDevices(this.app, this.http);

  public interval_dashboard: any;
  public ComponentType = ComponentType;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public home: HomeComponent
  ) {
    this.users.get_log_access_list();
    this.devices.get_devices_list();
  }
  ngOnInit(): void {
    this.interval_dashboard = setInterval(() => {
      this.users.get_log_access_list();
      this.devices.get_devices_list();
    }, 9 * 60 * 1000);
  }
  ngOnDestroy(): void {
    clearInterval(this.interval_dashboard);
  }
}
