<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div class="menu_block">
  <div id="toggle" class="openclose">
    <a (click)="nav()">
      <img
        class="icon"
        src="../../assets/icon/home-menu/toggle_menu.png"
        alt=""
      />
    </a>
  </div>
  <div id="menu" class="openclose" (window:resize)="menu_resize_big_screen()">
    <div id="logo">
      <img src="../../assets/logo-archimede-b.png" alt="" />
    </div>
    <div style="height: 100px"></div>

    <br />
    <ng-container *ngFor="let level of menuLevels">
      <div [id]="level.id" [class]="level.class">
        <ng-container *ngFor="let item of level.nodes">
          <app-home-menu-button
            *ngIf="getIsShown(item)"
            [class]="'hidden_button'"
            [background_color]="'#565556'"
            [hover_background_color]="'#6E6D6F'"
            [is_active]="getIsActive(item)"
            [is_disable]="getIsDisable(item)"
            [label]="item.label ? item.label : ''"
            [icon_src]="item.iconSrc ? item.iconSrc : ''"
            (click)="getOnClick(item)"
          ></app-home-menu-button>
        </ng-container>
      </div>
    </ng-container>
    <table class="user_table_info">
      <tr>
        <td class="user_info">
          <div class="circle_initials">{{ home.user_initials }}</div>
        </td>
      </tr>
      <tr>
        <td class="user_info name_td">{{ home.user_name }}</td>
      </tr>
      <tr>
        <td class="user_info mail_td">{{ home.user_email }}</td>
      </tr>
      <tr>
        <td class="user_info">
          <div
            class="user_type"
            [ngStyle]="{ 'background-color': backgroundUser() }"
            (click)="view_user_setup = true"
          >
            {{ home.user_type.toUpperCase() }}
          </div>
        </td>
      </tr>
    </table>

    <button class="logout" (click)="app.logout()">
      <img
        class="menu_icon_logout"
        src="../../assets/icon/home-menu/logout.png"
        alt=""
      />Log out
    </button>
    <label class="version">{{ app.version }}</label>
  </div>
</div>
<app-advanced-user
  [view_option]="view_user_setup"
  (close)="view_user_setup = $event"
  (user)="home.user_type = $event"
></app-advanced-user>
