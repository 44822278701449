<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div
  class="central_block"
  *ngIf="this.view_option"
  (window:resize)="this.set_option()"
>
  <div>
    <button class="circle" id="circle">
      <img
        class="icon_close"
        src="../../../assets/icon/close.svg"
        (click)="this.close.emit(false)"
      />
    </button>
  </div>
  <div class="form">
    <app-option
      [title]="'Tools'"
      [subTitle]="'Select the tool.'"
      [button_index]="this.button_index"
      [tools_imgs]="this.tools_imgs"
      [tools_imgs_over]="this.tools_imgs_over"
      [is_over]="this.is_over"
      [tools_title]="this.tools_title"
      [tools_label]="this.tools_label"
      [disable]="this.disable"
      (close)="this.close.emit(false)"
      (command)="this.command.emit($event)"
    ></app-option>
  </div>
</div>
