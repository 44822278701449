/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Map } from '../../../model/app.map';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClient } from '@angular/common/http';
import { Command } from 'src/app/dashboard-menu/command';
import { AppDate } from 'src/model/app.date';
import {
  DeviceInfo,
  DeviceManager,
  DeviceMethods,
} from 'src/model/app.deviceManager';

@Component({
  selector: 'app-battery-info',
  templateUrl: './battery-info.component.html',
  styleUrls: ['./battery-info.component.css'],
})
export class BatteryInfoComponent implements OnInit, OnDestroy {
  public map: Map = new Map();
  public dev_methods: DeviceMethods = new DeviceMethods();

  public is_empty: boolean = true;
  public gpio: GPIO[] = [];

  public menu_string_option: string[] = ['sync'];
  public menu_options: boolean[] = [false];
  public menu_icons: string[] = ['../../assets/icon/advanced/sync_white.svg'];

  public interval: any;

  constructor(
    public devMan: DeviceManager,
    public app: AppRoutingModule,
    public http: HttpClient
  ) {}

  ngOnInit() {
    this.map.clearMap();
    this.map.addMarker(
      this.devMan.device.devInfo.lat,
      this.devMan.device.devInfo.lng,
      this.devMan.device.sn + '-' + this.devMan.device.alias
    );

    this.map.setMap();
    this.get_gpio();
    this.get_info();
    this.interval = setInterval(() => {
      this.get_info();
    }, 9 * 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  public device_type() {
    let code = this.devMan.device.sn.substring(0, 2);
    return this.dev_methods.device_type_name(code);
  }

  private get_info() {
    this.http
      .post<DeviceInfo | boolean>(this.app.server_domain + '/device.php', {
        request: 'device_info',
        token: this.app.token,
        sn: this.devMan.device.sn,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            return;
          }
          this.devMan.device.devInfo = result;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private get_gpio() {
    this.http
      .post<GPIO[] | boolean>(this.app.server_domain + '/device.php', {
        request: 'device_gpio',
        token: this.app.token,
        sn: this.devMan.device.sn,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            return;
          }
          if (result.length == 0) {
            this.is_empty = true;
            return;
          }
          this.is_empty = false;
          this.gpio = result;
        },
        (error) => {
          console.error(error);
        }
      );
  }
  public command(command: Command = new Command('', [false])) {
    switch (command.command) {
      case 'sync':
        this.devMan.tools.synchronize();
        break;
    }
  }
  public lastUpdate(timestamp: string) {
    let date = new Date(Number(timestamp));

    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum_hour2_minute2
    );
  }
}

class GPIO {
  public name: string = '';
  public alias: string = '';
  public value: number = 0;
  constructor(){}
}
