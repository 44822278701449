/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AppDate } from 'src/model/app.date';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements AfterContentInit, OnChanges {
  public days: Day[][] = [];

  public day_of_week: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  public mL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public mS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  public selected: number = 0;
  public date: Date = new Date();
  public format_date: string = '';

  @Input() value: Date | null = null;
  @Input() max: Date | null = null;
  @Input() min: Date | null = null;

  @Input() with_events: boolean = false;
  @Input() events: Date[] = [];
  @Input() color: string = '#50a17c';

  @Output() return = new EventEmitter<Date>();
  @Output() close = new EventEmitter<boolean>();
  @Output() update_calendar = new EventEmitter<Date>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.calendar();
  }

  ngAfterContentInit(): void {
    if (this.value == null) {
      this.value = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate()
      );
    } else {
      this.date = new Date(
        this.value.getFullYear(),
        this.value.getMonth(),
        this.value.getDate()
      );
    }
    this.update_calendar.emit(this.date);
    this.calendar();
  }

  public switch(response: string) {
    switch (response) {
      case 'left':
        if (this.min != null) {
          const tmpDate = new Date(
            this.date.getFullYear(),
            this.date.getMonth() - 1
          );
          const tmpDateMin = new Date(
            this.min.getFullYear(),
            this.min.getMonth()
          );
          if (tmpDate.getTime() < tmpDateMin.getTime()) return;
        }
        this.date.setMonth(this.date.getMonth() - 1);
        break;
      case 'right':
        if (this.max != null) {
          const tmpDate = new Date(
            this.date.getFullYear(),
            this.date.getMonth() + 1
          );
          const tmpDateMax = new Date(
            this.max.getFullYear(),
            this.max.getMonth()
          );
          if (tmpDate.getTime() > tmpDateMax.getTime()) return;
        }
        this.date.setMonth(this.date.getMonth() + 1);
        break;
    }
    this.update_calendar.emit(this.date);
  }

  calendar() {
    this.createCalendar();
    this.setValue();
    this.setMin();
    this.setMax();
  }
  private createCalendar() {
    const upperbound = new Date(this.date);
    upperbound.setMonth(upperbound.getMonth() + 1);
    const lowerbound = new Date(this.date);
    lowerbound.setMonth(lowerbound.getMonth() - 1);

    const maxDaysInCurrentMonth = AppDate.daysInMonth(
      this.date.getMonth(),
      this.date.getFullYear()
    );
    const firstDayOfWeek = new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      1
    ).getDay();
    const lastDayOfWeek = new Date(
      this.date.getFullYear(),
      this.date.getMonth() + 1,
      0
    ).getDay();

    const daysToAddBefore = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;
    const daysToAddAfter = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;

    const totalDays = maxDaysInCurrentMonth + daysToAddBefore + daysToAddAfter;
    const totalWeeks = Math.ceil(totalDays / 7);

    this.days = Array.from({ length: totalWeeks }, () => Array(7).fill({}));

    let dayIndex = 1;
    for (let week = 0; week < totalWeeks; week++) {
      for (let day = 0; day < 7; day++) {
        // Previous month days
        if (week === 0 && day < daysToAddBefore) {
          const prevDate = new Date(lowerbound);
          prevDate.setDate(
            AppDate.daysInMonth(
              lowerbound.getMonth(),
              lowerbound.getFullYear()
            ) -
              daysToAddBefore +
              day +
              1
          );
          this.days[week][day] = {
            date: prevDate,
            type: false,
            is_selected: false,
            there_is_mark: false,
          };
        }
        // Current month days
        else if (dayIndex <= maxDaysInCurrentMonth) {
          const currDate = new Date(
            this.date.getFullYear(),
            this.date.getMonth(),
            dayIndex
          );
          this.days[week][day] = {
            date: currDate,
            type: true,
            is_selected: false,
            there_is_mark: this.events.some((markDate) =>
              AppDate.isSameDay(markDate, currDate)
            ),
          };
          dayIndex++;
        }
        // Next month days
        else if (week === totalWeeks - 1 && day >= 7 - daysToAddAfter) {
          const nextDate = new Date(upperbound);
          nextDate.setDate(dayIndex - maxDaysInCurrentMonth);
          this.days[week][day] = {
            date: nextDate,
            type: false,
            is_selected: false,
            there_is_mark: false,
          };
          dayIndex++;
        }
      }
    }
  }

  public select(day: Day, type: boolean) {
    if (!type) return;

    const selectedIndex = this.days
      .flat()
      .findIndex((x) => AppDate.isSameDay(x.date, day.date));

    if (this.selected >= 0) {
      const previouslySelectedDay = this.days.flat()[this.selected];
      if (previouslySelectedDay) previouslySelectedDay.is_selected = false;
    }

    this.selected = selectedIndex;
    this.days.flat()[this.selected].is_selected = true;

    this.value = new Date(day.date); // Set value to the selected date
    this.formatDate();
  }

  private setValue() {
    if (this.value == null) return;

    for (const day of this.days.flat()) {
      if (day.type && AppDate.isSameDay(day.date, this.value)) {
        this.select(day, true);
      }
    }
  }

  private setMin() {
    if (this.min == null) return;

    for (const day of this.days.flat()) {
      if (day.type && day.date.getTime() < this.min.getTime()) {
        day.type = false;
      }
    }
  }

  private setMax() {
    if (this.max == null) return;

    for (const day of this.days.flat()) {
      if (day.type && day.date.getTime() > this.max.getTime()) {
        day.type = false;
      }
    }
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.ok();
    }
  }

  public ok() {
    this.return.emit(this.value!);
    this.close.emit(false);
  }

  private formatDate() {
    if (this.value == null) return;
    this.format_date = AppDate.stringFromDate(
      this.value,
      AppDate.format_day2_monthshort_yearnum
    );
  }
}

interface Day {
  date: Date;
  type: boolean;
  is_selected: boolean;
  there_is_mark: boolean;
}
