<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<div class="fixed_table" (window:resize)="this.onResize()">
  <div class="first_row">
    <table>
      <tr>
        <th
          id="H"
          style="border-top-left-radius: 8px; border-bottom-left-radius: 8px"
          (click)="!this.calendar ? this.setGraphs(intervalType.Hourly, 0) : ''"
        >
          H
        </th>
        <td class="line td_center" id="first_line">|</td>
        <th
          id="D"
          (click)="!this.calendar ? this.setGraphs(intervalType.Daily, 0) : ''"
        >
          D
        </th>
        <td class="line td_center" id="second_line">|</td>
        <th
          id="W"
          (click)="!this.calendar ? this.setGraphs(intervalType.Weekly, 0) : ''"
        >
          W
        </th>
        <td class="line td_center" id="third_line">|</td>
        <th
          id="M"
          style="border-top-right-radius: 8px; border-bottom-right-radius: 8px"
          (click)="
            !this.calendar ? this.setGraphs(intervalType.Monthly, 0) : ''
          "
        >
          M
        </th>
      </tr>
    </table>
  </div>
  <app-calendar
    *ngIf="this.calendar"
    (return)="this.valueDate = $event; this.setDate()"
    (close)="this.calendar = $event"
    (update_calendar)="
      this.calendarEvent.getMonthEvents(
        $event,
        this.http,
        this.devMan,
        this.app
      )
    "
    [value]="this.valueDate"
    [max]="this.max_date"
    [with_events]="true"
    [events]="this.calendarEvent.events"
    [color]="'rgb(255, 86, 86)'"
  ></app-calendar>
  <app-dashboard-menu
    class="dashboard_menu"
    [mark_color]="'#50a17c'"
    [button_active]="'multi'"
    [string_buttons]="this.menu_string_option"
    [view_labels]="true"
    [icon_width]="['29px', '25px']"
    [icon_height]="['29px', '25px']"
    [icon_margin]="['-2px 0px 0px 0px', '0px 3px 0px 0px']"
    [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px']"
    [buttons]="this.menu_options"
    [icons]="this.menu_icons"
    [is_active]="[this.devMan.tools.wait_sync, this.calendar]"
    (response)="this.command($event)"
  ></app-dashboard-menu>

  <table class="list">
    <tr>
      <th class="icon_title th_left">
        <img
          class="td_icon"
          src="../../../assets/icon/battery-details/current.svg"
          alt=""
          srcset=""
        />
      </th>
      <th colspan="2" class="th_right">Current</th>
    </tr>
    <tr>
      <td colspan="3" class="td_data">Average</td>
      <div
        class="vertical_content_collapsed td_balloon"
        [ngStyle]="{
          'max-height': this.chartCurrent.selected != -1 ? '76px' : '0px'
        }"
      >
        <p class="balloon_data">
          {{ this.chartCurrent.balloonValue }}
        </p>
        <p class="balloon_time">
          {{ this.chartCurrent.balloonDate }}
        </p>
      </div>
    </tr>
    <tr>
      <td colspan="3" class="td_data_mark">
        {{
          !this.stat.noData ? this.stat.avg_current.toFixed(1) + "A" : "No Data"
        }}
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td_data">
        {{ this.labelCurrentRange }}
      </td>
    </tr>
    <tr>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow left"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset - 1) : ''
          "
        ></a>
      </td>
      <td [colSpan]="this.canvas_col_span">
        <canvas
          id="line_graph"
          (click)="this.chartCurrent.longPress()"
          (touchstart)="swipe($event, swipeEvent.start)"
          (touchend)="swipe($event, swipeEvent.end)"
        ></canvas>
      </td>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow right"
          *ngIf="this.offset != 0"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset + 1) : ''
          "
        ></a>
      </td>
    </tr>
    <tr>
      <th class="icon_title th_left">
        <img
          class="td_icon"
          src="../../../assets/icon/battery-details/voltage.svg"
          alt=""
          srcset=""
        />
      </th>
      <th colspan="2" class="th_right">Voltage</th>
    </tr>
    <tr>
      <td colspan="3" class="td_data">Average</td>
      <div
        class="vertical_content_collapsed td_balloon"
        [ngStyle]="{
          'max-height': this.chartVoltage.selected != -1 ? '76px' : '0px'
        }"
      >
        <p class="balloon_data">
          {{ this.chartVoltage.balloonValue }}
        </p>
        <p class="balloon_time">
          {{ this.chartVoltage.balloonDate }}
        </p>
      </div>
    </tr>
    <tr>
      <td colspan="3" class="td_data_mark">
        {{
          !this.stat.noData ? this.stat.avg_voltage.toFixed(1) + "V" : "No Data"
        }}
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td_data">{{ this.labelVoltageRange }}</td>
    </tr>
    <tr>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow left"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset - 1) : ''
          "
        ></a>
      </td>
      <td [colSpan]="this.canvas_col_span">
        <canvas
          id="line_graph1"
          (click)="this.chartVoltage.longPress()"
          (touchstart)="swipe($event, swipeEvent.start)"
          (touchend)="swipe($event, swipeEvent.end)"
        ></canvas>
      </td>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow right"
          *ngIf="this.offset != 0"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset + 1) : ''
          "
        ></a>
      </td>
    </tr>
    <tr>
      <th class="icon_title th_left">
        <img
          class="td_icon"
          src="../../../assets/icon/battery-details/soc_gauge.svg"
          alt=""
          srcset=""
        />
      </th>
      <th colspan="2" class="th_right">State of charge</th>
    </tr>
    <tr>
      <td colspan="3" class="td_data">Average</td>
      <div
        class="vertical_content_collapsed td_balloon"
        [ngStyle]="{
          'max-height': this.chartSoc.selected != -1 ? '76px' : '0px'
        }"
      >
        <p class="balloon_data">
          {{ this.chartSoc.balloonValue }}
        </p>
        <p class="balloon_time">
          {{ this.chartSoc.balloonDate }}
        </p>
      </div>
    </tr>
    <tr>
      <td colspan="3" class="td_data_mark">
        {{ !this.stat.noData ? this.stat.avg_soc.toFixed(0) + "%" : "No Data" }}
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td_data">{{ this.labelSocRange }}</td>
    </tr>
    <tr>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow left"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset - 1) : ''
          "
        ></a>
      </td>
      <td [colSpan]="this.canvas_col_span">
        <canvas
          id="line_graph2"
          (click)="this.chartSoc.longPress()"
          (touchstart)="swipe($event, swipeEvent.start)"
          (touchend)="swipe($event, swipeEvent.end)"
        ></canvas>
      </td>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow right"
          *ngIf="this.offset != 0"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset + 1) : ''
          "
        ></a>
      </td>
    </tr>
    <tr>
      <th class="icon_title th_left">
        <img
          class="td_icon"
          src="../../../assets/icon/battery-details/temperature.svg"
          alt=""
          srcset=""
        />
      </th>
      <th colspan="2" class="th_right">Temperature</th>
    </tr>
    <tr>
      <td colspan="3" class="td_data">Average</td>
      <div
        class="vertical_content_collapsed td_balloon"
        [ngStyle]="{
          'max-height': this.chartTemperature.selected != -1 ? '76px' : '0px'
        }"
      >
        <p class="balloon_data">
          {{ this.chartTemperature.balloonValue }}
        </p>
        <p class="balloon_time">
          {{ this.chartTemperature.balloonDate }}
        </p>
      </div>
    </tr>
    <tr>
      <td colspan="3" class="td_data_mark">
        {{
          !this.stat.noData
            ? this.stat.avg_temperature.toFixed(0) + "°C"
            : "No Data"
        }}
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td_data">{{ this.labelTemperatureRange }}</td>
    </tr>
    <tr>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow left"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset - 1) : ''
          "
        ></a>
      </td>
      <td [colSpan]="this.canvas_col_span">
        <canvas
          id="line_graph3"
          (click)="this.chartTemperature.longPress()"
          (touchstart)="swipe($event, swipeEvent.start)"
          (touchend)="swipe($event, swipeEvent.end)"
        ></canvas>
      </td>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow right"
          *ngIf="this.offset != 0"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset + 1) : ''
          "
        ></a>
      </td>
    </tr>
    <tr>
      <th class="icon_title th_left">
        <img
          class="td_icon"
          src="../../../assets/icon/battery-details/cell_voltage_range.svg"
          alt=""
          srcset=""
        />
      </th>
      <th colspan="2" class="th_right">Cell difference</th>
    </tr>
    <tr>
      <td colspan="3" class="td_data">Average</td>
      <div
        class="vertical_content_collapsed td_balloon"
        [ngStyle]="{
          'max-height': this.chartDeltaVoltage.selected != -1 ? '76px' : '0px'
        }"
      >
        <p class="balloon_data">
          {{ this.chartDeltaVoltage.balloonValue }}
        </p>
        <p class="balloon_time">
          {{ this.chartDeltaVoltage.balloonDate }}
        </p>
      </div>
    </tr>
    <tr>
      <td colspan="3" class="td_data_mark">
        {{
          !this.stat.noData
            ? this.stat.avg_deltavoltage.toFixed(0) + "mV"
            : "No Data"
        }}
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td_data">{{ this.labelDeltaVoltageRange }}</td>
    </tr>
    <tr>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow left"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset - 1) : ''
          "
        ></a>
      </td>
      <td [colSpan]="this.canvas_col_span">
        <canvas
          id="line_graph4"
          (click)="this.chartDeltaVoltage.longPress()"
          (touchstart)="swipe($event, swipeEvent.start)"
          (touchend)="swipe($event, swipeEvent.end)"
        ></canvas>
      </td>
      <td *ngIf="!this.smallScreen" class="swipe">
        <a
          class="arrow right"
          *ngIf="this.offset != 0"
          (click)="
            !this.calendar ? this.setGraphs(this.interval, this.offset + 1) : ''
          "
        ></a>
      </td>
    </tr>
  </table>
</div>
