/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { Data_registration } from './data_registration';
import { AppRoutingModule, Message } from '../../app-routing.module';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Regex } from '../../../model/Regex';
import { WarningColor } from 'src/app/warning/warning.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  /**
   * required: 0 name 1 username 2 email 3 phone  4 city 5 country 6 password 7 password_c
   */

  /**
   * required: 0 company
   */
  public data_registration = new Data_registration(
    [
      'name',
      'username',
      'email',
      'phone',
      'city',
      'country',
      'password',
      'password_c',
    ],
    ['company'],
    this.http,
    this.app
  );

  public register: boolean = true;
  // false if user must complete registration, true if resistration is end
  public login: boolean = false;

  constructor(public app: AppRoutingModule, public http: HttpClient) {
    this.app.page_access('register');
  }

  ngOnInit(): void {}

  private get_message(): string[] {
    let message: string[] = [];
    if (!Regex.regex_email(this.data_registration.data_required[2])) {
      message.push(`• Email can only contain letters and numbers.
       (Ex:text.44@gmail.com)`);
    }
    if (!Regex.regex_phone(this.data_registration.data_required[3])) {
      message.push(
        `• Phone number must contain only numbers and possible initial +.`
      );
    }
    if (!Regex.regex_pwd(this.data_registration.data_required[6])) {
      message.push(`• Password lenght is min 8, max 20.
                    You can use letters, numbers and this special characters $ @ ! % * # ? & 
                    Password must contain at least 1 special characters, 1 numbers, 1 lowercase letter and 1 uppercase letter.`);
    }
    if (message.length > 0) {
      // message.push(0,'Invalid credential');
    }
    return message;
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      if (!this.login) this.onSubmit();
      else this.app.goToLogin();
    }
  }

  onSubmit(): void {
    this.data_registration.warning.setViewWarning(false);
    const privacy = (
      document.getElementById('checkbox_privacy_policy')! as HTMLInputElement
    ).checked;

    if (!privacy) {
      this.register = true;
      this.data_registration.warning.setWarning(WarningColor.red, [
        'You must confirm acceptance of the privacy policy',
      ]);
      return;
    }

    if (
      !this.data_registration.required() &&
      !this.data_registration.password_confirmation_bool &&
      Regex.regex_email(this.data_registration.data_required[2]) &&
      Regex.regex_phone(this.data_registration.data_required[3]) &&
      Regex.regex_pwd(this.data_registration.data_required[6])
    ) {
      this.register = false;
      this.http
        .post<Message>(this.app.server_domain + '/register.php', {
          request: 'registration',
          name: this.data_registration.data_required[0],
          username: this.data_registration.data_required[1],
          email: this.data_registration.data_required[2],
          phone_number: this.data_registration.data_required[3],
          company: this.data_registration.data_unrequired[0],
          city: this.data_registration.data_required[4],
          country: this.data_registration.data_required[5],
          password: this.data_registration.data_required[6],
        })
        .subscribe(
          (result) => {
            if (!result.response) {
              this.register = true;
              this.data_registration.warning.setWarning(WarningColor.red, [
                result.message,
              ]);
            } else {
              this.login = true;
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.register = true;
      this.data_registration.warning.setWarning(
        WarningColor.red,
        this.get_message()
      );
    }
  }
}
