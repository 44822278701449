/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Command } from 'src/app/dashboard-menu/command';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClient } from '@angular/common/http';
import { AppDate } from 'src/model/app.date';
import { DeviceManager, BatteryMethods } from 'src/model/app.deviceManager';

@Component({
  selector: 'app-general-events',
  templateUrl: './general-events.component.html',
  styleUrls: ['./general-events.component.css'],
})
export class GeneralEventsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private _update: boolean = false;
  @Input() public set update(value: boolean) {
    this._update = value;
    if (this._update) {
      this.updateGeneralEvent();
    }
    this._update = false;
  }

  public rows_offset = 0;
  public range_rows: string = '0 - 0';
  public events: GeneralEvent[] = [];

  public menu_string_option: string[] = ['sync', 'date'];
  public menu_options: boolean[] = [false, false];
  public calendar: boolean = false;

  public menu_icons: string[] = [
    '../../assets/icon/advanced/sync_white.svg',
    '../../assets/icon/battery-details/clock.svg',
  ];

  public date: string = 'Today';
  public max_date: Date = new Date();
  public valueDate: Date = new Date();

  public eventsInterval: any;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager,
  ) {}

  ngAfterViewInit(): void {
    this.eventsInterval = setInterval(() => {
      this.updateGeneralEvent();
    }, 1 * 60 * 1000);
  }
  ngOnInit(): void {
    this.updateGeneralEvent();
  }

  public updateGeneralEvent() {
    this.setDate(this.valueDate);
    this.get_general_events(this.valueDate.getTime());
  }

  ngOnDestroy(): void {
    clearInterval(this.eventsInterval);
  }
  public state(decString: string, precision: number) {
    return BatteryMethods.dec2hex(decString, precision);
  }

  public change_offset(type: boolean) {
    let max = Math.floor(this.events.length / 100);
    if (type) {
      this.rows_offset++;
      if (this.rows_offset > max) {
        this.rows_offset = 0;
      }
      return;
    }
    this.rows_offset--;
    if (this.rows_offset < 0) {
      this.rows_offset = max;
    }
  }

  public event_filter() {
    let start = this.rows_offset * 100;
    let end = this.rows_offset * 100 + 100;
    let list: GeneralEvent[] = [];

    list = this.events.slice();

    if (end > list.length) {
      end = list.length;
    }
    this.range_rows = start + 1 + ' - ' + end;
    if (list.length == 0) {
      this.range_rows = '0 - 0';
    }

    list = list.slice(start, end);
    return list;
  }

  public event_date(timestemp: number) {
    let date = new Date(timestemp);
    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum_hour2_minute2_second2
    );
  }

  private setDate(date: Date) {
    this.date = AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum
    );
  }

  /**
   * param0 device.sn
   * param1 index
   * param2 start
   * param3 end
   */
  private get_general_events(date: number) {
    let start = new Date(date);
    let end = new Date(date);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    this.http
      .post<GeneralEvent[] | null>(this.app.server_domain + '/battery.php', {
        request: 'device_battery_log',
        token: this.app.token,
        sn: this.devMan.device.sn,
        num_battery: this.devMan.battery_num,
        start_date: Math.floor(start.getTime() / 1000),
        end_date: Math.floor(end.getTime() / 1000),
      })
      .subscribe(
        (result) => {
          if (result == null) {
            this.events = [];
            return;
          }
          this.events = result;
        },
        (error) => {
          console.error(error);
        }
      );
  }
  public command(command: Command = new Command('', [false])) {
    this.calendar = command.active[1];
    switch (command.command) {
      case 'date':
        break;
      case 'sync':
        this.devMan.tools.synchronize();
        break;
    }
  }
  public stringToNumber(str: number | string) {
    return Number(str);
  }
}

class GeneralEvent {
  public _time: number = 0;
  public soc: number | string = '-';
  public canbus_timeout: number | string = '-';
  public sw: number | string = '-';
  public sw2: number | string = '-';
  public current: number | string = '-';
  public count: string = '';
  public v_max: number | string = '-';
  public v_min: number | string = '-';
  public voltage: number | string = '-';
  public cycles: number | string = '-';
  public t_max: number | string = '-';
  public t_min: number | string = '-';

  constructor() {}
}