<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<div class="central_block" *ngIf="this.view_option">
  <div>
    <button class="circle" id="circle">
      <img
        class="icon_close"
        src="../../../assets/icon/close.svg"
        (click)="this.close_set_user()"
      />
    </button>
  </div>
  <div class="form">
    <app-option
      *ngIf="!this.input_user"
      [title]="'Users'"
      [subTitle]="'Select user.'"
      [button_index]="this.button_index"
      [is_over]="this.is_over"
      [tools_title]="this.tools_title"
      [disable]="this.disable"
      (command)="this.command($event)"
    ></app-option>
    <div *ngIf="this.input_user">
      <table>
        <tr>
          <td>{{ this.nextUser }} password</td>
        </tr>
        <tr>
          <td>
            <input
              class="input"
              type="password"
              id="userpwd"
              autocomplete="new-password"
              aria-autocomplete="none"
            />
          </td>
        </tr>
      </table>
      <div>
        <button class="submit delete" (click)="this.input_user = false">
          CANCEL
        </button>
        <button class="submit" (click)="this.userpwd()">OK</button>
      </div>
    </div>
  </div>
</div>
<app-warning
  [title]="'Set user response'"
  [background_color]="warning.getWarningBackgroundColor()"
  [message]="warning.getWarningMessage()"
  [warning]="warning.getViewWarning()"
  (response)="warning.setViewWarning($event)"
></app-warning>
