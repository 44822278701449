<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->
<table>
  <tr>
    <td colspan="2" class="filter_title">{{ this.title }}</td>
  </tr>
  <tr>
    <td colspan="2" class="filter_subtitle">
      {{ this.subTitle }}
    </td>
  </tr>
  <tr
    *ngFor="let i of this.button_index"
    [ngStyle]="{
      opacity: this.disable[i] ? 0.3 : 1
    }"
  >
    <td
      class="data_tool"
      (click)="this.clickCommand(i)"
      (mouseover)="this.is_over[i] = true"
      (mouseout)="this.is_over[i] = false"
    >
      <img
        *ngIf="tools_imgs.length > 0"
        src="{{ this.is_over[i] ? tools_imgs_over[i] : tools_imgs[i] }}"
        alt=""
        srcset=""
      />
      <div
        [ngClass]="
          tools_imgs.length > 0 ? 'option_with_img' : 'option_without_img'
        "
      >
        <p class="p_title">{{ tools_title[i] }}</p>
        <div class="p_data" *ngIf="tools_label.length > 0">
          <p>{{ tools_label[i] }}</p>
        </div>
      </div>
    </td>
  </tr>
</table>
