/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HomeComponent } from 'src/app/home/home.component';
import { LogAccess, ManageUsers } from '../manage-users/manageUsers';

@Component({
  selector: 'app-log-access',
  templateUrl: './log-access.component.html',
  styleUrls: ['./log-access.component.css'],
})
export class LogAccessComponent implements OnInit, OnDestroy {
  public users: ManageUsers = new ManageUsers(this.app, this.http);

  public interval_dashboard: any;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public home: HomeComponent
  ) {
    this.users.get_log_access_list();
  }
  ngOnDestroy(): void {
    clearInterval(this.interval_dashboard);
  }

  ngOnInit(): void {
    this.interval_dashboard = setInterval(() => {
      this.users.get_log_access_list();
    }, 9 * 60 * 1000);
  }
  public filtered_sorted_list() {
    let list: LogAccess[] = [];
    list = this.users.log_access_list_filter();
    let sort = (
      document.getElementById('sort_options')! as HTMLInputElement
    ).value
      .trim()
      .toLowerCase();

    switch (sort) {
      case 'name':
        list = list.sort((a, b) =>
          a.account_name.trim().toLowerCase() >
          b.account_name.trim().toLowerCase()
            ? 1
            : -1
        );
        break;
      case 'company':
        list = list.sort((a, b) =>
          this.compare_company(
            a.account_company.trim().toLowerCase(),
            b.account_company.trim().toLowerCase()
          )
        );
        break;
      case 'device':
        list = list.sort((a, b) => (a.device_count > b.device_count ? 1 : -1));
        break;
      case 'last_created':
        list = list.sort((a, b) =>
          new Date(a.account_date) < new Date(b.account_date) ? 1 : -1
        );
        break;
      case 'oldest_created':
        list = list.sort((a, b) =>
          new Date(a.account_date) > new Date(b.account_date) ? 1 : -1
        );
        break;
      case 'last_activity':
      case 'oldest_activity':
        list = list.sort((a, b) =>
          this.compare_activity(a.max_last_access, b.max_last_access, sort)
        );
        break;
    }

    return list;
  }

  private compare_company(a: string, b: string) {
    if (a == '') return 1;
    if (b == '') return -1;

    if (a > b) return 1;
    return -1;
  }
  private compare_activity(a: string, b: string, sort: string) {
    switch (sort) {
      case 'last_activity':
        if (a == 'Never') return 1;
        if (b == 'Never') return -1;
        return new Date(a) < new Date(b) ? 1 : -1;
      case 'oldest_activity':
        if (a == 'Never') return -1;
        if (b == 'Never') return 1;
        return new Date(a) > new Date(b) ? 1 : -1;
    }
    return -1;
  }
}
