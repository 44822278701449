/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { ComponentType, HomeComponent } from 'src/app/home/home.component';

import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { rebootType } from 'src/model/app.tools';
import { Firmware } from './firmware';
import { AppDate } from 'src/model/app.date';
import {
  DeviceManager,
  DeviceMethods,
  DeviceType,
  UpgradeHistoryItem,
} from 'src/model/app.deviceManager';
import { userTypeString } from '../advanced-user/user';
import { SubMenu } from 'src/app/home-menu/home-menu.component';
import { AdvancedToolPermissions } from '../advanced-tool-permissions';

enum cmd {
  tools = 0,
  reboot = 1,
  reboot_all = 2,
  upgrade = 3,
}

@Component({
  selector: 'app-advanced-general',
  templateUrl: './advanced-general.component.html',
  styleUrls: ['./advanced-general.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class AdvancedGeneralComponent implements OnInit, OnDestroy {
  public type_title: string[] = ['DATALOGGER', 'BATTERY'];

  public options: boolean[] = [false, false, false, false];
  public is_active: boolean[] = [false, false, false, false];
  public labels: string[] = [
    'Accepted',
    'Downloading',
    'Downloaded',
    'SignatureVerified',
    'Installing',
    'Installed',
  ];

  public view_confirm: boolean = false;
  public view_confirm_title: string = '';
  public view_confirm_subtitle: string = '';
  // the command to appy if user confirm action
  public view_confirm_command = '';

  public interval_check_target: any;

  // Create variables to go from data logger to batteries as they are all devices
  private array_devices: number[] = [];
  public current_device: number = 0;

  protected ComponentType = ComponentType;
  protected UserTypeString = userTypeString;
  protected DeviceType = DeviceType;
  protected AdvancedToolPermissions = AdvancedToolPermissions;

  constructor(
    public home: HomeComponent,
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager
  ) {}

  ngOnInit(): void {
    // Reset count of batteries for switch batteries
    this.devMan.battery_num = 0;
    // Reset device type
    this.devMan.type = DeviceType.datalogger;

    // Create an array to switch devices in advanced component
    this.array_devices.push(-1);
    for (let i = 0; i < this.devMan.batteries_tot_num; i++) {
      this.array_devices.push(i);
    }

    this.devMan.tools.onReb = () => {
      this.is_active[cmd.reboot] = false;
      this.is_active[cmd.reboot_all] = false;
    };
    this.updateInfo();
    this.interval_check_target = setInterval(() => {
      this.updateInfo();
    }, 30 * 1000);
  }
  ngOnDestroy(): void {
    clearInterval(this.interval_check_target);
  }

  private getNewFirmware(type: DeviceType) {
    let fw =
      type == DeviceType.datalogger
        ? this.home.available_firmware_table.get(this.devMan.device.type)
        : this.home.available_firmware_table.get(
            this.devMan.getCurrentBattery().type
          );

    if (fw != undefined) return fw;
    else return new Firmware();
  }

  public src_img() {
    if (this.devMan.type == DeviceType.datalogger) {
      return DeviceMethods.device_type(this.devMan.device.sn.substring(0, 2));
    }
    return DeviceMethods.device_type('02');
  }
  public product() {
    if (this.devMan.type == DeviceType.datalogger) {
      return DeviceMethods.device_type_name(
        this.devMan.device.sn.substring(0, 2)
      );
    }
    return DeviceMethods.device_type_name('02');
  }

  public sn() {
    if (this.devMan.type == DeviceType.datalogger) {
      return this.devMan.device.sn;
    }
    return this.devMan.getCurrentBattery().sn;
  }

  public async command(command: string) {
    const labelComp =
      this.home.title_path[this.home.title_path.length - 1].componentType;
    if (labelComp == ComponentType.ADVANCED_GENERAL) {
      this.home.menu.changeMenu(SubMenu.ADVANCED_MENU, SubMenu.DEVICE_MENU);
    }
    switch (command) {
      case 'Console':
        this.home.change_component(ComponentType.ADVANCED_CONSOLE);
        break;
      case 'Device settings':
        this.home.change_component(ComponentType.DEVICE_SETTINGS);
        break;
    }
  }

  private updateInfo() {
    // If the active component is Advanced-General we do the updateInfo
    if (this.home.component.types[ComponentType.ADVANCED_GENERAL]) {
      let type = DeviceType.datalogger;
      let index = '-1';
      if (this.devMan.type != DeviceType.datalogger) {
        type = DeviceType.battery;
        index = this.devMan.battery_num.toString();
      }
      // If the type is datalogger is always possible check if is online
      this.devMan.tools.baseInfo(type, index);
    }
  }

  public checkVersion() {
    let new_version =
      this.devMan.type == DeviceType.datalogger
        ? this.getNewFirmware(DeviceType.datalogger).firmware_version
        : this.getNewFirmware(DeviceType.battery).firmware_version;
    let current_version =
      this.devMan.type == DeviceType.datalogger
        ? this.devMan.device.fw_ver
        : this.devMan.getCurrentBattery().fw_ver;
    if (
      current_version === '-' ||
      current_version === '' ||
      new_version === ''
    ) {
      return '-';
    } else if (new_version === current_version) {
      return 'Up to date';
    } else {
      return 'New version available: ' + new_version;
    }
  }

  public async updateFirmware() {
    if (!this.isReachable()) return;
    this.addStartItem();
    this.devMan.tools.update(
      this.devMan.type,
      this.getNewFirmware(DeviceType.datalogger),
      this.getNewFirmware(DeviceType.battery)
    );
  }

  private addStartItem() {
    let newUpgrade = new UpgradeHistoryItem(
      new Date().getTime(),
      'Start',
      0,
      '',
      this.devMan.type == DeviceType.datalogger
        ? this.devMan.device.sn
        : this.devMan.getCurrentBattery().sn,
      -1,
      ''
    );
    this.devMan.upgrade_history.unshift(newUpgrade);
  }

  public switchDevice(type: string) {
    switch (type) {
      case 'left':
        this.current_device -= 1;
        break;
      case 'right':
        this.current_device += 1;
        break;
    }

    if (this.current_device < 0)
      this.current_device = this.array_devices.length - 1;

    if (this.current_device >= this.array_devices.length)
      this.current_device = 0;

    if (this.current_device == 0) this.devMan.type = DeviceType.datalogger;
    else this.devMan.type = DeviceType.battery;

    if (this.devMan.type == DeviceType.battery)
      this.devMan.battery_num = this.array_devices[this.current_device];

    this.updateInfo();
  }

  public tool_command(command: string) {
    switch (command) {
      case 'reboot':
        if (this.devMan.type == DeviceType.datalogger)
          this.devMan.tools.reboot(rebootType.datalogger);
        else this.devMan.tools.reboot(rebootType.battery);
        break;
      case 'reboot_all':
        this.devMan.tools.reboot(rebootType.all_battery);
        break;
      case 'upgrade':
        this.updateFirmware();
        break;
    }
    this.resetPopup();
  }

  public lastRemoteUpdateHistory(timestamp: number) {
    let date = new Date(timestamp);

    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum_hour2_minute2
    );
  }

  public optionsLabel() {
    let w = window.innerWidth;
    if (w >= 800) {
      return true;
    }
    return false;
  }

  public annularStepperRadius() {
    let w = window.innerWidth;
    if (w >= 500) {
      return true;
    }
    return false;
  }

  public isReachable() {
    if (this.devMan.type == DeviceType.datalogger)
      return this.devMan.device.isReachable;
    else return this.devMan.batteries[this.devMan.battery_num].isReachable;
  }

  public confirmPopup(
    command: string = '',
    active: boolean[] = [false, false, false, false]
  ) {
    let tmp = false;
    this.is_active = active;
    this.is_active.forEach((x) => {
      tmp = tmp || x;
    });

    // If tools menu is active the popup to confirm reboot, ... must be close
    if (this.is_active[cmd.tools]) {
      this.resetPopup();
    }

    this.view_confirm = tmp;

    if (!this.view_confirm) {
      this.resetPopup();
      return;
    }

    // Set popup message
    switch (command) {
      case 'reboot':
        this.view_confirm_command = 'reboot';
        this.view_confirm_title = 'Are you sure to restart your device now?';
        this.view_confirm_subtitle =
          'Confirming this message will force the device to reboot.';
        break;
      case 'reboot_all':
        this.view_confirm_command = 'reboot_all';
        this.view_confirm_title = 'Are you sure to restart all batteries now?';
        this.view_confirm_subtitle =
          'Confirming this message will force your batteries to reboot.';
        break;
      case 'upgrade':
        this.view_confirm_command = 'upgrade';
        this.view_confirm_title = 'Are you sure to upgrade now?';
        this.view_confirm_subtitle =
          'Confirming this message will force the device to upgrade.';
        break;
      default:
        this.resetPopup();
    }
  }

  public resetPopup() {
    this.view_confirm_command = '';
    this.view_confirm_title = '';
    this.view_confirm_subtitle = '';
    this.view_confirm = false;
    this.is_active[cmd.reboot] = false;
    this.is_active[cmd.reboot_all] = false;
    this.is_active[cmd.upgrade] = false;
  }
}
