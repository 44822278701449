/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import * as Paho from 'paho-mqtt';

export enum TopicType {
  publish,
  subscribe,
}

export class MQTT {
  private client_id = this.createClientID();
  private client = new Paho.Client(
    'grafana.archimede-energia.com',
    9001,
    '/mqtt',
    this.client_id
  );
  private options: Paho.ConnectionOptions = {
    password: 'mqTT@ch12020',
    userName: 'mqtt-archimede',
    reconnect: false,
    timeout: 30, // Timeout period
    keepAliveInterval: 60,
    // Authentication information
    onSuccess: () => {
      this.onSuccess();
    },
    onFailure: (message: Paho.ErrorWithInvocationContext) => {
      this.onFailure(message);
    },
  };

  private m_queuePublish: MqttHandlerMsg[] = [];
  private m_executeSyncSt: number = 0;
  private m_executeRetry: number = 5;
  private m_executeMsg: MqttHandlerMsg | undefined;
  private m_timer: any;
  private m_status: number = 0;
  private m_hashSubscribe: Map<string, number> = new Map();
  private m_renewSubscribe: boolean = true;

  constructor() {}

  public onMessageArrived(message: any) {}

  public startConnection() {
    this.m_queuePublish = [];
    this.m_hashSubscribe = new Map();
    this.m_status = 0;
    this.m_executeSyncSt = 0;
    this.m_renewSubscribe = true;
    this.client.onMessageArrived = (message) => {
      this.onMessageArrived(message);
    };
    this.client.onConnectionLost = (responseObject) => {
      console.log('Connection lost: ' + responseObject.errorMessage);
      this.m_status = 0;
      this.m_executeSyncSt = 1;
    };
    this.m_timer = setInterval(() => {
      this.executeSync();
    }, 1000);
  }

  public disconnect() {
    if (this.client.isConnected()) this.client.disconnect();
    console.log('Disconnect');
    this.m_status = 0;
    clearInterval(this.m_timer);
  }

  public pushPublish(message: MqttHandlerMsg) {
    this.m_queuePublish?.push(message);
  }

  public pushSubscribe(topic: string, qos: number) {
    this.m_hashSubscribe.set(topic, qos);
    this.m_renewSubscribe = true;
  }

  public unSubscribe(topic: string) {
    this.client.unsubscribe(topic);
  }

  private executeSync() {
    let {
      m_executeSyncSt,
      m_queuePublish,
      m_hashSubscribe,
      m_executeRetry,
      m_status,
      m_renewSubscribe,
      m_executeMsg,
    } = this;
    switch (m_executeSyncSt) {
      case 0: //Connect
        if (m_queuePublish.length == 0 && m_hashSubscribe.size == 0) {
          return;
        }

        console.log('Start to connect...');

        this.m_executeRetry = 5;

        this.connect();

        this.m_executeSyncSt += 1;
        break;
      case 1: // Try reconnect
        if (m_executeRetry == 0) {
          this.m_executeSyncSt = 0;
          this.m_queuePublish = [];
          return;
        }

        if (m_status == 0) {
          this.m_executeRetry -= 1;
          return;
        }
        this.m_executeSyncSt += 1;
        break;
      case 2: // Add subscribe and check publish message
        if (m_renewSubscribe) {
          m_hashSubscribe.forEach((qos, topic) => {
            this.subscribe(topic, { qos: qos });
          });

          this.m_renewSubscribe = false;
        }
        if (m_queuePublish.length == 0) {
          this.m_executeSyncSt = 4;
          return;
        }
        this.m_executeMsg = m_queuePublish![0];
        this.m_executeSyncSt += 1;
        break;
      case 3: // Publish Message
        this.m_executeSyncSt = 2;
        this.publish(m_executeMsg!);
        this.m_queuePublish!.shift();
        break;
      case 4: // Disconnect
        if (m_hashSubscribe.size == 0 || m_status == 0) {
          this.client.disconnect();
          this.m_renewSubscribe = true;
          this.m_executeSyncSt += 1;
          return;
        }
        this.m_executeSyncSt = 2;
        break;
      case 5: // Restart
        this.m_executeSyncSt = 0;
        break;
      default:
        this.m_executeSyncSt = 0;
    }
  }

  private connect() {
    if (this.client.isConnected()) {
      this.client.disconnect();
    }
    this.client.connect(this.options);
  }

  private onSuccess() {
    console.log('Successful connection');
    this.m_status = 1;
  }
  async subscribe(filter: string, qos: { qos: any }) {
    // Connection succeeded; subscribe to our topic, you can add multile lines of these
    this.client.subscribe(filter, qos);
  }

  private async publish(m: MqttHandlerMsg) {
    //use the below if you want to publish to a topic on connect
    let message = new Paho.Message(m.update_message);
    message.destinationName = m.topic;
    message.retained = m.retained;
    this.client.send(message);
  }

  private onFailure(message: Paho.ErrorWithInvocationContext) {
    console.log('Failure connection: ' + message.errorMessage);
    this.m_status = 0;
    // On case 2 and 3 we don't check if status is != 0, because is necessary reset m_executeSyncSt
    this.m_executeSyncSt = 0;
  }

  private createClientID() {
    let tmp = 'myclientid_' + Math.random().toString(36).substring(2, 15);
    return tmp;
  }

  static createRequestID(text: string = '') {
    let tmp = text;
    tmp += Math.random().toString(36).substring(2, 15);
    return tmp;
  }
}

export class MqttHandlerMsg {
  public topic: string = '';
  public update_message: string | ArrayBuffer = '';
  public retained: boolean = true;
  constructor(
    topic: string = '',
    update_message: string | ArrayBuffer = '',
    retained: boolean = true
  ) {
    this.topic = topic;
    this.update_message = update_message;
    this.retained = retained;
  }
}
