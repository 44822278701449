/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { Command } from 'src/app/dashboard-menu/command';
import { AppDate } from 'src/model/app.date';
import { DeviceMethods } from 'src/model/app.deviceManager';
import { AdminDevice, ManageDevices } from './ManageDevices';
import { AppCollapsible } from 'src/model/app.collapsible';

@Component({
  selector: 'app-manage-devices',
  templateUrl: './manage-devices.component.html',
  styleUrls: ['./manage-devices.component.css'],
})
export class ManageDevicesComponent implements OnInit, OnDestroy {
  public menu_string_option: string[] = ['online', 'offline'];
  public menu_options: boolean[] = [false, false];
  public is_active: boolean[] = [false, false];
  public menu_icons: string[] = [
    '../../assets/icon/device-list/device_list_menu/offline.svg',
    '../../assets/icon/device-list/device_list_menu/online.svg',
  ];

  public dashboard_message: string = '';

  public view_option: boolean = false;
  public view_option_id: number = 0;
  public view_option_sn: string = '';
  public option: boolean = false;
  public option_type: string = '';
  public first_transmission: string = '';
  public last_transmission: string = '';

  public last_row_option_opened: string = '';

  public manageDevice = new ManageDevices(this.app, this.http);
  public dev_methods = new DeviceMethods();

  public interval_dashboard: any;
  constructor(public app: AppRoutingModule, public http: HttpClient) {
    this.manageDevice.update_message = () => {
      this.update_message();
    };
    // Reset option for last row open before update device list
    if (this.last_row_option_opened != '') {
      //close last row option
      AppCollapsible.width_collapsible(
        this.last_row_option_opened,
        210,
        true,
        0
      );
      this.last_row_option_opened = '';
    }
    this.get_devices_list();
  }

  ngOnInit(): void {
    this.interval_dashboard = setInterval(() => {
      this.get_devices_list();
    }, 9 * 60 * 1000);
  }

  private get_devices_list() {
    // Reset option for last row open before update device list
    if (this.last_row_option_opened != '') {
      //close last row option
      AppCollapsible.width_collapsible(
        this.last_row_option_opened,
        210,
        true,
        0
      );
      this.last_row_option_opened = '';
    }
    this.manageDevice.get_devices_list();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_dashboard);
  }

  /**
   * Check type of command arrived from dashboard-menu component
   *
   * If you don't give parameter he set command for total device
   * @param command type of command and if is activated or deactivated
   */
  public command(command: Command = new Command('', [false, false])) {
    this.is_active = command.active;
    switch (command.command) {
      case 'offline':
        this.is_active[0] = false;
        break;
      case 'online':
        this.is_active[1] = false;
        break;
      default:
        this.update_message();
    }
  }
  private update_message() {
    let total = this.manageDevice.devices_list.length;
    let online = this.manageDevice.devices_list.filter(
      (x) => x.is_online == true
    ).length;

    this.dashboard_message = 'Total: ' + total;
    this.dashboard_message += ', Online: ' + online;
  }

  public devices_list_filter() {
    let list: AdminDevice[] = [];
    list = this.manageDevice.devices_list;
    if (this.is_active[0]) {
      list = list.filter((x) => x.is_online != true);
    }
    if (this.is_active[1]) {
      list = list.filter((x) => x.is_online == true);
    }
    let search = (
      document.getElementById('search_devices_list_input')! as HTMLInputElement
    ).value
      .trim()
      .toLowerCase();
    if (search != '') {
      list = list.filter(
        (x) =>
          x.id.toString().includes(search) ||
          x.name.toLowerCase().includes(search) ||
          x.sn.toLowerCase().includes(search)
      );
    }
    return list;
  }

  public get_row_option(id: number, sn: string) {
    if (this.last_row_option_opened == '') {
      this.last_row_option_opened = id + '_' + sn + '_collapsed';
    }
    //user close row option open
    else if (this.last_row_option_opened == id + '_' + sn + '_collapsed') {
      this.last_row_option_opened = '';
    }
    //user select a row option when another is open
    else if (this.last_row_option_opened != id + '_' + sn + '_collapsed') {
      //close last row option
      AppCollapsible.width_collapsible(
        this.last_row_option_opened,
        210,
        true,
        0
      );
      this.last_row_option_opened = id + '_' + sn + '_collapsed';
    }
    AppCollapsible.width_collapsible(id + '_' + sn + '_collapsed', 210, true);
  }
  public fromTimestampToString(timestamp: string) {
    let date = new Date(Number(timestamp));

    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum_hour2_minute2
    );
  }
  public fromTimestampToDate(timestamp: string) {
    return new Date(Number(timestamp));
  }
}
