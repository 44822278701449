<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="body" *ngIf="this.app.pages.types[3]">
  <table class="path" *ngIf="this.view_path()">
    <tr>
      <td *ngFor="let p of this.title_path; let i = index">
        <a> > </a>
        <a
          class="folder_link"
          (click)="this.change_folder(this.title_path[i].label)"
        >
          {{ p.label }}
        </a>
      </td>
    </tr>
  </table>
  <div
    class="fixed_table"
    *ngIf="
      this.component.types[ComponentType.HOME] ||
      this.component.types[ComponentType.SETTINGS]
    "
  >
    <!-- Home -->
    <table class="list" *ngIf="this.component.types[ComponentType.HOME]">
      <tr
        (click)="
          this.change_component(ComponentType.DEVICE_LIST);
          this.menu.changeMenu(SubMenu.DEVICE_LIST_MENU, SubMenu.HOME_MENU)
        "
      >
        <th>
          <app-home-menu-button
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Device list'"
            [icon_src]="'../../assets/icon/home-menu/device_list.png'"
          ></app-home-menu-button>
          <p>
            View a list of your registered devices and devices shared by other
            users.
          </p>
        </th>
      </tr>
      <tr>
        <th>
          <app-home-menu-button
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Service'"
            [icon_src]="'../../assets/icon/home-menu/service.png'"
          ></app-home-menu-button>
          <p>Coming soon</p>
        </th>
      </tr>
      <tr
        (click)="
          this.change_component(ComponentType.SETTINGS);
          this.menu.changeMenu(SubMenu.SETTINGS_MENU, SubMenu.HOME_MENU)
        "
      >
        <th>
          <app-home-menu-button
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Settings'"
            [icon_src]="'../../assets/icon/home-menu/settings.svg'"
          ></app-home-menu-button>
          <p>
            Manage user parameters, update password, and delete the account.
          </p>
        </th>
      </tr>
      <tr
        *ngIf="this.is_developer"
        (click)="
          this.change_component(ComponentType.DASHBOARD_ADMIN_AREA);
          this.menu.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU)
        "
      >
        <th>
          <app-home-menu-button
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Admin area'"
            [icon_src]="'../../assets/icon/home-menu/key.svg'"
          ></app-home-menu-button>
          <p>Manage ae-portal.</p>
        </th>
      </tr>
    </table>
    <!-- Settings -->
    <table class="list" *ngIf="this.component.types[ComponentType.SETTINGS]">
      <tr (click)="this.change_component(ComponentType.PROFILE)">
        <th>
          <app-home-menu-button
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Profile'"
            [icon_src]="'../../assets/icon/home-menu/account icon.svg'"
          ></app-home-menu-button>
          <p>Edit your profile data.</p>
        </th>
      </tr>
      <tr (click)="this.change_component(ComponentType.PASSWORD)">
        <th>
          <app-home-menu-button
            [class]="'menu_button'"
            [background_color]="'#343334'"
            [hover_background_color]="'#343334'"
            [label]="'Change password'"
            [icon_src]="'../../assets/icon/home-menu/lock icon.svg'"
          ></app-home-menu-button>
          <p>Change your password.</p>
        </th>
      </tr>
    </table>
  </div>
  <app-home-menu (component)="this.change_component($event)"></app-home-menu>
  <div
    (click)="this.menu.outnav()"
    *ngIf="!this.component.types[ComponentType.HOME]"
  >
    <app-profile
      *ngIf="this.component.types[ComponentType.PROFILE]"
      [id]="this.app.token.id"
    ></app-profile>
    <app-password
      *ngIf="this.component.types[ComponentType.PASSWORD]"
      [id]="this.app.token.id"
      [update_token]="true"
    ></app-password>
    <app-device-list
      *ngIf="this.component.types[ComponentType.DEVICE_LIST]"
    ></app-device-list>
    <app-battery-list
      *ngIf="this.component.types[ComponentType.BATTERY_DETAILS]"
    ></app-battery-list>
    <!-- admin tools -->
    <app-log-access
      *ngIf="this.component.types[ComponentType.LOG_ACCESS]"
    ></app-log-access>
    <app-terminal
      [host]="'grafana.archimede-energia.com'"
      [port]="9001"
      [username]="'mqtt-archimede'"
      [password]="'mqTT@ch12020'"
      [device_sn]="'aeportal'"
      [sfxPublishTopic]="'/console/in'"
      [sfxSubscribeTopic]="'/console/out'"
      [pfxTopic]="this.app.console_topic"
      *ngIf="this.component.types[ComponentType.SERVER_CONSOLE]"
    ></app-terminal>
    <app-manage-users
      *ngIf="this.component.types[ComponentType.MANAGE_USERS]"
    ></app-manage-users>
    <app-dashboard
      *ngIf="this.component.types[ComponentType.DASHBOARD_ADMIN_AREA]"
    ></app-dashboard>
    <app-manage-devices
      *ngIf="this.component.types[ComponentType.MANAGE_DEVICES]"
    ></app-manage-devices>
    <!--  -->
    <!-- advanced tools -->
    <app-advanced-general
      *ngIf="this.component.types[ComponentType.ADVANCED_GENERAL]"
    ></app-advanced-general>
    <app-terminal
      [host]="'grafana.archimede-energia.com'"
      [port]="9001"
      [username]="'mqtt-archimede'"
      [password]="'mqTT@ch12020'"
      [device_sn]="this.devMan.device.sn"
      [sfxPublishTopic]="'/console/in'"
      [sfxSubscribeTopic]="'/console/out'"
      [pfxTopic]="'units/' + this.devMan.device.sn"
      [name]="this.devMan.device.alias"
      [target]="this.devMan.device.sn"
      *ngIf="this.component.types[ComponentType.ADVANCED_CONSOLE]"
    ></app-terminal>
    <app-device-settings
      *ngIf="this.component.types[ComponentType.DEVICE_SETTINGS]"
    ></app-device-settings>
    <!--  -->
  </div>
</div>
