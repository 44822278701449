<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="central_block">
  <div id="circle_right" class="circle" (click)="this.close.emit(false)">
    <img
      class="icon_close"
      src="../../assets/icon/close.svg"
      alt=""
      srcset=""
    />
  </div>
  <div class="form">
    <app-option
      *ngIf="this.option_type_input == ''"
      [title]="'Option'"
      [subTitle]="'Select the option to do on this device.'"
      [button_index]="this.button_index"
      [tools_imgs]="this.tools_imgs"
      [tools_imgs_over]="this.tools_imgs_over"
      [is_over]="this.is_over"
      [tools_title]="this.tools_title"
      [tools_label]="this.tools_label"
      [disable]="[false, false, false]"
      (command)="this.command($event)"
    ></app-option>
    <app-calendar
      *ngIf="this.calendar_start"
      (return)="this.startValueDate = $event"
      (close)="this.calendar_start = $event"
      [value]="this.startValueDate"
      [max]="this.endValueDate"
    ></app-calendar>
    <app-calendar
      *ngIf="this.calendar_end"
      (return)="this.endValueDate = $event"
      (close)="this.calendar_end = $event"
      [value]="this.endValueDate"
      [min]="this.startValueDate"
    ></app-calendar>

    <p
      *ngIf="this.option_type_input != ''"
      class="label"
      style="margin-top: 0px; margin-bottom: 5px; float: none"
    >
      Device ID: {{ this.id }}
    </p>
    <p
      *ngIf="this.option_type_input != ''"
      class="label"
      style="margin-top: 0px; margin-bottom: 20px; float: none"
    >
      Device SN: {{ this.sn }}
    </p>
    <p class="label" style="margin-top: 0px; float: none">
      {{ this.title }}
    </p>
    <table *ngIf="this.option_type_input == 'delete log data'" class="label" style="margin-top: 10px; float: none">
      <tr>
        <td class="td_action" (click)="this.calendar_start = true">
          Start date:
        </td>
        <td>{{ this.formatDate(this.startValueDate) }}</td>
        <td class="td_action" style="padding-left: 15px">Start time:</td>
      </tr>
      <tr>
        <td class="td_action" (click)="this.calendar_end = true">End date:</td>
        <td>{{ this.formatDate(this.endValueDate) }}</td>
        <td class="td_action" style="padding-left: 15px">End time:</td>
      </tr>
    </table>

    <div>
      <button
        *ngIf="this.option_type_input != ''"
        class="submit delete"
        (click)="this.close.emit(false)"
      >
        Cancel
      </button>
      <button
        class="submit"
        *ngIf="this.option_type_input == 'delete log data' && !this.confirm_delete"
        (click)="this.confirm_delete = true; this.change_view('delete log data')"
      >
        Delete
      </button>
      <button
        class="submit"
        [ngStyle]="{
          display: this.option_type_input != 'delete log data' ? 'none' : ''
        }"
        *ngIf="this.option_type_input == 'delete log data' && this.confirm_delete"
        (click)="this.delete_device_log_data()"
      >
        I'm sure
      </button>
    </div>
  </div>
</div>
