<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="block">
  <div>
    <p class="title">Manage devices</p>
  </div>
  <div id="title_menu">
    <div class="list_message">{{ this.dashboard_message }}</div>
    <app-dashboard-menu
      class="dashboard_menu"
      [mark_color]="'#50a17c'"
      [button_active]="'multi'"
      [string_buttons]="this.menu_string_option"
      [icon_width]="['25px', '25px']"
      [icon_height]="['26px', '26px']"
      [icon_margin]="['0px 3px 0px 0px', '0px 3px 0px 0px']"
      [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px']"
      [buttons]="this.menu_options"
      [icons]="this.menu_icons"
      [is_active]="this.is_active"
      (response)="this.command($event)"
    ></app-dashboard-menu>
    <div id="search_devices_list">
      <img
        class="search"
        src="../../assets/icon/device-list/device_list_menu/search.svg"
        alt=""
      />
      <input
        id="search_devices_list_input"
        class="input"
        type="text"
        placeholder="Search for a device"
        (input)="this.devices_list_filter()"
      />
    </div>
    <app-admin-device-options
      *ngIf="this.view_option"
      [id]="this.view_option_id"
      [sn]="this.view_option_sn"
      [option_type_input]="this.option_type"
      [first_transmission]="this.fromTimestampToDate(this.first_transmission)"
      [last_transmission]="this.fromTimestampToDate(this.last_transmission)"
      (close)="this.view_option = $event"
    ></app-admin-device-options>
  </div>
  <div class="fixed_table" id="device_list_fixed_table">
    <table class="list">
      <thead>
        <tr>
          <th class="extra_small_td">ID</th>
          <th class="small_td">Device</th>
          <th class="sn">Name</th>
          <th class="sn">SN</th>
          <th class="transmission first_transmission">First transmission</th>
          <th class="transmission last_transmission">Last transmission</th>
          <th class="user_count">Connected users</th>
          <th class="small_td">Online</th>
          <th class="extra_small_td"></th>
        </tr>
      </thead>
      <tbody id="device_list_body">
        <tr *ngFor="let device of this.devices_list_filter(); let i = index">
          <td
            class="extra_small_td"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            {{ device.id }}
          </td>
          <td
            class="small_td"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            <img
              class="icon"
              src="{{
                this.dev_methods.device_type(device.sn.substring(0, 2))
              }}"
              alt=""
              srcset=""
            />
          </td>
          <td
            class="sn"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            {{ device.name }}
          </td>
          <td
            class="sn"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            {{ device.sn }}
          </td>

          <td
            class="transmission first_transmission"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            {{ this.fromTimestampToString(device.devInfo.first_transmission) }}
          </td>
          <td
            class="transmission last_transmission"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            {{ this.fromTimestampToString(device.devInfo.last_transmission) }}
          </td>
          <td
            class="user_count"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            {{ device.user_count }}
          </td>
          <td
            class="small_td"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            <img
              class="icon"
              *ngIf="!device.is_online"
              src="../../assets/icon/device-list/offline_red.svg"
              alt=""
              srcset=""
            />
            <img
              class="icon"
              *ngIf="device.is_online"
              src="../../assets/icon/device-list/online_green.svg"
              alt=""
              srcset=""
            />
          </td>
          <td
            class="extra_small_td"
            [ngStyle]="{
              background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
            }"
          >
            <img
              class="open_icon"
              [ngStyle]="{
                background: i % 2 != 0 ? 'rgb(60,60,60)' : ''
              }"
              src="../../assets/icon/arrow/left.svg"
              alt=""
              srcset=""
              (click)="this.get_row_option(device.id, device.sn)"
            />
          </td>
          <div
            id="{{ device.id }}_{{ device.sn }}_collapsed"
            class="horizontal_content_collapsed"
          >
            <td style="background: gray">
              <img
                class="icon"
                src="../../assets/icon/device-list/ellipsis.svg"
                alt=""
                srcset=""
                (click)="
                  this.option = this.view_option = true;
                  this.view_option_id = device.id;
                  this.view_option_sn = device.sn;
                  this.option_type = '';
                  this.first_transmission = device.devInfo.first_transmission;
                  this.last_transmission = device.devInfo.last_transmission
                "
                style="cursor: pointer"
                [ngStyle]="{
                  'pointer-events': this.view_option ? 'none' : 'all'
                }"
              />
            </td>
            <td style="background: rgb(255, 86, 86)">
              <img
                class="icon"
                src="../../assets/icon/device-list/delete_white.svg"
                alt=""
                srcset=""
                (click)="
                  this.view_option = true;
                  this.option = false;
                  this.view_option_id = device.id;
                  this.view_option_sn = device.sn;
                  this.option_type = 'delete log data';
                  this.first_transmission = device.devInfo.first_transmission;
                  this.last_transmission = device.devInfo.last_transmission
                "
                style="cursor: pointer"
                [ngStyle]="{
                  'pointer-events': this.view_option ? 'none' : 'all'
                }"
              />
            </td>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</div>
