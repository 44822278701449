/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Gauge } from 'src/model/chart/gauge';
import { Command } from 'src/app/dashboard-menu/command';
import { AppDate } from 'src/model/app.date';
import {
  DeviceManager,
  Battery,
  BatteryMethods,
} from 'src/model/app.deviceManager';

@Component({
  selector: 'app-battery-general',
  templateUrl: './battery-general.component.html',
  styleUrls: ['./battery-general.component.css'],
})
export class BatteryGeneralComponent implements OnInit, OnChanges {
  private _update: boolean = this.devMan.update;
  @Input() public set update(value: boolean) {
    this._update = value;
    if (this._update) {
      this.updateGauge();
      this.updateSW();
    }
    this._update = false;
  }

  public valid_input: boolean = false;

  public gauge: Gauge = new Gauge('gauge', 0, 3 / 2, '#50a17c', 0, 100, 15);

  public sw: string[] = [];
  public sw_hex: string = '';

  public sw_labels: string[] = [
    'Under voltage',
    'Over voltage',
    'PEC error',
    'Contactor error',
    'Unbalanced',
    'Over discharging temperature',
    'Under discharging temperature',
    'Under charging temperature',
    'Enable charge',
    'Enable discharge',
    'Over charging temperature',
    'Charge hysteresis',
    'Power on',
    'Enable battery',
    'Current in range',
    'Reached absolute voltage limits',
  ];

  public sw2: string[] = [];
  public sw2_hex: string = '';

  public sw2_labels: string[] = [
    'Warning minimum voltage',
    'Warning maximum voltage',
    'Warning minimum charging temperature',
    'Warning maximum charging temperature',
    'Warning minimum discharging temperature',
    'Warning maximum discharging temperature',
    'Enable viasat',
    'SD card error',
    'Battery present',
    'Warning humidity',
    'Viasat open',
    'Viasat close',
    'Not used',
    'Balance performing',
    'Not used',
    'General error',
  ];

  public is_cluster: boolean = true;

  public menu_string_option: string[] = ['sync'];
  public menu_options: boolean[] = [false];
  public menu_icons: string[] = ['../../assets/icon/advanced/sync_white.svg'];

  constructor(
    public devMan: DeviceManager,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.updateGauge();
    this.updateSW();
  }

  ngOnInit(): void {
    this.updateGauge();
    this.updateSW();
  }
  public command(command: Command = new Command('', [false])) {
    switch (command.command) {
      case 'sync':
        this.devMan.tools.synchronize();
        break;
    }
  }

  public updateGauge() {
    if (
      this.gauge.perc != this.devMan.getCurrentBattery().soc ||
      this.devMan.getCurrentBattery().soc == 0
    ) {
      this.gauge.perc = this.devMan.getCurrentBattery().soc;
      this.gauge.draw();
    }
  }

  public updateSW() {
    this.sw = BatteryMethods.dec2bin(this.devMan.getCurrentBattery().sw, 16);
    this.sw_hex = BatteryMethods.dec2hex(
      this.devMan.getCurrentBattery().sw.toString(),
      4
    );
    this.is_cluster = true;
    if (!BatteryMethods.is_cluster(this.devMan.getCurrentBattery().sn)) {
      this.sw2 = BatteryMethods.dec2bin(
        this.devMan.getCurrentBattery().sw2,
        16
      );
      this.sw2_hex = BatteryMethods.dec2hex(
        this.devMan.getCurrentBattery().sw2.toString(),
        4
      );
      this.is_cluster = false;
    }
  }
  public state(decString: string, precision: number) {
    return BatteryMethods.dec2hex(decString, precision);
  }
  public checkAlarm(device: Battery) {
    return BatteryMethods.check_battery_alarm(device);
  }

  public lastUpdate(timestamp: string) {
    let date = new Date(Number(timestamp) * 1000);

    return AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum_hour2_minute2
    );
  }
}
