/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { HomeComponent } from 'src/app/home/home.component';
import {
  DeviceManager,
  DeviceMethods,
  DeviceType,
} from 'src/model/app.deviceManager';
import { AdvancedToolPermissions } from '../advanced-tool-permissions';

@Component({
  selector: 'app-advanced-option',
  templateUrl: './advanced-option.component.html',
  styleUrls: ['./advanced-option.component.css'],
})
export class AdvancedOptionComponent implements OnChanges {
  // 0 datalogger 1 battery
  @Input() type: DeviceType = DeviceType.datalogger;

  @Input() view_option: boolean = false;
  @Output() close = new EventEmitter<boolean>();
  @Output() command = new EventEmitter<string>();

  public button_index: number[] = [
    toolType.settings,
    toolType.gpio,
    toolType.console,
  ];

  public tools_imgs: string[] = [
    '../../assets/icon/advanced/parameter.svg',
    '../../assets/icon/advanced/gpio.svg',
    '../../assets/icon/advanced/console.svg',
  ];
  public tools_imgs_over: string[] = [
    '../../assets/icon/advanced/parameter_white.svg',
    '../../assets/icon/advanced/gpio_white.svg',
    '../../assets/icon/advanced/console_white.svg',
  ];
  public is_over: boolean[] = [false, false, false];
  public tools_title: string[] = ['Device settings', 'GPIO Control', 'Console'];
  public tools_label: string[] = [
    'Modify device parameters',
    'Configure read/write GPIO',
    'Available for manufacturer on computer screens',
  ];

  public disable: boolean[] = [false, false, false];

  public dev_methods: DeviceMethods = new DeviceMethods();

  constructor(public home: HomeComponent, public devMan: DeviceManager) {}
  ngOnChanges(): void {
    this.set_option();
  }

  public set_option() {
    if (this.type == DeviceType.datalogger) {
      if (this.dev_methods.isLinuxDevice(this.devMan.device.sn)) {
        this.button_index = [
          toolType.settings,
          toolType.gpio,
          toolType.console,
        ];
      } else {
        this.button_index = [toolType.settings, toolType.gpio];
      }
    } else this.button_index = [toolType.settings, toolType.gpio];
    // Disable tools not allowed
    this.disable[toolType.gpio] = AdvancedToolPermissions.disableGPIO();
    this.disable[toolType.settings] = AdvancedToolPermissions.disableSettings(
      this.type,
      this.home.user_type,
      this.devMan.getCurrentBattery().sn
    );
    this.disable[toolType.console] = AdvancedToolPermissions.disableConsole(
      this.type,
      this.home.user_type,
      this.devMan.device.sn
    );
  }
}

enum toolType {
  console = 2,
  gpio = 1,
  settings = 0,
}
