/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Regex } from 'src/model/Regex';
import { AppRoutingModule, Message } from '../../app-routing.module';
import { Data_registration } from '../../public_component/registration/data_registration';
import { HomeComponent } from 'src/app/home/home.component';
import { User } from 'src/app/advanced/advanced-user/user';
import { WarningColor } from 'src/app/warning/warning.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  /**
   * required: 0 name 1 username 2 email 3 phone  4 city 5 country
   */
  /**
   * unrequired: company
   */

  public data_registration = new Data_registration(
    ['name', 'username', 'email', 'phone', 'city', 'country'],
    ['usertype', 'company'],
    this.http,
    this.app
  );

  public is_sure: boolean = false;
  public view_user_setup: boolean = false;

  @Input() id: string = '-1';

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public home: HomeComponent
  ) {}

  ngAfterViewInit(): void {
    this.get_parameters();
  }

  ngOnInit(): void {}

  public delete() {
    this.http
      .post<any>(this.app.server_domain + '/user_setting.php', {
        request: 'delete_account',
        token: this.app.token,
        id: this.id,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            console.log('Internal server error');
            return;
          }
          if (result.response == true && this.id == this.app.token.id) {
            this.app.logout();
          } else if (result.response == true) {
            this.data_registration.warning.setWarning(WarningColor.green, [
              'Account deleted',
            ]);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private get_parameters() {
    this.http
      .post<any>(this.app.server_domain + '/user_setting.php', {
        request: 'get_user_setting',
        token: this.app.token,
        id: this.id,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            console.log('Internal server error');
            return;
          }
          for (
            let i = 0;
            i < this.data_registration.data_required.length;
            i++
          ) {
            (
              document.getElementById(
                this.data_registration.data_required_input_id[i]
              )! as HTMLInputElement
            ).value = result[i];
          }
          (
            document.getElementById(
              this.data_registration.data_unrequired_input_id[0]
            )! as HTMLInputElement
          ).value = User.user_type_from_number_to_string(result[6]);
          (
            document.getElementById(
              this.data_registration.data_unrequired_input_id[1]
            )! as HTMLInputElement
          ).value = result[7];
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private get_message(): string[] {
    let message: string[] = [];
    if (!Regex.regex_phone(this.data_registration.data_required[3])) {
      message.push(`• Phone number must contain only numbers.`);
    }
    return message;
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  public onSubmit(): void {
    this.data_registration.warning.setViewWarning(false);
    if (
      !this.data_registration.required() &&
      Regex.regex_phone(this.data_registration.data_required[3])
    ) {
      this.http
        .post<Message>(this.app.server_domain + '/user_setting.php', {
          request: 'change_user_setting',
          token: this.app.token,
          id: this.id,
          name: this.data_registration.data_required[0],
          phone_number: this.data_registration.data_required[3],
          company: this.data_registration.data_unrequired[1],
          city: this.data_registration.data_required[4],
          country: this.data_registration.data_required[5],
          usertype: User.user_type_from_string_to_number(
            this.data_registration.data_unrequired[0]
          ),
        })
        .subscribe(
          (result) => {
            if (!result.response) {
              this.data_registration.warning.setWarning(
                WarningColor.red,
                this.get_message()
              );
            } else {
              this.data_registration.warning.setWarning(WarningColor.green, [
                result.message,
              ]);
              this.home.get_user_parameters();
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.data_registration.warning.setWarning(
        WarningColor.red,
        this.get_message()
      );
    }
  }

  public set_user(user: string) {
    (
      document.getElementById(
        this.data_registration.data_unrequired_input_id[0]
      )! as HTMLInputElement
    ).value = user;
  }
}
