/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppDate } from 'src/model/app.date';

export class ManageUsers {
  public log_access_list: LogAccess[] = [];
  public dashboard_message: string = 'Total users: ';
  public active_users: number = 0;
  public valid_users: number = 0;

  constructor(public app: AppRoutingModule, public http: HttpClient) {}

  public get_log_access_list() {
    this.http
      .post<LogAccess[]>(this.app.server_domain + '/user_setting.php', {
        request: 'get_log_access',
        token: this.app.token,
      })
      .subscribe(
        (result) => {
          this.log_access_list = result;
          this.update_message();
          this.activeUsers();
          this.validUsers();
        },
        (error) => {
          console.error(error);
        }
      );
  }
  private update_message() {
    let list: LogAccess[] = [];
    list = this.log_access_list;
    this.dashboard_message = 'Total users: ';
    this.dashboard_message += list.length;
  }

  private activeUsers() {
    let date = new Date();
    date.setUTCHours(-24 * 3);
    this.active_users = this.log_access_list.filter(
      (x) => new Date(x.max_last_access).getTime() >= date.getTime()
    ).length;
  }

  public validUsers() {
    this.valid_users = this.log_access_list.filter(
      (x) => x.account_is_valid == true
    ).length;
  }

  public log_access_list_filter() {
    let list: LogAccess[] = [];
    list = this.log_access_list;
    let search = (
      document.getElementById(
        'search_log_access_list_input'
      )! as HTMLInputElement
    ).value
      .trim()
      .toLowerCase();
    if (search != '') {
      list = list.filter(
        (x) =>
          x.account_id.toString().toLowerCase().includes(search) ||
          x.account_name.toLowerCase().includes(search) ||
          x.account_email.toLowerCase().includes(search) ||
          x.account_company.toLowerCase().includes(search)
      );
    }
    return list;
  }

  public formatDate(date: string) {
    if (date == 'Never') {
      return date;
    }
    let tmp = new Date(date);
    if (AppDate.is_today(tmp))
      return (
        'Today at ' + AppDate.stringFromDate(tmp, AppDate.format_hour2_minute2)
      );
    return AppDate.stringFromDate(
      tmp,
      AppDate.format_day2_monthshort_yearnum_hour2_minute2
    );
  }
}

export class LogAccess {
  public max_last_access = '';
  public account_name = '';
  public account_email = '';
  public account_company = '';
  public account_id = 0;
  public device_count = 0;
  public account_date = '';
  public account_is_valid = false;
  public admin = false;
}
