<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<div class="central_block" *ngIf="this.view_filter">
  <div class="form">
    <table>
      <tr>
        <td colspan="2" class="filter_title">Filters</td>
      </tr>
      <tr>
        <td colspan="2" class="filter_subtitle">
          Select the items you want to include in the list.
        </td>
      </tr>
      <tr *ngFor="let filter of this.filters_code; let i = index">
        <td class="td_label_filter">
          {{ this.eventsDescription.get(filter) }}
        </td>
        <td class="td_label_checkbox">
          <label class="switch">
            <input
              id="{{ filter }}"
              type="checkbox"
              [checked]="this.filter ? this.active_checkbox[i] : true"
            />
            <span class="slider round"></span>
          </label>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="filter_reset">
          <button class="reset" (click)="this.reset()">Reset</button>
        </td>
      </tr>
    </table>
    <div>
      <button class="submit delete" (click)="this.view_filter = false">
        CLOSE
      </button>
      <button class="submit" (click)="this.apply_filter()">APPLY</button>
    </div>
  </div>
</div>
<div class="block">
  <div>
    <div class="list_message">Total events: {{ this.events.length }}</div>
    <div class="list_message date_message">{{ this.date }}</div>
    <div *ngIf="this.filter" class="list_message date_message filter_message">
      <a
        (click)="this.view_filter = !this.view_filter"
        [ngStyle]="{
          'pointer-events': this.calendar ? 'none' : 'all'
        }"
        >{{ this.short_description_filters }}</a
      >
    </div>
    <app-calendar
      *ngIf="this.calendar"
      (return)="
        this.valueDate = $event; this.rows_offset = 0; this.updateHistory()
      "
      (close)="this.calendar = $event"
      (update_calendar)="
        this.calendarEvent.getMonthEvents(
          $event,
          this.http,
          this.devMan,
          this.app
        )
      "
      [value]="this.valueDate"
      [max]="this.max_date"
      [with_events]="true"
      [events]="this.calendarEvent.events"
      [color]="'#FFB347'"
    ></app-calendar>
    <app-dashboard-menu
      [ngStyle]="{
        'pointer-events': this.calendar || this.view_filter ? 'none' : 'all'
      }"
      class="dashboard_menu"
      [mark_color]="'#50a17c'"
      [button_active]="'multi'"
      [string_buttons]="this.menu_string_option"
      [icon_width]="['29px', '25px', '23px']"
      [icon_height]="['29px', '25px', '23px']"
      [icon_margin]="['-2px 0px 0px 0px', '0px 3px 0px 0px', '2px 3px 0px 0px']"
      [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px', '0px 0px 0px 0px']"
      [buttons]="this.menu_options"
      [icons]="this.menu_icons"
      [is_active]="[this.devMan.tools.wait_sync, this.calendar, this.filter]"
      (response)="this.command($event)"
    ></app-dashboard-menu>
  </div>
  <div class="fixed_table">
    <table class="list">
      <thead>
        <tr>
          <th style="padding-left: 8px">Description</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of this.event_filter(); let i = index">
          <td
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
            style="padding-left: 0px"
          >
            <svg width="2.5px" height="34px">
              <rect
                style="fill: #ffffff"
                width="2.5px"
                height="34px"
                x="0"
                y="0"
                ry="0"
                [ngStyle]="{
                  fill: getEventType(event).color
                }"
              />
            </svg>

            {{ this.eventsDescription.get(this.event.code) }}
          </td>
          <td [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }">
            {{
              getEventType(event).initString +
                this.event_date(this.event.date.getTime())
            }}
          </td>
        </tr>
      </tbody>
      <tr class="last_row">
        <td>{{ this.range_rows }} / {{ this.num_events_filter }}</td>
        <td></td>
        <td></td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.left.svg"
            alt=""
            (click)="this.change_offset(false)"
          />
        </td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.right.svg"
            alt=""
            (click)="this.change_offset(true)"
          />
        </td>
      </tr>
    </table>
  </div>
</div>
