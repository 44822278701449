/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { AppCollapsible } from '../../model/app.collapsible';
import { ComponentType, HomeComponent } from '../home/home.component';
import { DeviceManager } from 'src/model/app.deviceManager';
import { userTypeString } from '../advanced/advanced-user/user';
import { AdvancedToolPermissions } from '../advanced/advanced-tool-permissions';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.css'],
})
export class HomeMenuComponent implements OnInit, AfterViewInit {
  @Output() component = new EventEmitter<ComponentType>();

  protected ComponentType = ComponentType;

  protected view_user_setup: boolean = false;

  protected menuLevels!: {
    class: string;
    id: string;
    nodes: MenuItem[];
  }[];

  private menuTree: MenuItem[] = [
    {
      label: 'Home',
      idContainer: SubMenu.HOME_MENU,
      class: 'horizontal_content_visible',
      componentType: ComponentType.HOME,
      isShown: () => {
        return false;
      },
      children: [
        {
          label: 'Device list',
          iconSrc: '../../assets/icon/home-menu/device_list.png',
          idContainer: SubMenu.DEVICE_LIST_MENU,
          componentType: ComponentType.DEVICE_LIST,
          goBack: () => {
            this.changeMenu(SubMenu.DEVICE_LIST_MENU, SubMenu.HOME_MENU);
          },
          isActive: () => {
            return this.home.component.types[ComponentType.DEVICE_LIST];
          },
          onClick: () => {
            this.changeMenu(SubMenu.DEVICE_LIST_MENU, SubMenu.HOME_MENU);
            this.component.emit(ComponentType.DEVICE_LIST);
          },
          children: [
            {
              label: 'Back',
              iconSrc: '../../assets/icon/home-menu/arrow_back.svg',
              isActive: () => {
                return false;
              },
              onClick: () => {
                this.changeMenu(SubMenu.DEVICE_LIST_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
            },
            {
              idContainer: SubMenu.DEVICE_MENU,
              isShown: () => {
                return false;
              },
              children: [
                {
                  label: 'Back',
                  iconSrc: '../../assets/icon/home-menu/arrow_back.svg',
                  isActive: () => {
                    return false;
                  },
                  onClick: () => {
                    this.changeMenu(
                      SubMenu.DEVICE_MENU,
                      SubMenu.DEVICE_LIST_MENU
                    );
                    this.component.emit(ComponentType.DEVICE_LIST);
                  },
                },
                {
                  label: 'Battery details',
                  iconSrc:
                    '../../assets/icon/battery-details/minus.plus.batteryblock.fill.svg',
                  componentType: ComponentType.BATTERY_DETAILS,
                  goBack: () => {
                    this.changeMenu(
                      SubMenu.DEVICE_MENU,
                      SubMenu.DEVICE_LIST_MENU
                    );
                  },
                  isActive: () => {
                    return this.home.component.types[
                      ComponentType.BATTERY_DETAILS
                    ];
                  },
                  onClick: () => {
                    this.component.emit(ComponentType.BATTERY_DETAILS);
                  },
                },
                {
                  label: 'Advanced',
                  iconSrc: '../../assets/icon/home-menu/shield.png',
                  idContainer: SubMenu.ADVANCED_MENU,
                  componentType: ComponentType.ADVANCED_GENERAL,
                  goBack: () => {
                    this.changeMenu(
                      SubMenu.DEVICE_MENU,
                      SubMenu.DEVICE_LIST_MENU
                    );
                  },
                  isActive: () => {
                    return this.home.component.types[
                      ComponentType.ADVANCED_GENERAL
                    ];
                  },
                  onClick: () => {
                    this.component.emit(ComponentType.ADVANCED_GENERAL);
                  },
                  children: [
                    {
                      label: 'Back',
                      iconSrc: '../../assets/icon/home-menu/arrow_back.svg',
                      onClick: () => {
                        this.changeMenu(
                          SubMenu.ADVANCED_MENU,
                          SubMenu.DEVICE_MENU
                        );
                        this.component.emit(ComponentType.ADVANCED_GENERAL);
                      },
                    },
                    {
                      label: 'Device settings',
                      iconSrc: '../../assets/icon/advanced/parameter_white.svg',
                      componentType: ComponentType.DEVICE_SETTINGS,
                      goBack: () => {
                        this.changeMenu(
                          SubMenu.ADVANCED_MENU,
                          SubMenu.DEVICE_MENU
                        );
                      },
                      isShown: () => {
                        if (
                          this.home.component.types[
                            ComponentType.DEVICE_SETTINGS
                          ] ||
                          this.home.component.types[
                            ComponentType.ADVANCED_CONSOLE
                          ]
                        )
                          return true;
                        return false;
                      },
                      isActive: () => {
                        return this.home.component.types[
                          ComponentType.DEVICE_SETTINGS
                        ];
                      },
                      onClick: () => {
                        this.component.emit(ComponentType.DEVICE_SETTINGS);
                      },
                      isDisable: () => {
                        return AdvancedToolPermissions.disableSettings(
                          this.devMan.type,
                          this.home.user_type,
                          this.devMan.getCurrentBattery().sn
                        );
                      },
                    },
                    {
                      label: 'Console',
                      iconSrc: '../../assets/icon/advanced/console_white.svg',
                      componentType: ComponentType.ADVANCED_CONSOLE,
                      goBack: () => {
                        this.changeMenu(
                          SubMenu.ADVANCED_MENU,
                          SubMenu.DEVICE_MENU
                        );
                      },
                      isShown: () => {
                        if (
                          this.home.component.types[
                            ComponentType.DEVICE_SETTINGS
                          ] ||
                          this.home.component.types[
                            ComponentType.ADVANCED_CONSOLE
                          ]
                        )
                          return true;
                        return false;
                      },
                      isActive: () => {
                        return this.home.component.types[
                          ComponentType.ADVANCED_CONSOLE
                        ];
                      },
                      onClick: () => {
                        this.component.emit(ComponentType.ADVANCED_CONSOLE);
                      },
                      isDisable: () => {
                        return AdvancedToolPermissions.disableConsole(
                          this.devMan.type,
                          this.home.user_type,
                          this.devMan.device.sn
                        );
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Service',
          iconSrc: '../../assets/icon/home-menu/service.png',
        },
        {
          label: 'Settings',
          iconSrc: '../../assets/icon/home-menu/settings.svg',
          idContainer: SubMenu.SETTINGS_MENU,
          componentType: ComponentType.SETTINGS,
          goBack: () => {
            this.changeMenu(SubMenu.SETTINGS_MENU, SubMenu.HOME_MENU);
          },
          isActive: () => {
            return this.home.component.types[ComponentType.SETTINGS];
          },
          onClick: () => {
            this.changeMenu(SubMenu.SETTINGS_MENU, SubMenu.HOME_MENU);
            this.component.emit(ComponentType.SETTINGS);
          },
          children: [
            {
              label: 'Back',
              iconSrc: '../../assets/icon/home-menu/arrow_back.svg',
              onClick: () => {
                if (
                  this.home.title_path[this.home.title_path.length - 1].label ==
                  'Settings'
                ) {
                  this.changeMenu(SubMenu.SETTINGS_MENU, SubMenu.HOME_MENU);
                  this.component.emit(ComponentType.HOME);
                } else {
                  this.component.emit(ComponentType.SETTINGS);
                }
              },
            },
            {
              label: 'Profile',
              iconSrc: '../../assets/icon/home-menu/account icon.svg',
              componentType: ComponentType.PROFILE,
              goBack: () => {
                if (
                  this.home.title_path[this.home.title_path.length - 1].label ==
                  'Settings'
                ) {
                  this.changeMenu(SubMenu.SETTINGS_MENU, SubMenu.HOME_MENU);
                  this.component.emit(ComponentType.HOME);
                } else {
                  this.component.emit(ComponentType.SETTINGS);
                }
              },
              isActive: () => {
                return this.home.component.types[ComponentType.PROFILE];
              },
              onClick: () => {
                this.component.emit(ComponentType.PROFILE);
              },
            },
            {
              label: 'Change password',
              iconSrc: '../../assets/icon/home-menu/lock icon.svg',
              componentType: ComponentType.PASSWORD,
              goBack: () => {
                if (
                  this.home.title_path[this.home.title_path.length - 1].label ==
                  'Settings'
                ) {
                  this.changeMenu(SubMenu.SETTINGS_MENU, SubMenu.HOME_MENU);
                  this.component.emit(ComponentType.HOME);
                } else {
                  this.component.emit(ComponentType.SETTINGS);
                }
              },
              isActive: () => {
                return this.home.component.types[ComponentType.PASSWORD];
              },
              onClick: () => {
                this.component.emit(ComponentType.PASSWORD);
              },
            },
          ],
        },
        {
          label: 'Admin area',
          iconSrc: '../../assets/icon/home-menu/key.svg',
          idContainer: SubMenu.ADMIN_AREA_MENU,
          isShown: () => {
            return this.home.is_developer;
          },
          isActive: () => {
            return this.home.component.types[ComponentType.ADMIN_AREA];
          },
          onClick: () => {
            this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
            this.component.emit(ComponentType.DASHBOARD_ADMIN_AREA);
          },
          children: [
            {
              label: 'Back',
              iconSrc: '../../assets/icon/home-menu/arrow_back.svg',
              onClick: () => {
                this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
            },
            {
              label: 'Dashboard',
              iconSrc: '../../assets/icon/home-menu/dashboard.svg',
              componentType: ComponentType.DASHBOARD_ADMIN_AREA,
              goBack: () => {
                this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
              onClick: () => {
                this.component.emit(ComponentType.DASHBOARD_ADMIN_AREA);
              },

              isActive: () => {
                return this.home.component.types[
                  ComponentType.DASHBOARD_ADMIN_AREA
                ];
              },
            },
            {
              label: 'Log access',
              iconSrc: '../../assets/icon/home-menu/log_access.svg',
              componentType: ComponentType.LOG_ACCESS,
              goBack: () => {
                this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
              onClick: () => {
                this.component.emit(ComponentType.LOG_ACCESS);
              },
              isActive: () => {
                return this.home.component.types[ComponentType.LOG_ACCESS];
              },
            },
            {
              label: 'Manage users',
              iconSrc: '../../assets/icon/home-menu/manage users.svg',
              componentType: ComponentType.MANAGE_USERS,
              goBack: () => {
                this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
              onClick: () => {
                this.component.emit(ComponentType.MANAGE_USERS);
              },
              isActive: () => {
                return this.home.component.types[ComponentType.MANAGE_USERS];
              },
            },
            {
              label: 'Manage devices',
              iconSrc: '../../assets/icon/home-menu/device_config.png',
              componentType: ComponentType.MANAGE_DEVICES,
              goBack: () => {
                this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
              onClick: () => {
                this.component.emit(ComponentType.MANAGE_DEVICES);
              },
              isActive: () => {
                return this.home.component.types[ComponentType.MANAGE_DEVICES];
              },
            },
            {
              label: 'Server console',
              iconSrc: '../../assets/icon/advanced/console_white.svg',
              componentType: ComponentType.SERVER_CONSOLE,
              goBack: () => {
                this.changeMenu(SubMenu.ADMIN_AREA_MENU, SubMenu.HOME_MENU);
                this.component.emit(ComponentType.HOME);
              },
              onClick: () => {
                this.component.emit(ComponentType.SERVER_CONSOLE);
              },
              isActive: () => {
                return this.home.component.types[ComponentType.SERVER_CONSOLE];
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    protected app: AppRoutingModule,
    protected home: HomeComponent,
    protected devMan: DeviceManager
  ) {
    this.initializeNodePaths(this.menuTree);
  }

  ngAfterViewInit(): void {
    this.outnav();
  }

  ngOnInit() {
    this.menuLevels = this.splitMenuTree(this.menuTree);
  }

  public outnav() {
    let w = window.innerWidth;
    if (w >= 1200) {
      return;
    }
    this.resize(0);
  }

  public changeMenu(setTrue: string, setFalse: string) {
    AppCollapsible.width_collapsible(setTrue, 220, true);
    AppCollapsible.width_collapsible(setFalse, 220, false);
  }

  public initializeNodePaths(
    menu: MenuItem[],
    parentPath: PathNode[] = []
  ): void {
    for (const item of menu) {
      const pathNode: PathNode = {
        label: item.label ? item.label : '',
        componentType: item.componentType,
        goBack: item.goBack ? item.goBack : () => {},
      };

      item.nodePath = [...parentPath, pathNode];

      if (item.children) {
        this.initializeNodePaths(item.children, item.nodePath);
      }
    }
  }

  public getPathByComponentType(
    componentType: ComponentType,
    menu: MenuItem[] = this.menuTree
  ): PathNode[] {
    let foundPath: PathNode[] = [];

    const findPath = (menu: MenuItem[]): PathNode[] => {
      for (const item of menu) {
        if (item.componentType === componentType) {
          return item.nodePath || [];
        }

        if (item.children) {
          const path = findPath(item.children);
          if (path.length > 0) {
            return path;
          }
        }
      }
      return [];
    };
    foundPath = findPath(menu);
    return foundPath.filter(
      (node) => node.label !== '' && node.componentType !== undefined
    );
  }

  protected getIsActive(item: MenuItem): boolean {
    // Check if item is active based on the dynamic state
    return item.isActive ? item.isActive() : false; // Defaults to false if undefined
  }

  protected getIsShown(item: MenuItem): boolean {
    // Return the display property, defaults to true if undefined
    return item.isShown !== undefined ? item.isShown() : true;
  }

  protected getOnClick(item: MenuItem): void {
    item.onClick !== undefined
      ? this.getIsDisable(item)
        ? {}
        : item.onClick()
      : {};
  }
  protected getIsDisable(item: MenuItem): boolean {
    return item.isDisable !== undefined ? item.isDisable() : false;
  }

  protected nav() {
    const menu = document.getElementById('menu')! as HTMLDivElement;
    if (menu.style.width != '0px') {
      this.resize(0);
    } else {
      this.resize(220);
    }
  }

  protected menu_resize_big_screen() {
    let w = window.innerWidth;
    if (w > 1200) {
      this.resize(220);
    } else {
      this.resize(0);
    }
  }

  protected backgroundUser() {
    if (this.home.user_type == userTypeString.enduser) return '#343334';
    else if (this.home.user_type == userTypeString.superuser)
      return 'rgb(85, 85, 127)';
    else if (this.home.user_type == userTypeString.manufacturer)
      return 'rgb(170, 0, 0)';
    else return '#343334';
  }

  private resize(px: number) {
    this.toggle_resize(px);
    this.menu_resize(px);
  }

  private toggle_resize(px: number) {
    const toggle = document.getElementById('toggle')! as HTMLDivElement;
    if (toggle) toggle.style.left = px + 'px';
  }

  private menu_resize(px: number) {
    const menu = document.getElementById('menu')! as HTMLDivElement;
    if (menu) {
      menu.style.width = px + 'px';
      menu.style.maxWidth = px + 'px';
    }
  }

  private splitMenuTree(
    menuTree: MenuItem[]
  ): { class: string; id: string; nodes: MenuItem[] }[] {
    const result: {
      class: string;
      id: string;
      nodes: MenuItem[];
    }[] = [];

    // Helper function to process each item
    const processItem = (item: MenuItem) => {
      if (item.idContainer) {
        // Create an object for the container node
        const containerObject = {
          class: item.class ? item.class : 'horizontal_content_collapsed',
          id: item.idContainer,
          nodes: item.children ? item.children : [], // Get children if exist
        };
        result.push(containerObject); // Add to result array

        // If the container has children, process each child
        if (item.children) {
          item.children.forEach((child) => processItem(child));
        }
      }
    };

    // Process each top-level item in the menu tree
    menuTree.forEach(processItem);
    return result;
  }
}

interface MenuItem {
  label?: string;
  iconSrc?: string;
  idContainer?: string;
  componentType?: ComponentType;
  class?: string;
  children?: MenuItem[];
  nodePath?: PathNode[];
  isActive?: () => boolean;
  isShown?: () => boolean;
  onClick?: () => void;
  goBack?: () => void;
  isDisable?: () => boolean;
}

export interface PathNode {
  label: string;
  componentType?: ComponentType;
  goBack: () => void; // onClick è facoltativo
}

export enum SubMenu {
  HOME_MENU = 'HOME_MENU',
  DEVICE_LIST_MENU = 'DEVICE_LIST_MENU',
  DEVICE_MENU = 'DEVICE_MENU',
  ADVANCED_MENU = 'ADVANCED_MENU',
  SETTINGS_MENU = 'SETTINGS_MENU',
  ADMIN_AREA_MENU = 'ADMIN_AREA_MENU',
}
