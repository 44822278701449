<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div
  class="block"
  *ngIf="this.home.component.types[ComponentType.ADVANCED_GENERAL]"
>
  <div>
    <p class="title">Advanced</p>
  </div>
  <div class="central_block" *ngIf="this.view_confirm">
    <div>
      <button class="circle" id="circle">
        <img
          class="icon_close"
          src="../../../assets/icon/close.svg"
          (click)="this.resetPopup()"
        />
      </button>
    </div>
    <div class="form">
      <table>
        <tr>
          <td colspan="2" class="filter_title">
            {{ this.view_confirm_title }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="filter_subtitle">
            {{ this.view_confirm_subtitle }}
          </td>
        </tr>
        <tr>
          <td>
            <button class="submit delete" (click)="this.tool_command('')">
              Cancel
            </button>
          </td>
          <td>
            <button
              class="submit"
              (click)="this.tool_command(this.view_confirm_command)"
            >
              Ok
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <app-dashboard-menu
    [ngClass]="
      this.devMan.type == DeviceType.datalogger
        ? 'dashboard_menu dashboard_menu_datalogger'
        : 'dashboard_menu dashboard_menu_battery'
    "
    [mark_color]="'#50a17c'"
    [button_active]="'single'"
    [string_buttons]="
      this.devMan.type == DeviceType.datalogger
        ? ['tools', 'reboot']
        : ['tools', 'reboot', 'reboot_all']
    "
    [icon_width]="['25px', '25px', '25px']"
    [icon_height]="['25px', '25px', '25px']"
    [icon_margin]="['0px 7px 0px 0px', '0px 40px 0px 0px', '0px 40px 0px 0px']"
    [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px', '0px 0px 0px 0px']"
    [icons]="
      this.devMan.type == DeviceType.datalogger
        ? [
            '../../../assets/icon/advanced/swiss.svg',
            '../../assets/icon/advanced/reboot_white.svg'
          ]
        : [
            '../../../assets/icon/advanced/swiss.svg',
            '../../assets/icon/advanced/reboot_white.svg',
            '../../assets/icon/advanced/reboot_all_white.svg'
          ]
    "
    [labels]="
      this.devMan.type == DeviceType.datalogger
        ? ['Tools', 'Reboot']
        : ['Tools', 'Reboot', 'Reboot all']
    "
    [labels_width]="
      this.devMan.type == DeviceType.datalogger
        ? ['43px', '85px']
        : ['43px', '85px', '87px']
    "
    [labels_height]="
      this.devMan.type == DeviceType.datalogger
        ? ['18px', '18px']
        : ['18px', '18px', '18px']
    "
    [view_labels]="this.optionsLabel()"
    [buttons]="this.options"
    [is_active]="this.is_active"
    [disable]="
      this.devMan.type == DeviceType.datalogger
        ? [false, AdvancedToolPermissions.disableReboot(this.home.user_type)]
        : [
            false,
            AdvancedToolPermissions.disableReboot(this.home.user_type),
            AdvancedToolPermissions.disableRebootAll(this.home.user_type)
          ]
    "
    (response)="this.confirmPopup($event.command, $event.active)"
  ></app-dashboard-menu>
  <app-advanced-option
    [type]="this.devMan.type"
    [view_option]="this.is_active[0]"
    (close)="this.is_active[0] = $event"
    (command)="this.command($event)"
  ></app-advanced-option>
  <div class="title_icon">
    <img src="{{ this.src_img() }}" alt="" srcset="" />
  </div>
  <div class="title_icon title_device">
    <p>{{ this.type_title[this.devMan.type] }}</p>
  </div>
  <div class="title_icon title_device device_status">
    <p
      [ngStyle]="{
        'border-color': this.isReachable() == null ? '#565556' : this.isReachable() ? '#50a17c' : 'rgb(255, 86, 86)',
        'width': this.isReachable() == null ? '110px': this.isReachable() ? '120px' : '140px',
        'margin-left': this.isReachable() == null ? 'calc(50% - 55px)' : this.isReachable() ? 'calc(50% - 60px)' : 'calc(50% - 70px)',
      }"
    >
      {{
        this.isReachable() == null
          ? "UNKNOWN"
          : this.isReachable()
          ? "REACHABLE"
          : "UNREACHABLE"
      }}
    </p>
  </div>
  <div class="fixed_table">
    <table class="list">
      <tbody>
        <tr>
          <th class="td_left">Product</th>
          <td class="td_left">
            {{ this.product() }}
          </td>
        </tr>
        <tr>
          <th class="td_left">Name</th>
          <td class="td_left">{{ this.devMan.device.alias }}</td>
        </tr>
        <tr>
          <th class="td_left">Serial Number</th>
          <td class="td_left">{{ this.sn() }}</td>
        </tr>
        <tr>
          <th class="td_left">RSSI</th>
          <td class="td_left">
            {{
              this.devMan.type == DeviceType.datalogger
                ? this.devMan.device.devInfo.rssi
                : this.devMan.getCurrentBattery().rssi
            }}
            dB
          </td>
        </tr>
        <tr>
          <th class="td_left">Firmware version</th>
          <td class="td_left">
            {{
              this.devMan.type == DeviceType.datalogger
                ? this.devMan.device.fw_ver == ""
                  ? "-"
                  : this.devMan.device.fw_ver
                : this.devMan.getCurrentBattery().fw_ver == ""
                ? "-"
                : this.devMan.getCurrentBattery().fw_ver
            }}
          </td>
        </tr>
        <tr>
          <th class="td_left">Updates</th>
          <td class="td_left">
            {{ this.checkVersion() }}
          </td>
        </tr>
        <tr class="first_tr">
          <th class="td_update">Update history</th>
        </tr>
        <tr>
          <td>
            {{
              this.devMan.upgrade_history.length == 0
                ? "This device has never been upgraded remotely"
                : "Updated to " + this.devMan.mostRecentUpgrade().version
            }}
          </td>
        </tr>
        <tr>
          <td class="td_update">
            <label
              [ngStyle]="{
                color:
                  this.devMan.upgrade_history.length == 0 ? 'white' : '#50a17c',
                opacity: this.devMan.upgrade_history.length == 0 ? 0.7 : 1,
                cursor: this.devMan.upgrade_history.length == 0 ? '' : 'pointer'
              }"
              >View update history</label
            >
          </td>
        </tr>
        <tr>
          <td class="td_update" style="padding: 0px 0px 10px 0px">
            <div>
              <app-step-indicator-view
                [numberOfSteps]="6"
                [currentStep]="
                  this.devMan.mostRecentUpgrade().error == -1
                    ? -1
                    : this.devMan.mostRecentUpgrade().step + 1
                "
                [error]="this.devMan.mostRecentUpgrade().error != 0"
                [horizontalLabels]="[]"
                [circleRadius]="this.annularStepperRadius() ? 25 : 18"
              ></app-step-indicator-view>
            </div>
          </td>
        </tr>
        <tr style="opacity: 0.7">
          <td class="td_update" style="text-align: center">
            <label *ngIf="this.devMan.mostRecentUpgrade().error == 0">
              {{
                this.devMan.upgrade_history.length == 0
                  ? "There is no information available to display"
                  : this.devMan.mostRecentUpgrade().step + 1 < 6
                  ? "Update in progress... " +
                    this.devMan.mostRecentUpgrade().action
                  : "Succesfully installed on " +
                    this.lastRemoteUpdateHistory(
                      this.devMan.mostRecentUpgrade()._time
                    )
              }}
            </label>
            <label *ngIf="this.devMan.mostRecentUpgrade().error > 0">
              Terminated with error on
              {{
                this.lastRemoteUpdateHistory(
                  this.devMan.mostRecentUpgrade()._time
                )
              }}</label
            >
          </td>
        </tr>
        <tr>
          <td class="td_update" style="text-align: center">
            <button
              class="submit"
              (click)="
                AdvancedToolPermissions.disableUpgrade(this.home.user_type)
                  ? ''
                  : this.confirmPopup('upgrade', [false, false, false, true])
              "
              [ngStyle]="{
                opacity: AdvancedToolPermissions.disableUpgrade(
                  this.home.user_type
                )
                  ? '0.3'
                  : '1'
              }"
            >
              Upgrade now
            </button>
          </td>
        </tr>
      </tbody>
      <tr class="last_row">
        <td class="switch_left switch_last_row">
          {{ this.current_device + 1 }} /
          {{ this.devMan.batteries_tot_num + 1 }}
        </td>
        <td style="text-align: center"></td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.left.svg"
            alt=""
            (click)="this.switchDevice('left')"
          />
        </td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.right.svg"
            alt=""
            (click)="this.switchDevice('right')"
          />
        </td>
      </tr>
    </table>
  </div>
</div>
<app-warning
  [title]="'Server response'"
  [background_color]="this.devMan.tools.warning.getWarningBackgroundColor()"
  [message]="this.devMan.tools.warning.getWarningMessage()"
  [warning]="this.devMan.tools.warning.getViewWarning()"
  (response)="this.devMan.tools.warning.setViewWarning($event)"
></app-warning>
