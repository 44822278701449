import dayjs from 'dayjs';
import { AppDate, TimeZone } from './app.date';

export enum intervalType {
  Hourly = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

export class BatteryHistory {
  public _time: number = 0;
  public sample: Sample = new Sample();
}

export class intervalIncrement {
  static HourIncrement: number = 60;
  static DayIncrement: number = 60 * 60;
  static WeekIncrement: number = 24 * 60 * 60;
  static MonthIncrement: number = 24 * 60 * 60;
}

export class Sample {
  _time: number = 0;
  current: number = 0;
  voltage: number = 0;
  soc: number = 0;
  temperature: number = 0;
  deltaVoltage: number = 0;
  weightCount: number = 0;
  isEmpty: boolean = false;
  timeout: boolean = false;
  readOnly: boolean = false;
}

export class Statistics {
  avg_current: number = 0.0;
  avg_voltage: number = 0.0;
  avg_soc: number = 0.0;
  avg_temperature: number = 0.0;
  avg_deltavoltage: number = 0.0;
  noData: boolean = true;
  max_current: number = 0.0;
  max_voltage: number = 0.0;
  max_soc: number = 0;
  max_temperature: number = 0.0;
  max_deltavoltage: number = 0.0;
  min_current: number = 0.0;
  min_voltage: number = 0.0;
  min_soc: number = 0;
  min_temperature: number = 0.0;
  min_deltavoltage: number = 0.0;
}

export type range = Map<Date, Sample>;
/**
 *
 * @param iType
 * @param offset
 * @param start return value
 * @param end return value
 */
export function calculateRangeLimit(
  iType: intervalType,
  offset: number
): { start: Date; end: Date } {
  var increment: number;
  var offsec: number = 0;

  //get current date
  var start = new Date();
  var end = new Date();

  switch (iType) {
    case intervalType.Hourly:
      increment = intervalIncrement.HourIncrement;
      offsec = Number(offset) * increment * 60;
      start.setUTCSeconds(offsec);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      end.setUTCSeconds(offsec);
      end.setUTCMinutes(59);
      end.setUTCSeconds(59);
      break;
    case intervalType.Daily:
      start = AppDate.startOfDay(start);
      start = addDays(start, offset);
      end = setEndOfDay(start);

      // Adjust start date for time zone offset and daylight saving time
      let timeZoneOffset = (new Date().getTimezoneOffset() / 60) * -1; // Correct timezone offset
      if (isDaylightSavingTime(start) && !isDaylightSavingTime(new Date())) {
        timeZoneOffset += 1;
      }
      if (!isDaylightSavingTime(start) && isDaylightSavingTime(new Date())) {
        timeZoneOffset -= 1;
      }
      start.setUTCHours(24 - timeZoneOffset, 0, 0, 0);

      // Adjust end date for time zone offset and daylight saving time
      timeZoneOffset = (new Date().getTimezoneOffset() / 60) * -1; // Correct timezone offset
      if (isDaylightSavingTime(end) && !isDaylightSavingTime(new Date())) {
        timeZoneOffset += 1;
      }
      if (!isDaylightSavingTime(end) && isDaylightSavingTime(new Date())) {
        timeZoneOffset -= 1;
      }

      end.setUTCHours(23 - timeZoneOffset, 59, 59, 0);

      break;
    case intervalType.Weekly:
      increment = intervalIncrement.WeekIncrement;
      offsec = Number(offset) * increment * 7;
      let day = start.getUTCDay();
      let diff = start.getUTCDate() - day + (day == 0 ? -6 : 1);
      start.setUTCDate(diff);
      start.setUTCSeconds(offsec);
      start.setUTCHours(0);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);
      end.setUTCDate(diff + 6);
      end.setUTCSeconds(offsec);
      end.setUTCHours(23);
      end.setUTCMinutes(59);
      end.setUTCSeconds(59);

      // Check if start and end dates are in DST
      const isStartInDST = AppDate.isDST(start, dayjs.tz.guess());
      const isEndInDST = AppDate.isDST(end, dayjs.tz.guess());

      // If the DST status differs, adjust the end date
      if (isStartInDST !== isEndInDST) {
        let timeAdjustment: number = isStartInDST ? -3600000 : 3600000; // Adjust for 1 hour in milliseconds
        end = new Date(end.getTime() + timeAdjustment);
      }
      break;
    case intervalType.Monthly:
      var d = new Date();
      start = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth() + offset,
        1,
        0,
        0,
        0
      );
      end = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth() + 1 + offset,
        0,
        23,
        59,
        59
      );
      break;
  }
  return { start, end };
}

// Helper function to check if a date is in daylight saving time
function isDaylightSavingTime(date: Date): boolean {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== date.getTimezoneOffset();
}

function addDays(date: Date, days: number): Date {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function setEndOfDay(date: Date): Date {
  let result = new Date(date);
  result.setHours(23, 59, 59, 0); // 23:59:59.999
  return result;
}

export class RecordMap {
  //delegate
  private m_records: range = new Map<Date, Sample>();
  private m_from: Date = new Date();
  private m_to: Date = new Date();

  constructor() {}

  public getRecords(from: Date, to: Date): range {
    var ret: range = new Map<Date, Sample>();

    for (let item of this.m_records.keys()) {
      if (item > from && item < to) {
        ret.set(item, this.m_records.get(item)!);
      }
    }
    return ret;
  }

  public getStatistics(r: range): Statistics {
    var stats: Statistics = new Statistics();
    var size: number = 0;

    if (r.size == 0) {
      return stats;
    }
    for (let key of r.keys()) {
      let s: Sample = r.get(key)!;
      if (s.isEmpty) {
        continue;
      }
      stats.avg_current += s.current * s.weightCount;
      stats.avg_voltage += s.voltage * s.weightCount;
      stats.avg_temperature += s.temperature * s.weightCount;
      stats.avg_soc += s.soc * s.weightCount;
      stats.avg_deltavoltage += s.deltaVoltage * s.weightCount;
      stats.noData = false;

      size += s.weightCount;
    }

    if (size != 0) {
      stats.avg_current /= Number(size);
      stats.avg_voltage /= Number(size);
      stats.avg_soc /= Number(size);
      stats.avg_temperature /= Number(size);
      stats.avg_deltavoltage /= Number(size);

      for (let t of r) {
        if (!t[1].isEmpty) {
          stats.max_current = t[1].current;
          stats.max_voltage = t[1].voltage;
          stats.max_soc = t[1].soc;
          stats.max_temperature = t[1].temperature;
          stats.max_deltavoltage = t[1].deltaVoltage;

          stats.min_current = t[1].current;
          stats.min_voltage = t[1].voltage;
          stats.min_soc = t[1].soc;
          stats.min_temperature = t[1].temperature;
          stats.min_deltavoltage = t[1].deltaVoltage;

          break;
        }
      }

      r.forEach((x) => {
        if (!x.isEmpty) {
          stats.max_current = Math.max(stats.max_current, x.current);
          stats.max_voltage = Math.max(stats.max_voltage, x.voltage);
          stats.max_soc = Math.max(stats.max_soc, x.soc);
          stats.max_temperature = Math.max(
            stats.max_temperature,
            x.temperature
          );
          stats.max_deltavoltage = Math.max(
            stats.max_deltavoltage,
            x.deltaVoltage
          );
          stats.min_current = Math.min(stats.min_current, x.current);
          stats.min_voltage = Math.min(stats.min_voltage, x.voltage);
          stats.min_soc = Math.min(stats.min_soc, x.soc);
          stats.min_temperature = Math.min(
            stats.min_temperature,
            x.temperature
          );
          stats.min_deltavoltage = Math.min(
            stats.min_deltavoltage,
            x.deltaVoltage
          );
        }
      });
    }

    return stats;
  }

  public emitSample(batteryHistory: BatteryHistory[]) {
    for (let bh of batteryHistory) {
      this.insert(new Date(bh._time), bh.sample);
    }
  }

  public insert(date: Date, sample: Sample) {
    if (this.m_from > date) {
      this.m_from = date;
    }

    if (this.m_to < date) {
      this.m_to = date;
    }

    this.m_records.set(date, sample);
  }

  static getIncrement(iType: intervalType): number {
    var ret: number = 0;

    switch (iType) {
      case intervalType.Hourly:
        ret = intervalIncrement.HourIncrement;
        break;
      case intervalType.Daily:
        ret = intervalIncrement.DayIncrement;
        break;
      case intervalType.Weekly:
        ret = intervalIncrement.WeekIncrement;
        break;
      case intervalType.Monthly:
        ret = intervalIncrement.MonthIncrement;
        break;
    }

    return ret;
  }

  public getPoints(
    iType: intervalType,
    offset: number = 0
  ): { ret: range; from: Date; to: Date } {
    var ret: range = new Map<Date, Sample>();
    var from: Date = new Date();
    var to: Date = new Date();
    var increment: number = 0;
    var s: Sample;
    var counter = 0;
    var checkLastHourlyPoint: boolean = false;

    increment = RecordMap.getIncrement(iType);
    let rangeLimit = calculateRangeLimit(iType, offset);
    from = rangeLimit.start;
    to = rangeLimit.end;
    var d = new Date(from);
    var end: Date | undefined = null!;
    while (d < to) {
      s = new Sample();
      for (let item of this.m_records.keys()) {
        var check: boolean = false;
        var dist = Math.abs((item.getTime() - d.getTime()) / 1000);

        switch (iType) {
          case intervalType.Hourly:
            check =
              item.getUTCMinutes() == d.getUTCMinutes() &&
              dist >= 0 &&
              dist <= increment;
            break;
          case intervalType.Daily:
            check =
              item.getUTCHours() == d.getUTCHours() &&
              dist >= 0 &&
              dist <= increment;
            break;

          case intervalType.Weekly:
            check =
              item.getDay() == d.getDay() && dist >= 0 && dist <= increment;
            break;

          case intervalType.Monthly:
            check =
              item.getDate() == d.getDate() && dist >= 0 && dist <= increment;
            break;
        }

        if (check) {
          if (end == null) {
            end = item;
          }
          s.current += this.m_records.get(item)?.current!;
          s.voltage += this.m_records.get(item)?.voltage!;
          s.temperature += this.m_records.get(item)?.temperature!;
          s.soc += this.m_records.get(item)?.soc!;
          s.timeout = s.timeout || this.m_records.get(item)?.timeout!;
          s.deltaVoltage += this.m_records.get(item)?.deltaVoltage!;
          s.weightCount += this.m_records.get(item)?.weightCount!;
          counter += 1;
        }
      }

      if (counter != 0) {
        s.current /= Number(counter);
        s.voltage /= Number(counter);
        s.temperature /= Number(counter);
        s.soc /= Number(counter);
        s.weightCount /= Number(counter);
        s.deltaVoltage /= Number(counter);

        /* case Hourly is an exception because we do not want to see current date point moving */
        if (iType == intervalType.Hourly) {
          let currentDate = new Date();
          checkLastHourlyPoint =
            currentDate.getUTCMinutes() == d.getUTCMinutes() &&
            Math.abs((currentDate.getTime() - d.getTime()) / 1000) <= increment;
        }
      }

      if (counter == 0 || checkLastHourlyPoint) {
        s.isEmpty = true;
      }
      var t = new Date(d);
      ret.set(t, s);

      counter = 0;
      checkLastHourlyPoint = false;
      // Adjust the date increment based on the interval type
      if (iType == intervalType.Monthly) {
        // Calculate the first day of the next month
        let nextMonthDate = new Date(from);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
        nextMonthDate.setDate(1); // Set to the first day of the next month

        // Increment by one day and check if we reached the next month
        d.setDate(d.getDate() + 1);
        if (d >= nextMonthDate) {
          break;
        }
      } else if (iType == intervalType.Weekly) {
        // Calculate the date for the same day next week
        let nextWeekDate = new Date(from);
        nextWeekDate.setDate(nextWeekDate.getDate() + 7);

        // Increment by one day and check if we reached the next week
        d.setDate(d.getDate() + 1);
        if (d >= nextWeekDate) {
          break;
        }
      } else {
        d.setUTCSeconds(+increment);
      }
    }
    return { ret, from, to };
  }

  public dropRecords() {
    for (let key of this.m_records.keys()) {
      if (this.m_records.get(key)?.readOnly == false) {
        this.m_records.delete(key);
      }
    }
  }
}
