<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div
  [ngStyle]="{
    opacity: this.is_disable ? 0.5 : 1
  }"
  (mouseover)="this.hover = true && ! this.is_disable"
  (mouseleave)="this.hover = false"
>
  <button
    class="{{ this.class }}"
    [ngStyle]="{
      'background-color':
        this.is_active || this.hover
          ? this.hover_background_color
          : this.background_color,
      'border-left': this.is_active ? '2px white solid' : ''
    }"
  >
    <img
      class="{{ this.class + '_icon' }}"
      src="{{ this.icon_src }}"
      [ngStyle]="{
        'background-color':
          this.is_active || this.hover
            ? this.hover_background_color
            : this.background_color
      }"
      alt=""
    />{{ this.label }}
  </button>
</div>
