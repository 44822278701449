import {
  BatteryMethods,
  DeviceMethods,
  DeviceType,
} from 'src/model/app.deviceManager';
import { userTypeString } from './advanced-user/user';

export class AdvancedToolPermissions {
  static disableSettings(
    type: DeviceType,
    userType: userTypeString,
    sn: string
  ) {
    if (type == DeviceType.datalogger) return true;
    if (BatteryMethods.is_cluster(sn)) return true;
    if (userType != userTypeString.manufacturer) return true;
    return false;
  }
  static disableConsole(
    type: DeviceType,
    userType: userTypeString,
    sn: string
  ) {
    let w = window.innerWidth;
    if (w <= 1200) return true;
    if (type != DeviceType.datalogger || !DeviceMethods.isLinuxDevice(sn))
      return true;
    if (userType != userTypeString.manufacturer) return true;
    return false;
  }
  static disableGPIO() {
    return true;
  }

  static disableUpgrade(userType: userTypeString) {
    return userType == userTypeString.enduser;
  }
  static disableReboot(userType: userTypeString) {
    return userType == userTypeString.enduser;
  }
  // Only for battery
  static disableRebootAll(userType: userTypeString) {
    return userType == userTypeString.enduser;
  }
}
