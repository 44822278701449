/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { sha256 } from 'js-sha256';
import { HomeComponent } from 'src/app/home/home.component';
import { Warning, WarningColor } from 'src/app/warning/warning.component';
import { userTypeString } from './user';
@Component({
  selector: 'app-advanced-user',
  templateUrl: './advanced-user.component.html',
  styleUrls: ['./advanced-user.component.css'],
})
export class AdvancedUserComponent {
  public input_user: boolean = false;
  public nextUser: string = '';
  public superuserpwd =
    '7fe1f17e367403c245eae51193bf3f9c83c2dca87a61c1e25c28a8437dfec779';
  public manufacturerpwd =
    '0001eaf0483335df555ed7e30fa59ea720a7637eb125fe8ba126f523573ba8d3';
  public superuser: boolean = false;
  public manufacturer: boolean = false;

  public warning: Warning = new Warning();

  @Output() close = new EventEmitter<boolean>();
  @Output() user = new EventEmitter<userTypeString>();

  private _view_option: boolean = false;
  @Input() set view_option(value: boolean) {
    this._view_option = value;
    this.input_user = false;
  }
  public get view_option() {
    return this._view_option;
  }

  public button_index: number[] = [0, 1, 2];
  public is_over: boolean[] = [false, false, false];
  public tools_title: string[] = [
    userTypeString.enduser,
    userTypeString.superuser,
    userTypeString.manufacturer,
  ];

  public disable: boolean[] = [false, false, false];

  constructor(public home: HomeComponent) {}

  public command(user: string) {
    this.nextUser = user;
    if (user == userTypeString.enduser) {
      this.user.emit(userTypeString.enduser);
      this.nextUser = '';
      this.set_user_pwd(userTypeString.enduser);
    } else {
      this.input_user = true;
    }
  }
  public userpwd() {
    let pwd =
      'archi:' +
      (document.getElementById('userpwd')! as HTMLInputElement).value;
    pwd = sha256(pwd);
    if (this.nextUser == 'Superuser') {
      if (this.superuserpwd == pwd) {
        this.superuser = true;
        this.set_user_pwd(userTypeString.superuser);
      } else {
        this.set_user_pwd(this.home.user_type);
        this.warning.setWarning(WarningColor.red, ['Incorrect password.']);
      }
    } else if (this.nextUser == 'Manufacturer') {
      if (this.manufacturerpwd == pwd) {
        this.manufacturer = true;
        this.set_user_pwd(userTypeString.manufacturer);
      } else {
        this.set_user_pwd(this.home.user_type);
        this.warning.setWarning(WarningColor.red, ['Incorrect password.']);
      }
    } else {
      this.set_user_pwd(userTypeString.enduser);
    }
  }
  private set_user_pwd(user: userTypeString) {
    this.user.emit(user);
    this.close_set_user();
  }

  public close_set_user() {
    this.close.emit(false);
    this.input_user = false;
  }
}
