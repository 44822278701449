/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppRoutingModule, Message } from '../app-routing.module';
import { DeviceListComponent } from './../device-list/device-list.component';
import { Warning, WarningColor } from '../warning/warning.component';
import { Regex } from 'src/model/Regex';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.css'],
})
export class AddDeviceComponent implements OnInit {
  public warning: Warning = new Warning();

  public last_added_sn: string = '';

  public selected_tool: number = -1;

  public button_index: number[] = [
    addDeviceOptionType.add_device,
    addDeviceOptionType.upload_log,
  ];

  public tools_imgs: string[] = [
    '../../assets/icon/device-list/device_list_menu/add_device.svg',
    '../../assets/icon/device-list/device_list_menu/log-file-icon.svg',
  ];
  public tools_imgs_over: string[] = [
    '../../assets/icon/device-list/device_list_menu/add_device_white.svg',
    '../../assets/icon/device-list/device_list_menu/log-file-icon_white.svg',
  ];
  public is_over: boolean[] = [false, false];
  public tools_title: string[] = ['Add device', 'Upload Log and Add Device'];
  public tools_label: string[] = [
    'Add Device by Specifying Serial Number',
    'Upload battery log data and add the corresponding device if not already present',
  ];

  @Output() close = new EventEmitter<boolean>();
  @Input() view: boolean = false;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public device_list: DeviceListComponent
  ) {}

  ngOnInit(): void {}

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.add_device();
    }
  }

  public async command(command: string) {
    switch (command) {
      case 'Add device':
        this.selected_tool = addDeviceOptionType.add_device;
        break;
      case 'Upload Log and Add Device':
        this.selected_tool = addDeviceOptionType.upload_log;
        this.select_log();
        break;
      default:
        this.selected_tool = -1;
    }
  }

  public exit() {
    this.command('');
    this.is_over = [false, false];
    this.close.emit(false);
  }

  public add_device() {
    this.exit();
    let sn = '';
    let alias = '';
    const obj = sessionStorage.getItem('device_shared');
    // In this way is possible use the same method to add device or add shared device
    if (obj == null) {
      const snObj = document.getElementById(
        'add_device_sn'
      )! as HTMLInputElement;
      const aliasObj = document.getElementById(
        'add_device_alias'
      )! as HTMLInputElement;
      if (snObj == null || aliasObj == null) return;
      sn = snObj.value.trim();
      alias = aliasObj.value.trim();
      if (alias == '') {
        alias = 'new_device';
      }
    } else {
      const device = JSON.parse(obj);
      sn = device.sn;
      alias = device.alias;
    }

    this.request_add_device(sn, alias);
  }

  private request_add_device(
    sn: string,
    alias: string = 'new_device',
    showMessage: boolean = true
  ) {
    this.http
      .post<Message>(this.app.server_domain + '/device.php', {
        request: 'add_device',
        token: this.app.token,
        sn: sn,
        alias: alias,
      })
      .subscribe(
        (result) => {
          if (showMessage) {
            this.warning.setViewWarning(true);
            this.warning.setWarningMessage([result.message]);
            if (result.response)
              this.warning.setWarningBackgroundColor(WarningColor.green);
            else this.warning.setWarningBackgroundColor(WarningColor.red);
          }
          if (result.response) {
            this.last_added_sn = sn;
            sessionStorage.removeItem('device_shared');
            this.device_list.get_devices_list();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public select_log() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv'; // Accept only CSV files
    input.style.display = 'none'; // Hide it

    document.body.appendChild(input);
    input.click();

    input.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target?.files?.[0];
      if (
        file &&
        file.type === 'text/csv' &&
        Regex.regex_csv_filename(file.name)
      ) {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
          const csvContent = e.target?.result as string;

          if (csvContent) {
            const rows = csvContent.split('\n');
            const firstRow = rows[0];

            const creationDate = this.extractDate(firstRow);
            if (!creationDate) {
              console.error('Date creation not found');
              return;
            }

            const creationTimestamp = creationDate.getTime();
            const statistics = [];

            const fileNameParts = file.name.split('_');
            const sn = fileNameParts[0];

            interface ColumnMapping {
              [key: string]: number;
            }

            const headerRow = rows[1].trim().split(/[\t;]+/);
            const columnMapping: ColumnMapping = {};
            headerRow.forEach((header, index) => {
              columnMapping[header.trim()] = index;
            });

            for (let i = 2; i < rows.length; i++) {
              const dataRow = rows[i].trim();
              if (!dataRow) continue;

              const columns = dataRow.split(/[\t;]+/);
              if (columns.length !== headerRow.length) {
                console.error(
                  `Row ${i + 1} has an inconsistent number of columns:`,
                  columns
                );
                continue;
              }

              const time = parseInt(columns[columnMapping['time']], 10);
              const fields: { time: number | undefined; [key: string]: any } = {
                time: isNaN(time) ? undefined : time,
              };

              // Add additional fields based on column mapping
              this.addField(columns, columnMapping, fields, 'sw');
              this.addField(columns, columnMapping, fields, 'sw2');
              this.addField(columns, columnMapping, fields, 'current', true);
              this.addField(columns, columnMapping, fields, 'soc');
              this.addField(columns, columnMapping, fields, 'cycles');

              if (isNaN(time)) {
                console.error(
                  `Row ${i + 1} contains invalid time value:`,
                  columns
                );
                continue;
              }

              const voltages = this.extractDynamicData(
                columns,
                columnMapping,
                'v['
              );
              const temperatures = this.extractDynamicData(
                columns,
                columnMapping,
                'temp['
              );

              const v_min = voltages.length
                ? Number(Math.min(...voltages).toFixed(3))
                : null;
              const v_max = voltages.length
                ? Number(Math.max(...voltages).toFixed(3))
                : null;
              const voltage = Number(
                voltages.reduce((sum, v) => sum + v, 0).toFixed(2)
              );

              const t_min = temperatures.length
                ? Math.min(...temperatures)
                : null;
              const t_max = temperatures.length
                ? Math.max(...temperatures)
                : null;

              const rowTimestamp = creationTimestamp + time * 1000;

              const rowData = {
                time: rowTimestamp,
                fields: {
                  ...fields,
                  v_min,
                  v_max,
                  voltage,
                  t_min,
                  t_max,
                },
              };
              statistics.push(rowData);
            }

            const creationLogTimestamp = (creationTimestamp / 1000).toString();
            this.uploadLog(statistics, creationLogTimestamp, sn);
          }
        };

        reader.readAsText(file);
      } else {
        this.warning.setWarning(WarningColor.red, [
          'Please select a valid CSV file.',
        ]);
      }

      document.body.removeChild(input);
    });
    this.exit();
  }

  // Method to extract date with flexibility for multiple formats
  private extractDate(dateString: string): Date | null {
    // Check for the standard format (DD/MM/YYYY HH:mm)
    const standardDateMatch = dateString.match(
      /date creation[: ]*(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2})/
    );
    if (standardDateMatch) {
      return this.parseStandardDate(standardDateMatch[1]);
    }

    // Check for UTC format (ISO 8601)
    const utcDateMatch = dateString.match(
      /date creation[: ]*(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|\+\d{2}:\d{2})?)/
    );
    if (utcDateMatch) {
      return new Date(utcDateMatch[1]); // Directly parse ISO format
    }

    // Additional formats can be added here as needed
    return null; // Return null if no valid date is found
  }

  // Parse the standard date format (DD/MM/YYYY HH:mm)
  private parseStandardDate(dateString: string): Date {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hour, minute] = timePart.split(':');

    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(minute)
    );
  }

  // Add field based on column mapping
  private addField(
    columns: string[],
    columnMapping: { [key: string]: number },
    fields: any,
    fieldName: string,
    isFloat: boolean = false
  ) {
    if (columnMapping.hasOwnProperty(fieldName)) {
      const value = isFloat
        ? parseFloat(columns[columnMapping[fieldName]].replace(',', '.'))
        : parseInt(columns[columnMapping[fieldName]]);
      if (!isNaN(value)) {
        fields[fieldName] = value; // Only add if valid
      }
    }
  }

  // Extract dynamic data based on column prefix
  private extractDynamicData(
    columns: string[],
    columnMapping: { [key: string]: number },
    prefix: string
  ): number[] {
    return Object.keys(columnMapping)
      .filter((key) => key.startsWith(prefix))
      .map((key) => parseFloat(columns[columnMapping[key]].replace(',', '.')))
      .filter((value) => !isNaN(value)); // Filter out NaN values
  }

  // Method to send JSON data to the PHP backend
  private uploadLog(
    jsonData: any[],
    creationLogTimestamp: string,
    sn: string
  ): void {
    const chunkSize = 2000; // Maximum number of rows per request
    const totalChunks = Math.ceil(jsonData.length / chunkSize); // Total number of chunks
    let currentChunk = 0; // Track the current chunk being processed
    let failed = false; // Flag to track if any chunk failed

    const processChunk = (chunk: any[]): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (failed) {
          // If already failed, skip further requests
          resolve(false);
          return;
        }

        this.http
          .post<Message>(this.app.server_domain + '/device.php', {
            request: 'log_upload',
            token: this.app.token,
            data: chunk, // Send the chunk of data
            creationTimestamp: creationLogTimestamp, //The timestamp of log creation
            sn: sn,
          })
          .subscribe(
            (response) => {
              currentChunk++; // Increment the chunk count
              if (response.response === false) {
                // If the response is false, mark as failed
                failed = true;
                this.warning.setWarning(WarningColor.red, [
                  'Error uploading CSV data',
                ]);
                resolve(false); // Stop further requests
              } else if (currentChunk === totalChunks) {
                // If it's the last chunk and no failure occurred
                this.warning.setWarning(WarningColor.green, [
                  'CSV data uploaded successfully!',
                ]);
                const tmp = response.message.split(' ');
                if (tmp.length == 2)
                  this.request_add_device(tmp[0], tmp[1], false);
                resolve(true); // Successful completion
              } else {
                resolve(true); // Process next chunk if not the last one
              }
            },
            (error) => {
              console.error('HTTP Error:', error);
              failed = true; // Mark as failed if there's a network error
              this.warning.setWarning(WarningColor.red, [
                'Error uploading CSV data',
              ]);
              resolve(false); // Stop further requests
            }
          );
      });
    };

    // Split the data into chunks and process them sequentially
    const chunks = Array.from({ length: totalChunks }, (_, i) =>
      jsonData.slice(i * chunkSize, (i + 1) * chunkSize)
    );

    // Sequentially send requests for each chunk
    chunks.reduce((promise, chunk) => {
      return promise.then((result) => {
        if (failed) {
          // If a failure occurred, stop processing further
          return Promise.resolve(false);
        }
        return processChunk(chunk);
      });
    }, Promise.resolve());
  }
}

enum addDeviceOptionType {
  add_device = 0,
  upload_log = 1,
}
