/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-menu-button',
  templateUrl: './home-menu-button.component.html',
  styleUrls: ['./home-menu-button.component.css'],
})
export class HomeMenuButtonComponent {
  @Input() class: string = '';

  @Input() background_color: string = '';

  @Input() hover_background_color: string = '';

  @Input() label: string = '';

  @Input() icon_src: string = '';

  @Input() is_active: boolean = false;

  @Input() is_disable: boolean = false;

  public hover: boolean = false;

  constructor() {}
}
