import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import timezone from 'dayjs/plugin/timezone'; // Import timezone plugin

// Extend dayjs with UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export class AppDate {
  static format_day2_monthshort_yearnum_hour2_minute2_second2: string =
    'DD MMM YYYY, HH:mm:ss';
  static format_day2_monthshort_yearnum_hour2_minute2: string =
    'DD MMM YYYY, HH:mm';
  static format_day2_monthshort_yearnum: string = 'DD MMM YYYY';
  static format_monthshort_yearnum: string = 'MMM YYYY';
  static format_day2_monthshort_hour2_minute2: string = 'DD MMM, HH:mm';
  static format_day2_monthshort: string = 'DD MMM';
  static format_hour2_minute2: string = 'HH:mm';
  static formatHour: string = 'HH';
  static formatWeek: string = 'ddd';
  static formatDay: string = 'DD';
  constructor() {}

  static stringFromDateFromYearToMilliseconds(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(6, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  static startOfHour(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours()
    );
  }

  static startOfDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static startOfWeek(date: Date) {
    const day = date.getDay(); // Get the day of the week (0-6)
    const diff = (day === 0 ? -6 : 1) - day; // Calculate the difference to the previous Monday
    return new Date(date.setDate(date.getDate() + diff));
  }

  static startOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  /**
   *
   * @param month 0-11
   * @param year
   * @returns number of days in the specific month
   */
  static daysInMonth(month: number, year: number) {
    switch (month) {
      case 3:
      case 5:
      case 8:
      case 10:
        return 30;
      case 1:
        if (year % 4 == 0) {
          return 29;
        }
        return 28;
      default:
        return 31;
    }
  }

  static isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  static isSameMonth(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    );
  }

  static is_today(date: Date) {
    let format_date = AppDate.stringFromDate(
      date,
      AppDate.format_day2_monthshort_yearnum
    );
    let today_date = AppDate.stringFromDate(
      new Date(),
      AppDate.format_day2_monthshort_yearnum
    );
    if (format_date == today_date) {
      return true;
    }
    return false;
  }

  static stringFromDate(date: Date, format: string) {
    return dayjs(date).format(format);
  }
  /**
   * Sets the timezone for the given date.
   * @param date - The date to be adjusted.
   * @param timeZone - The desired timezone from the TimeZone enum.
   * @returns The date adjusted to the specified timezone.
   */
  static setTimeZone(date: Date, timeZone: TimeZone): Date {
    // Convert the native Date object to a dayjs object
    const dayjsDate = dayjs(date);

    // Adjust the date to the specified timezone
    const adjustedDate = dayjsDate.tz(timeZone);

    return adjustedDate.toDate(); // Return the date adjusted to the timezone
  }
  // Function to check if a date is in Daylight Saving Time (DST)
  static isDST = (date: Date, timeZone: string): boolean => {
    const standardOffset = dayjs(date)
      .tz(timeZone, true)
      .startOf('year')
      .utcOffset();
    const currentOffset = dayjs(date).tz(timeZone, true).utcOffset();
    return currentOffset !== standardOffset;
  };
}

// Define an enumeration for time zones
export enum TimeZone {
  UTC = 'UTC',
  EST = 'America/New_York',
  PST = 'America/Los_Angeles',
  CST = 'America/Chicago',
  GMT = 'Europe/London',
  CET = 'Europe/Berlin',
  IST = 'Asia/Kolkata',
  JST = 'Asia/Tokyo',
  AEST = 'Australia/Sydney',
  CURRENT = '',
}
