/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';

import { Command } from '../dashboard-menu/command';
import { SwitchType } from 'src/model/app.switchType';
import { Battery, DeviceManager } from 'src/model/app.deviceManager';

@Component({
  selector: 'app-battery-list',
  templateUrl: './battery-list.component.html',
  styleUrls: ['./battery-list.component.css'],
})
export class BatteryListComponent implements OnInit, OnDestroy {
  //0 battery_general 1 battery_history 2 battery_charts 3 battery_info 4 general_events
  public types = new SwitchType(5, 0);

  public detail_string_option: string[] = [
    'info',
    'general_events',
    'chart',
    'history',
    'general',
  ];
  public detail_options: boolean[] = [false, false, false, false, false];
  public detail_is_active: boolean[] = [false, false, false, false, true];
  public detail_icons: string[] = [
    '../../assets/icon/battery-details/info.svg',
    '../../assets/icon/battery-details/log.svg',
    '../../assets/icon/battery-details/chart.png',
    '../../assets/icon/battery-details/history.svg',
    '../../assets/icon/battery-details/minus.plus.batteryblock.fill.svg',
  ];

  public icons_width: string[] = ['15px', '43px', '43px', '43px', '43px'];
  public icons_height: string[] = ['35px', '35px', '35px', '35px', '35px'];
  public icons_margin: string[] = [
    '0px 0px 0px 0px',
    '0px 3px 0px 0px',
    '0px 3px 0px 0px',
    '0px 3px 0px 0px',
    '0px 3px 0px 0px',
  ];
  public icons_padding: string[] = [
    '0px 14px 0px 14px',
    '0px 0px 0px 0px',
    '0px 0px 0px 0px',
    '0px 0px 0px 0px',
    '0px 0px 0px 0px',
  ];

  public interval_dashboard: any;

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager
  ) {}

  ngOnInit(): void {
    this.devMan.battery_num = 0;
    this.devMan.get_battery_details(
      this.devMan.device.sn,
      this.devMan.battery_num
    );
    this.interval_dashboard = setInterval(() => {
      this.devMan.get_battery_details(
        this.devMan.device.sn,
        this.devMan.battery_num
      );
    }, 30 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_dashboard);
  }

  /**
   * check if battery is offline
   * @param device
   * @returns true if device is offline
   */
  public check_battery_offline(device: Battery) {
    return device.count == '0';
  }

  public battery_online() {
    let tmp = Number(this.devMan.getCurrentBattery().last_update) * 1000;
    let now = new Date().getTime();
    return Math.floor((now - tmp) / 60000) < 30;
  }

  public type(
    command: Command = new Command('default', this.detail_is_active)
  ) {
    switch (command.command) {
      case 'general_events':
        this.types.change_types(4);
        break;
      case 'history':
        this.types.change_types(1);
        break;
      case 'general':
        this.types.change_types(0);
        break;
      case 'chart':
        this.types.change_types(2);
        break;
      case 'info':
        this.types.change_types(3);
        break;
      default:
        this.types.change_types(0);
    }
    this.devMan.get_battery_details(
      this.devMan.device.sn,
      this.devMan.battery_num
    );
  }
}
