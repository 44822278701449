/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

export class MQTTReceiver {
  private requestIDs: Map<string, { date: Date; index: number }> = new Map();
  private expirationTime: number = 30;

  private m_timer: any;

  constructor() {}

  public start() {
    this.m_timer = setInterval(() => {
      this.executeCheckExpired();
    }, 1000);
  }

  public reset() {
    clearInterval(this.m_timer);
    this.requestIDs.clear();
  }

  public processMQTTRemoteCommand(data: any) {}
  public processMQTTNotification(data: any) {}
  public targetNotReachable(cmdid: string, requestIndex: number) {}

  public emitDidReceived(message: any) {
    let msgString: string = '';

    msgString = message.payloadString;
    let payload = JSON.parse(msgString);

    let requestID = null;
    if (payload.requestID !== undefined) requestID = payload.requestID;

    if (requestID === null) {
      this.processMQTTNotification(payload);
      return;
    }

    if (this.requestIDs.has(requestID)) {
      this.requestIDs.delete(requestID);
      this.processMQTTRemoteCommand(payload);
    }
  }

  public addRequestID(requestID: string, index: number) {
    this.requestIDs.set(requestID, { date: new Date(), index: index });
  }

  /**
   * Check if text is part of key (ex. info345f20, search 'info')
   * @param text The string to check
   * @returns True if text is part of one key, false otherwise
   */
  public hasKeyWithText(text: string) {
    for (const key of this.requestIDs.keys()) {
      if (key.includes(text)) {
        return true;
      }
    }
    return false;
  }

  private executeCheckExpired() {
    const now = new Date();
    const expiredRequestIDs = [];
    for (const [requestID, requestValue] of this.requestIDs.entries()) {
      if (
        (now.getTime() - new Date(requestValue.date).getTime()) / 1000 >
        this.expirationTime
      ) {
        let cmdid = requestID.split(':')[0];
        this.targetNotReachable(cmdid, requestValue.index);
        expiredRequestIDs.push(requestID);
      }
    }

    for (const expiredID of expiredRequestIDs) {
      this.requestIDs.delete(expiredID);
    }
  }
}
