<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div id="body" *ngIf="app.pages.types[1]">
  <div class="logo">
    <img src="../../assets/logo-archimede-b.png" alt="" />
  </div>

  <div class="central_block" (window:keypress)="keyDownFunction($event)">
    <div>
      <p class="title">Register for free</p>
    </div>
    <div class="form">
      <div *ngIf="!login">
        <!-- Name block -->
        <div class="profile_div">
          <div>
            <p class="label">First and second name</p>
            <p class="required">*</p>
          </div>
          <input
            placeholder="Mario Rossi"
            id="name"
            type="text"
            class="input"
            (input)="
              data_registration.parameter_required(0);
              data_registration.warning.setViewWarning(false)
            "
          />
          <p class="message" *ngIf="data_registration.data_required_check[0]">
            This field is required
          </p>
        </div>
        <div class="profile_div">
          <div>
            <p class="label">Username</p>
            <p class="required">*</p>
          </div>
          <input
            placeholder="mario.rossi"
            id="username"
            type="text"
            class="input"
            (input)="
              data_registration.parameter_required(1);
              data_registration.warning.setViewWarning(false);
              data_registration.unique_username = false
            "
            (change)="
              data_registration.check_unique_contact('check_username', 1, '')
            "
          />
          <p class="message" *ngIf="data_registration.data_required_check[1]">
            This field is required
          </p>
          <p
            class="message"
            *ngIf="
              data_registration.unique_username &&
              !data_registration.data_required_check[1]
            "
          >
            There is another user with this username.
          </p>
        </div>
        <!-- Name block -->
        <!-- Contact block -->
        <div class="profile_div">
          <div>
            <p class="label">Email address</p>
            <p class="required">*</p>
          </div>
          <input
            id="email"
            type="text"
            class="input"
            (input)="
              data_registration.parameter_required(2);
              data_registration.warning.setViewWarning(false);
              data_registration.unique_email = false
            "
            (change)="
              data_registration.check_unique_contact('check_email', 2, '')
            "
          />
          <p class="message" *ngIf="data_registration.data_required_check[2]">
            This field is required
          </p>
          <p
            class="message"
            *ngIf="
              data_registration.unique_email &&
              !data_registration.data_required_check[2]
            "
          >
            There is another user with this email.
          </p>
        </div>
        <div class="profile_div">
          <div>
            <p class="label">Phone number</p>
            <p class="required">*</p>
          </div>
          <input
            id="phone"
            type="text"
            class="input"
            (input)="
              data_registration.parameter_required(3);
              data_registration.warning.setViewWarning(false)
            "
          />
          <p class="message" *ngIf="data_registration.data_required_check[3]">
            This field is required
          </p>
        </div>
        <!-- Contact block -->
        <!-- Company block -->
        <div class="profile_div" style="float: none">
          <div>
            <p class="label">Company</p>
          </div>
          <input id="company" type="text" class="input" />
        </div>
        <!-- Company block -->
        <!-- Place block -->
        <div class="profile_div">
          <div>
            <p class="label">City</p>
            <p class="required">*</p>
          </div>
          <input
            id="city"
            type="text"
            class="input"
            (input)="
              data_registration.parameter_required(4);
              data_registration.warning.setViewWarning(false)
            "
          />
          <p class="message" *ngIf="data_registration.data_required_check[4]">
            This field is required
          </p>
        </div>
        <div class="profile_div">
          <div>
            <p class="label">Country</p>
            <p class="required">*</p>
          </div>
          <select id="country" name="country" class="input">
            <option
              *ngFor="let country of data_registration.country"
              (click)="
                data_registration.parameter_required(5);
                data_registration.warning.setViewWarning(false)
              "
            >
              {{ country.name }}
            </option>
          </select>
          <p class="message" *ngIf="data_registration.data_required_check[5]">
            This field is required
          </p>
        </div>
        <!-- Place block -->
        <!-- Password block -->
        <div class="profile_div">
          <div>
            <p class="label">Password</p>
            <p class="required">*</p>
          </div>
          <input
            id="password"
            type="password"
            class="input"
            (input)="
              data_registration.parameter_required(6);
              data_registration.password_confirmation(
                data_registration.data_required[6],
                data_registration.data_required[7]
              );
              data_registration.warning.setViewWarning(false)
            "
          />
          <p class="message" *ngIf="data_registration.data_required_check[6]">
            This field is required
          </p>
        </div>
        <div class="profile_div">
          <div>
            <p class="label">Password confirmation</p>
            <p class="required">*</p>
          </div>
          <input
            id="password_c"
            type="password"
            class="input"
            (input)="
              data_registration.parameter_required(7);
              data_registration.password_confirmation(
                data_registration.data_required[6],
                data_registration.data_required[7]
              );
              data_registration.warning.setViewWarning(false)
            "
          />
          <p
            class="message"
            *ngIf="
              data_registration.data_required_check[7] &&
              !data_registration.password_confirmation_bool
            "
          >
            This field is required
          </p>
          <p
            class="message"
            *ngIf="data_registration.password_confirmation_bool"
          >
            Password doesn't match
          </p>
        </div>
        <div>
          <div class="checkbox">
            <input type="checkbox" name="" id="checkbox_privacy_policy" />
          </div>
          <div class="checkbox_text">
            <a
              href="https://www.archimede-energia.com/privacy-policy/"
              target="_blank"
              >Yes, I would like to register on AE-PORTAL and consent to the
              processing of personal data, pursuant to and by effect of EU
              Regulation 2016/679 (GDPR).*</a
            >
          </div>
        </div>

        <div>
          <button class="submit" (click)="onSubmit()" [disabled]="!register">
            Register
          </button>
        </div>
      </div>
      <div class="form" *ngIf="login">
        <div>
          <p class="end_label">Check your email to complete registration.</p>
        </div>
        <div>
          <button class="submit" (click)="app.goToLogin()">Go to login</button>
        </div>
      </div>
    </div>
  </div>
  <app-warning
    [title]="'Server response'"
    [background_color]="data_registration.warning.getWarningBackgroundColor()"
    [message]="data_registration.warning.getWarningMessage()"
    [warning]="data_registration.warning.getViewWarning()"
    (response)="data_registration.warning.setViewWarning($event)"
  ></app-warning>
</div>
