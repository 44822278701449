/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css'],
})
export class WarningComponent implements OnInit, OnDestroy {
  @Input() warning: boolean = false;
  @Input() message: string[] = [];
  @Input() title: string = '';
  @Input() background_color: string = '';

  @Output() response = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}

export enum WarningColor {
  red = 'rgba(255, 86, 86, 0.8)',
  green = 'rgba(80, 161, 124, 0.8)',
}

export class Warning {
  // Private properties
  private view_warning: boolean = false;
  private warning_background_color: string = '';
  private warning_message: string[] = [];

  private warningResetTimeout: any;

  // Getter for view_warning
  public getViewWarning(): boolean {
    return this.view_warning;
  }

  // Setter for view_warning with scheduler to reset after 3 seconds
  public setViewWarning(value: boolean): void {
    this.view_warning = value;

    // If warning is set to true, start a timer to reset after 3 seconds
    if (value) {
      this.startWarningResetTimer();
    } else {
      // Clear the timeout if the warning is manually set to false before the timer expires
      if (this.warningResetTimeout) {
        clearTimeout(this.warningResetTimeout);
      }
    }
  }

  // Getter for warning_background_color
  public getWarningBackgroundColor(): string {
    return this.warning_background_color;
  }

  // Setter for warning_background_color
  public setWarningBackgroundColor(color: WarningColor): void {
    this.warning_background_color = color;
  }

  // Getter for warning_message
  public getWarningMessage(): string[] {
    return this.warning_message;
  }

  // Setter for warning_message
  public setWarningMessage(message: string[]): void {
    this.warning_message = message;
  }

  // Starts a 5-second timer to reset the warning
  private startWarningResetTimer(): void {
    this.warningResetTimeout = setTimeout(() => {
      this.resetWarning();
    }, 5000);
  }

  // Method to set all 3 parameters at once, calling individual setters
  public setWarning(background_color: WarningColor, message: string[]): void {
    this.setViewWarning(true);
    this.setWarningBackgroundColor(background_color);
    this.setWarningMessage(message);
  }
  // Resets the warning properties
  public resetWarning(): void {
    this.view_warning = false;
    this.warning_background_color = '';
    this.warning_message =[];
  }
}

