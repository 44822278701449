<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<div class="central_block" (window:keypress)="this.keyDownFunction($event)">
  <div class="form">
    <table>
      <tr>
        <th>
          <input
            type="number"
            name=""
            id="set_hours"
            step="1"
            min="{{ this.checkMinHours() ? this.min!.getHours() : 0 }}"
            max="{{ this.checkMaxHours() ? this.max!.getHours() : 24 }}"
            (input)="this.setTime()"
          />
        </th>
        <th
          style="
            width: 31px;
            min-width: 31px;
            max-width: 31px;
            text-align: center;
          "
        >
          :
        </th>
        <th>
          <input
            type="number"
            name=""
            id="set_minutes"
            step="1"
            min="{{
              this.checkMinHours() && this._hours == this.min!.getHours()
                ? this.min!.getMinutes()
                : 0
            }}"
            max="{{
              this.checkMaxHours() && this._hours == this.max!.getHours()
                ? this.max!.getMinutes()
                : 60
            }}"
            (input)="this.setTime()"
          />
        </th>
      </tr>
    </table>
    <div>
      <button class="submit delete" (click)="this.close.emit(false)">
        CANCEL
      </button>
      <button class="submit" (click)="this.onSubmit()">OK</button>
    </div>
  </div>
</div>
