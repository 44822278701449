/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { Data_registration } from 'src/app/public_component/registration/data_registration';
import { WarningColor } from 'src/app/warning/warning.component';
import { Regex } from 'src/model/Regex';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
})
export class PasswordComponent implements OnInit {
  @Input() id: string = '-1';
  /**
   * If update_token is true, the token is updated with current parameters, else is not necessary update token
   * (For example when administraator update the password of another user)
   * Is possible simplify:
   *  - When update_token is true, the user change his password in a current session (the user know his old password)
   *  - When update_token is false, the administrator change the password of another user (the admin don't know user's old password)
   */
  @Input() update_token: boolean = false;

  /**
   * required: 0 current_password 1 new_password 2 repeat_new_password
   */
  /**
   * unrequired: ''
   */

  public data_registration = new Data_registration(
    ['old_password', 'new_password', 'password_c'],
    [],
    this.http,
    this.app
  );
  public index = { old_password: 0, new_password: 1, password_c: 2 };

  constructor(public app: AppRoutingModule, public http: HttpClient) {}

  ngOnInit(): void {
    if (!this.update_token) {
      this.data_registration = new Data_registration(
        ['new_password', 'password_c'],
        [],
        this.http,
        this.app
      );
      this.index = { old_password: -1, new_password: 0, password_c: 1 };
    }
    console.log(this.index);
  }

  private get_message(): string[] {
    let message: string[] = [];

    message.push(`• Password lenght is min 8, max 20.
                    You can use letters, numbers and this special characters $ @ ! % * # ? & 
                    Password must contain at least 1 special characters, 1 numbers, 1 lowercase letter and 1 uppercase letter.`);

    return message;
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    console.log('update', this.update_token);
    let params = this.update_token
      ? {
          request: 'change_password',
          token: this.app.token,
          id: this.id,
          update_token: 1,
          old_password:
            this.data_registration.data_required[this.index.old_password],
          new_password:
            this.data_registration.data_required[this.index.new_password],
        }
      : {
          request: 'change_password',
          token: this.app.token,
          id: this.id,
          update_token: 0,
          new_password:
            this.data_registration.data_required[this.index.new_password],
        };
    this.data_registration.warning.setViewWarning(false);
    if (
      !this.data_registration.required() &&
      !this.data_registration.password_confirmation_bool &&
      Regex.regex_pwd(
        this.data_registration.data_required[this.index.new_password]
      )
    ) {
      this.http
        .post<any>(this.app.server_domain + '/user_setting.php', params)
        .subscribe(
          (result) => {
            this.data_registration.warning.setWarningBackgroundColor(
              WarningColor.red
            );
            if (!this.update_token) {
              let message: string[] = [];

              if (result[0]) {
                message.push(`Password updated`);
                this.data_registration.warning.setWarningBackgroundColor(
                  WarningColor.green
                );
              } else {
                message.push(`Error during update password`);
              }
              this.data_registration.warning.setWarningMessage(message);

              this.data_registration.warning.setViewWarning(true);

              return;
            }

            if (result.length > 1) {
              this.app.token = result[1];
              sessionStorage.setItem('token', JSON.stringify(result[1]));
              sessionStorage.setItem(
                'password',
                this.data_registration.data_required[this.index.new_password]
              );
              if (this.app.remember_me_check) {
                localStorage.setItem(
                  'password',
                  this.data_registration.data_required[this.index.new_password]
                );
              }
            }
            if (!result[0].response) {
              this.data_registration.warning.setWarningMessage([result[0].message]);
            } else {
              this.app.logout();
            }
            this.data_registration.warning.setViewWarning(true);
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.data_registration.warning.setWarningBackgroundColor(
        WarningColor.red
      );
      this.data_registration.warning.setWarningMessage(this.get_message());
      this.data_registration.warning.setViewWarning(true);
    }
  }
}
