<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div id="body">
  <div class="central_block">
    <div>
      <p class="title">Profile</p>
    </div>
    <div class="form" (window:keypress)="this.keyDownFunction($event)">
      <!-- Name block -->
      <div class="profile_div">
        <div>
          <p class="label" style="opacity: 0.7">Username</p>
          <p class="required" style="opacity: 0.7">*</p>
        </div>
        <input
          style="opacity: 0.7; border: 2px solid #50a17c"
          disabled
          id="username"
          type="text"
          class="input"
        />
      </div>
      <div class="profile_div">
        <div>
          <p class="label">Name</p>
          <p class="required">*</p>
        </div>
        <input
          id="name"
          type="text"
          class="input"
          (input)="
            this.data_registration.parameter_required(0);
            this.data_registration.warning.setViewWarning(false)
          "
        />
        <p
          class="message"
          *ngIf="this.data_registration.data_required_check[0]"
        >
          This field is required
        </p>
      </div>
      <!-- Name block -->
      <!-- Contact block -->
      <div class="profile_div">
        <div>
          <p class="label" style="opacity: 0.7">Email address</p>
          <p class="required" style="opacity: 0.7">*</p>
        </div>
        <input
          style="opacity: 0.7; border: 2px solid #50a17c"
          disabled
          id="email"
          type="text"
          class="input"
        />
      </div>
      <div class="profile_div">
        <div>
          <p class="label">Phone number</p>
          <p class="required">*</p>
        </div>
        <input
          id="phone"
          type="text"
          class="input"
          (input)="
            this.data_registration.parameter_required(3);
            this.data_registration.warning.setViewWarning(false)
          "
        />
        <p
          class="message"
          *ngIf="this.data_registration.data_required_check[3]"
        >
          This field is required
        </p>
      </div>

      <!-- Contact block -->
      <!-- Company block -->
      <div class="profile_div" style="float: none">
        <div><p class="label">Company</p></div>
        <input id="company" type="text" class="input" />
      </div>
      <!-- Company block -->

      <!-- Place block -->
      <div class="profile_div">
        <div>
          <p class="label">City</p>
          <p class="required">*</p>
        </div>
        <input
          id="city"
          type="text"
          class="input"
          (input)="
            this.data_registration.parameter_required(4);
            this.data_registration.warning.setViewWarning(false)
          "
        />
        <p
          class="message"
          *ngIf="this.data_registration.data_required_check[4]"
        >
          This field is required
        </p>
      </div>
      <div class="profile_div">
        <div>
          <p class="label">Country</p>
          <p class="required">*</p>
        </div>
        <select id="country" name="country" class="input">
          <option
            *ngFor="let country of this.data_registration.country"
            (click)="
              this.data_registration.parameter_required(5);
              this.data_registration.warning.setViewWarning(false)
            "
          >
            {{ country.name }}
          </option>
        </select>
        <p
          class="message"
          *ngIf="this.data_registration.data_required_check[5]"
        >
          This field is required
        </p>
      </div>

      <!-- Place block -->

      <!-- Usertype block -->
      <div class="profile_div" style="float: none">
        <div><p class="label">Default user type</p></div>
        <input
          id="usertype"
          type="button"
          class="input"
          (click)="this.view_user_setup = true"
        />
      </div>
      <!-- Usertype block -->

      <div>
        <button class="submit" (click)="this.onSubmit()">Save</button>
      </div>
      <div>
        <div>
          <p class="warning_text" style="font-size: 21px">
            Would you like to permanently delete your account?
          </p>
          <p class="warning_text">
            Warning: This is an irreversible action and all the data associated
            with your account will be lost.
          </p>
        </div>
        <button
          class="submit delete"
          *ngIf="!this.is_sure"
          (click)="this.is_sure = !this.is_sure"
        >
          Delete account
        </button>
      </div>
      <div>
        <button
          class="submit delete"
          *ngIf="this.is_sure"
          (click)="this.delete()"
        >
          I'm sure
        </button>
        <button
          class="submit"
          style="margin-top: 18px"
          *ngIf="this.is_sure"
          (click)="this.is_sure = !this.is_sure"
        >
          I'm not sure
        </button>
      </div>
    </div>
  </div>
  <app-warning
    [title]="'Server response'"
    [background_color]="data_registration.warning.getWarningBackgroundColor()"
    [message]="data_registration.warning.getWarningMessage()"
    [warning]="data_registration.warning.getViewWarning()"
    (response)="data_registration.warning.setViewWarning($event)"
  ></app-warning>
  <app-advanced-user
    class="advanced_user"
    [view_option]="this.view_user_setup"
    (user)="this.set_user($event)"
    (close)="this.view_user_setup = $event"
  ></app-advanced-user>
</div>
