/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { MQTT, MqttHandlerMsg, TopicType } from 'src/model/app.mqtt';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClient } from '@angular/common/http';
import {
  DeviceManager,
  BatteryMethods,
  DeviceMethods,
  UpgradeHistoryItem,
  DeviceType,
} from './app.deviceManager';
import { MQTTReceiver } from './app.mqttReceiver';
import { Firmware } from 'src/app/advanced/advanced-general/firmware';
import { AppDate } from './app.date';
import { Warning, WarningColor } from 'src/app/warning/warning.component';

export class Tools {
  // MQTT warning
  public warning: Warning = new Warning();

  // Callback function to implement
  public onReadVariable(str: string) {}
  public onWriteVariable() {}
  public onListVariables(str: string, encodedDescriptionList: string | null) {}
  public onReb() {}

  public onTimeoutListReadVariables() {}

  // Flag to set if user wait resp
  public wait_sync: boolean = false;

  private mqtt: MQTT = new MQTT();
  public mqttReciver: MQTTReceiver = new MQTTReceiver();

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devMan: DeviceManager
  ) {
    this.mqtt.onMessageArrived = (message) => {
      this.mqttReciver.emitDidReceived(message);
    };
    this.mqttReciver.processMQTTRemoteCommand = (data) => {
      this.processMQTTRemoteCommand(data);
    };
    this.mqttReciver.processMQTTNotification = (data) => {
      this.notify(data);
    };
    this.mqttReciver.targetNotReachable = (cmdid: string, index: number) => {
      this.targetNotreachable(cmdid, index);
    };
  }
  public connect() {
    this.mqtt.startConnection();
    this.mqttReciver.start();
  }
  public subscribe() {
    this.mqtt.pushSubscribe(this.createTopic(TopicType.subscribe), 0);
  }

  public disconnect() {
    this.mqtt.disconnect();
    this.mqttReciver.reset();
  }

  private async notify(data: any) {
    let cmdid = data.cmdid;
    let parameters = data.parameters;
    this.warning.setViewWarning(false);
    switch (cmdid) {
      case 'update.firmware.nty':
        let newUpgrade = new UpgradeHistoryItem(
          Number(parameters._time) / 1000000,
          parameters.action,
          parameters.error,
          parameters.additional_info,
          parameters.sn,
          parameters.step,
          parameters.version
        );
        this.devMan.upgrade_history.unshift(newUpgrade);
        break;
    }
  }

  private async processMQTTRemoteCommand(data: any) {
    let cmdid = data.cmdid;
    let parameters = data.parameters;
    let index = parameters.index;
    console.log(cmdid);
    switch (cmdid) {
      case 'info.rsp':
        if (parameters.status === 'Rejected') return;
        let rssi = parameters.rssi;
        let fw_ver = parameters.fw_ver;
        let online = true;
        if (fw_ver == '') online = false;
        this.devMan.updateValueForProperty('isOnline', online, index);

        this.devMan.updateValueForProperty('rssi', rssi, index);
        this.devMan.updateValueForProperty('fw_ver', fw_ver, index);
        break;
      case 'read.variables.rsp':
        this.onReadVariable(parameters.value);
        break;
      case 'write.variables.rsp':
        this.onWriteVariable();
        break;
      case 'list.variables.rsp':
        let decodedList = this.decodeHTML(parameters.value);
        this.onListVariables(
          parameters.value != '' ? decodedList : '',
          parameters.value != '' ? this.encodeHTML(decodedList) : null
        );
        break;
      case 'sync.rsp':
        if (this.wait_sync) {
          this.wait_sync = false;
          if (parameters.status === 'Rejected') {
            this.warning.setWarning(WarningColor.red, [
              'Update in progress. Sync not allowed',
            ]);
            return;
          }
          this.warning.setWarning(WarningColor.green, ['Target synchronized']);
        } else {
          if (parameters.status === 'Rejected') return;
        }
        this.devMan.get_battery_details(
          this.devMan.device.sn,
          this.devMan.battery_num
        );
        break;
      case 'reboot.rsp':
        if (parameters.status === 'Rejected') {
          this.warning.setWarning(WarningColor.red, [
            'Update in progress. Reboot not allowed',
          ]);
          return;
        }
        this.warning.setWarning(WarningColor.green, ['Target reboot']);
        this.onReb();
        break;
      case 'update.firmware.res':
        if (parameters.status !== 'Rejected') return;
        this.warning.setWarning(WarningColor.red, parameters.info);
        index = this.devMan.upgrade_history.findIndex(
          (x) => x.action.toUpperCase() == 'START'
        );
        this.devMan.upgrade_history.splice(index, 1);
        break;
    }
  }

  private decodeHTML(str: string) {
    let txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value.trim();
  }
  private encodeHTML(str: string): string {
    const map: { [key: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      ' ': ' ',
      '\n': '<br>',
      '\t': '&emsp;',
    };

    return str.replace(/[&<>"'\s\t\n]/g, (match) => map[match]);
  }

  public async synchronize(wait_sync: boolean = true) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.Sync + ':')) return;
    let requestID = MQTT.createRequestID(MqttRemoteCommand.Sync + ':');
    this.mqttReciver.addRequestID(requestID, -1);
    let msg = JSON.stringify({
      cmdid: 'sync.req',
      requestID: requestID,
      parameters: { requestID: requestID },
    });
    this.wait_sync = wait_sync;
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async update(
    _type: DeviceType,
    firmwareDatalogger: Firmware,
    firmwareBattery: Firmware
  ) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.Upgrade + ':'))
      return;
    let requestID = MQTT.createRequestID(MqttRemoteCommand.Upgrade + ':');
    this.mqttReciver.addRequestID(requestID, -1);
    let type = 0;
    let sn;
    if (_type == DeviceType.datalogger) {
      sn = this.devMan.device.sn;
      if (DeviceMethods.isLinuxDevice(this.devMan.device.sn)) type = 1;
      else type = 0;
    }
    if (_type == DeviceType.battery) {
      sn = this.devMan.getCurrentBattery().sn;
      type = 2;
    }
    var message;
    if (type == 1) {
      message = {
        cmdid: 'update.firmware.req',
        requestID: requestID,
        parameters: {
          retries: 3,
          retryInterval: 5,
          sn: sn,
          location: firmwareDatalogger.url,
          retrieveDateTime: AppDate.stringFromDateFromYearToMilliseconds(
            new Date()
          ),
          signature: firmwareDatalogger.firmware_signature,
          signingCertificate: firmwareDatalogger.certificate,
          version: firmwareDatalogger.firmware_version,
          arguments: '-b',
        },
      };
    }
    if (type == 2) {
      message = {
        cmdid: 'update.firmware.req',
        requestID: requestID,
        parameters: {
          retries: 3,
          retryInterval: 5,
          sn: sn,
          location: firmwareBattery.url,
          retrieveDateTime: AppDate.stringFromDateFromYearToMilliseconds(
            new Date()
          ),
          signature: firmwareBattery.firmware_signature,
          signingCertificate: firmwareBattery.certificate,
          version: firmwareBattery.firmware_version,
          arguments:
            '-a ' +
            BatteryMethods.dec2hex(
              this.devMan.getCurrentBattery().baseid.toString(),
              4
            ),
        },
      };
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(
        this.createTopic(TopicType.publish),
        JSON.stringify(message),
        false
      )
    );
  }

  public async readVariable(
    type: DeviceType,
    strRangeID: string,
    size: string | null = null,
    description: string | null = null
  ) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.ReadVariables + ':'))
      return;
    let requestID = MQTT.createRequestID(MqttRemoteCommand.ReadVariables + ':');
    this.mqttReciver.addRequestID(requestID, -1);
    let arg: string = '';
    switch (type) {
      case DeviceType.datalogger:
        arg = '';
        break;
      case DeviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == DeviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'read.variables.req',
        requestID: requestID,
        parameters: {
          requestID: requestID,
          name: strRangeID,
        },
      });
    } else {
      if (size == null || description == null)
        msg = JSON.stringify({
          cmdid: 'read.variables.req',
          requestID: requestID,
          parameters: {
            requestID: requestID,
            arguments: arg,
            name: strRangeID,
          },
        });
      else
        msg = JSON.stringify({
          cmdid: 'read.variables.req',
          requestID: requestID,
          parameters: {
            requestID: requestID,
            arguments: arg,
            name: strRangeID,
            size: size,
            description: description,
          },
        });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async writeVariable(
    type: DeviceType,
    name: string,
    value: string,
    size: string | null = null,
    description: string | null = null
  ) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.WriteVariables + ':'))
      return;
    let requestID = MQTT.createRequestID(
      MqttRemoteCommand.WriteVariables + ':'
    );
    this.mqttReciver.addRequestID(requestID, -1);
    let arg: string = '';
    switch (type) {
      case DeviceType.datalogger:
        arg = '';
        break;
      case DeviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == DeviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'write.variables.req',
        requestID: requestID,
        parameters: {
          requestID: requestID,
          name: name,
          value: value,
        },
      });
    } else {
      if (size == null || description == null)
        msg = JSON.stringify({
          cmdid: 'write.variables.req',
          requestID: requestID,
          parameters: {
            requestID: requestID,
            arguments: arg,
            name: name,
            value: value,
          },
        });
      else
        msg = JSON.stringify({
          cmdid: 'write.variables.req',
          requestID: requestID,
          parameters: {
            requestID: requestID,
            arguments: arg,
            name: name,
            value: value,
            size: size,
            description: description,
          },
        });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async baseInfo(type: DeviceType, index: string) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.Info + ':')) return;
    let requestID = MQTT.createRequestID(MqttRemoteCommand.Info + ':');
    this.mqttReciver.addRequestID(requestID, Number(index));
    let arg: string = '';
    switch (type) {
      case DeviceType.datalogger:
        arg = '';
        break;
      case DeviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == DeviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'info.req',
        requestID: requestID,
        parameters: {
          requestID: requestID,
          index: index,
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'info.req',
        requestID: requestID,
        parameters: {
          arguments: arg,
          index: index,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async listVariables(type: DeviceType) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.ListVariables + ':'))
      return;
    let requestID = MQTT.createRequestID(MqttRemoteCommand.ListVariables + ':');
    this.mqttReciver.addRequestID(requestID, -1);
    let arg: string = '';
    switch (type) {
      case DeviceType.datalogger:
        arg = '';
        break;
      case DeviceType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
    }
    let msg = '';
    if (type == DeviceType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'list.variables.req',
        requestID: requestID,
        parameters: {
          requestID: requestID,
        },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'list.variables.req',
        requestID: requestID,
        parameters: {
          requestID: requestID,
          arguments: arg,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  public async reboot(reboot_type: rebootType) {
    if (this.mqttReciver.hasKeyWithText(MqttRemoteCommand.Reboot + ':')) return;
    let requestID = MQTT.createRequestID(MqttRemoteCommand.Reboot + ':');
    this.mqttReciver.addRequestID(requestID, -1);
    let arg: string = '';
    switch (reboot_type) {
      case rebootType.datalogger:
        arg = '';
        break;
      case rebootType.battery:
        arg =
          '-a ' +
          BatteryMethods.dec2hex(
            this.devMan.getCurrentBattery().baseid.toString(),
            4
          );
        break;
      case rebootType.all_battery:
        arg = '-a all';
        break;
    }
    let msg = '';
    if (reboot_type == rebootType.datalogger) {
      msg = JSON.stringify({
        cmdid: 'reboot.req',
        requestID: requestID,
        parameters: { requestID: requestID },
      });
    } else {
      msg = JSON.stringify({
        cmdid: 'reboot.req',
        requestID: requestID,
        parameters: {
          requestID: requestID,
          arguments: arg,
        },
      });
    }
    this.mqtt.pushPublish(
      new MqttHandlerMsg(this.createTopic(TopicType.publish), msg, false)
    );
  }

  /**
   * Check if target is reachable
   * If target is not reachable we start update firmware recovery operations
   */
  private targetNotreachable(cmdid: string, index: number) {
    switch (cmdid) {
      case 'read.variables':
      case 'list.variables':
        this.onTimeoutListReadVariables();
        break;
      case 'write.variables':
        break;
      case 'sync':
        if (this.wait_sync) {
          this.wait_sync = false;
          this.warning.setViewWarning(true);
        }
        break;
      case 'reboot':
        this.onReb();
        this.warning.setViewWarning(true);
        break;
      case 'info':
        this.devMan.updateValueForProperty('isOnline', false, index);
        this.devMan.updateValueForProperty('rssi', '-', index);
        this.devMan.updateValueForProperty('fw_ver', '-', index);
        break;
      case 'upgrade':
        index = this.devMan.upgrade_history.findIndex(
          (x) => x.action.toUpperCase() == 'START'
        );
        this.devMan.upgrade_history.splice(index, 1);
        this.warning.setViewWarning(true);
        break;
    }
    this.warning.setWarningBackgroundColor(WarningColor.red);
    this.warning.setWarningMessage(['Target is not reachable']);
  }

  public createTopic(topic_type: TopicType) {
    let topic = 'units/';

    topic += this.devMan.device.sn;
    switch (topic_type) {
      case TopicType.publish:
        topic += '/command/dev';
        break;
      case TopicType.subscribe:
        topic += '/command/app';
        break;
    }
    return topic;
  }
}

export enum MqttRemoteCommand {
  Info = 'info',
  Sync = 'sync',
  Reboot = 'reboot',
  Upgrade = 'upgrade',
  ReadVariables = 'read.variables',
  WriteVariables = 'write.variables',
  ListVariables = 'list.variables',
}

export enum rebootType {
  datalogger = 0,
  battery = 1,
  all_battery = 2,
}
