<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<div
  class="central_block modal-overlay"
  (window:keypress)="this.keyDownFunction($event)"
>
  <div class="form modal-content">
    <table>
      <tr>
        <th style="height: 20px; padding-top: 0px">
          <img
            src="../../assets/icon/arrow/left_black.svg"
            alt=""
            (click)="this.switch('left')"
          />
        </th>
        <th colspan="5" style="height: 20px; padding-top: 0px">
          {{ this.mL[this.date.getMonth()] }} - {{ this.date.getFullYear() }}
        </th>

        <th style="height: 20px; padding-top: 0px">
          <img
            src="../../assets/icon/arrow/right_black.svg"
            alt=""
            (click)="this.switch('right')"
          />
        </th>
      </tr>
      <tr>
        <th colspan="7" style="height: 20px; padding-top: 0px; opacity: 0.5">
          {{ this.format_date }}
        </th>
      </tr>
      <tr>
        <th
          [ngStyle]="{
            'border-top-left-radius': day == 'Mon' ? '8px' : '',
            'border-bottom-left-radius': day == 'Mon' ? '8px' : '',
            'border-top-right-radius': day == 'Sun' ? '8px' : '',
            'border-bottom-right-radius': day == 'Sun' ? '8px' : '',
          }"
          *ngFor="let day of this.day_of_week"
        >
          {{ day }}
        </th>
      </tr>
      <tr *ngFor="let week of days">
        <td
          *ngFor="let day of week"
          [ngStyle]="{
            opacity: day.type ? 1 : 0.5,
            'background-color': day.is_selected ? this.color : '',
            color: day.is_selected ? '#ffffff' : ''
          }"
          (click)="this.select(day, day.type)"
        >
          {{ day.date.getDate() }}
          <div
            *ngIf="this.with_events"
            class="dot"
            [ngStyle]="{
              'background-color': day.there_is_mark ? day.is_selected ? 'white' : this.color : 'trasparent',
            }"
          ></div>
        </td>
      </tr>
    </table>
    <div>
      <button class="submit delete" (click)="this.close.emit(false)">
        CANCEL
      </button>
      <button class="submit" (click)="this.ok()">OK</button>
    </div>
  </div>
</div>
