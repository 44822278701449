/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

/**
 * Check if user input is valid
 * All method return true if input is valid, false otherwise
 */
export class Regex {
  static regex_email(email: string): boolean {
    var regex = new RegExp(
      '^(([_A-Za-z0-9\\-]){1,}(\\.[_A-Za-z0-9\\-]{1,}){0,}@[A-Za-z0-9\\-]{1,}(\\.[A-Za-z0-9]{1,}){0,}(\\.[A-Za-z]{2,}))$',
      'g'
    );
    return email.match(regex) != null;
  }
  static regex_pwd(pwd: string): boolean {
    var regex = new RegExp(
      '^(?=.{0,}[a-z])(?=.{0,}[A-Z])(?=.{0,}\\d)(?=.{0,}[\\$@!%\\*#\\?&])[A-Za-z\\d\\$@!%\\*#\\?&]{8,20}$',
      'g'
    );

    return pwd.match(regex) != null;
  }
  /**
   * Check if user input is valid
   *
   * All method return true if input is valid, false otherwise
   */
  static regex_phone(phone_number: string): boolean {
    var regex = new RegExp('^[\\+]{0,1}[0-9]{3,20}$', 'g');
    return phone_number.match(regex) != null;
  }

  static regex_word(word: string): boolean {
    var regex = new RegExp('^[a-zA-Z\\ ]{1,}$', 'g');
    return word.match(regex) != null;
  }

  // Example of correct csv name: 111921140006_107__0.csv
  static regex_csv_filename(filename: string): boolean {
    const regex = /^\d{12}_\d+__\d+\.csv$/;
    return regex.test(filename);
  }
}
