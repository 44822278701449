import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AppRoutingModule, Message } from 'src/app/app-routing.module';
import { Regex } from 'src/model/Regex';
import { Data_registration } from '../registration/data_registration';
import { Warning, WarningColor } from 'src/app/warning/warning.component';

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.css'],
})
export class AccountDeleteComponent {
  public presentation: boolean = true;
  public delete_confirm: boolean = false;

  public warning: Warning = new Warning();

  /**
   * required: 0 email
   */
  public email = new Data_registration(['email'], [], this.http, this.app);

  constructor(public http: HttpClient, public app: AppRoutingModule) {
    this.app.page_access('account_delete');
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      if (!this.presentation) {
        this.delete();
      } else this.delete_previous();
    }
  }

  delete() {
    if (
      !this.email.required() &&
      Regex.regex_email(this.email.data_required[0])
    ) {
      this.http
        .post<boolean | Message>(
          this.app.server_domain + '/account_delete.php',
          {
            request: 'account_delete',
            email: this.email.data_required[0],
          }
        )
        .subscribe(
          (result) => {
            if (typeof result == 'boolean') {
              this.warning.setWarning(
                WarningColor.red,
                ['Internal server error']
              );
              return;
            }
            this.delete_next();
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  private delete_next() {
    this.presentation = false;
    this.delete_confirm = true;
  }

  public delete_previous() {
    this.presentation = true;
    this.delete_confirm = false;
  }
}
