<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div class="block">
  <div>
    <p class="title">Dashboard</p>
  </div>
  <div class="fixed_table">
    <table class="list">
      <tbody>
        <tr>
          <td>
            <div class="dashboard_unit">
              <table>
                <tr>
                  <td
                    colspan="2"
                    style="
                      border-top-left-radius: 2px;
                      border-top-right-radius: 2px;
                      padding-top: 14px;
                      padding-bottom: 0px;
                      color: rgba(255, 255, 255, 0.8);
                    "
                  >
                    <div style="float: left; padding: 0px">
                      <img
                        style="width: 12px"
                        src="../../../assets/icon/admin/account icon.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div style="float: left; padding: 2px 0px 0px 5px">
                      Users
                    </div>
                  </td>
                </tr>
                <tr>
                  <th
                    colspan="2"
                    style="font-size: 20px; font-weight: bold; padding-top: 0px"
                  >
                    {{ this.users.log_access_list.length }}
                  </th>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    style="
                      border-bottom-left-radius: 2px;
                      border-bottom-right-radius: 2px;
                      padding-top: 14px;
                      padding-bottom: 14px;
                      background-color: #343334;
                    "
                  >
                    <div
                      class="go_to"
                      (click)="
                        this.home.change_component(ComponentType.LOG_ACCESS)
                      "
                    >
                      Log access
                    </div>
                    <div style="float: left">&nbsp;/&nbsp;</div>
                    <div
                      class="go_to"
                      (click)="
                        this.home.change_component(ComponentType.MANAGE_USERS)
                      "
                    >
                      Manage users
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td>
            <div class="dashboard_unit">
              <table>
                <tr>
                  <td
                    colspan="2"
                    style="
                      border-top-left-radius: 2px;
                      border-top-right-radius: 2px;
                      padding-top: 14px;
                      padding-bottom: 0px;
                      color: rgba(255, 255, 255, 0.8);
                    "
                  >
                    <div style="float: left; padding: 0px">
                      <img
                        style="width: 19.5px"
                        src="../../../assets/icon/admin/battery_white.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div style="float: left; padding: 2px 0px 0px 5px">
                      Devices
                    </div>
                  </td>
                </tr>
                <tr>
                  <th
                    colspan="2"
                    style="font-size: 20px; font-weight: bold; padding-top: 0px"
                  >
                    {{ this.devices.devices_list.length }}
                  </th>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    style="
                      border-bottom-left-radius: 2px;
                      border-bottom-right-radius: 2px;
                      padding-top: 14px;
                      padding-bottom: 14px;
                      background-color: #343334;
                    "
                  >
                    <div
                      class="go_to"
                      (click)="
                        this.home.change_component(ComponentType.MANAGE_DEVICES)
                      "
                    >
                      Manage devices
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="dashboard_unit">
              <table>
                <tr>
                  <td style="border-top-left-radius: 2px; padding-top: 14px">
                    Users with <b style="font-size: 14px">Activities</b> in the
                    last 3 days
                  </td>
                  <td
                    class="td_right"
                    style="border-top-right-radius: 2px; padding-top: 14px"
                  >
                    {{ this.users.active_users }}
                  </td>
                </tr>

                <tr>
                  <td
                    style="border-bottom-left-radius: 2px; padding-bottom: 14px"
                  >
                    Users with <b style="font-size: 14px">Verified</b> email
                  </td>
                  <td
                    class="td_right"
                    style="
                      border-bottom-right-radius: 2px;
                      padding-bottom: 14px;
                    "
                  >
                    {{ this.users.valid_users }}
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td>
            <div class="dashboard_unit">
              <table>
                <tr>
                  <td style="border-top-left-radius: 2px; padding-top: 14px">
                    Total amount of
                    <b style="font-size: 14px">Rewind</b> devices
                  </td>
                  <td
                    class="td_right"
                    style="border-top-right-radius: 2px; padding-top: 14px"
                  >
                    {{ this.devices.rewind_count }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Total amount of
                    <b style="font-size: 14px">Generic linux</b> devices
                  </td>
                  <td class="td_right">
                    {{ this.devices.generic_linux_count }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Total amount of
                    <b style="font-size: 14px">Battery</b> devices
                  </td>
                  <td class="td_right">
                    {{ this.devices.battery_count }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Total amount of
                    <b style="font-size: 14px">Application</b> devices
                  </td>
                  <td class="td_right">
                    {{ this.devices.application_count }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="border-bottom-left-radius: 2px; padding-bottom: 14px"
                  >
                    <b style="font-size: 14px">Online</b> devices
                  </td>
                  <td
                    class="td_right"
                    style="
                      border-bottom-right-radius: 2px;
                      padding-bottom: 14px;
                    "
                  >
                    {{ this.devices.online_device }}
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
