/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DeviceMethods } from 'src/model/app.deviceManager';

export class ManageDevices {
  public devices_list: AdminDevice[] = [];
  public online_device: number = 0;
  public rewind_count: number = 0;
  public generic_linux_count: number = 0;
  public battery_count: number = 0;
  public application_count: number = 0;

  public update_message() {}

  constructor(public app: AppRoutingModule, public http: HttpClient) {}
  public get_devices_list() {
    this.http
      .post<AdminDevice[]>(this.app.server_domain + '/device.php', {
        request: 'admin_get_device_list',
        token: this.app.token,
      })
      .subscribe(
        (result) => {
          if (typeof result == 'boolean') {
            this.devices_list = [];
            this.online_device = 0;
            return;
          }
          this.devices_list = result;
          this.online_device = this.devices_list.filter(
            (x) => x.is_online == true
          ).length;
          this.update_message();
          this.count_type();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private count_type() {
    this.rewind_count = 0;
    this.generic_linux_count = 0;
    this.battery_count = 0;
    this.application_count = 0;

    this.devices_list.forEach((x) => {
      switch (DeviceMethods.device_type_name(x.sn.substring(0, 2))) {
        case 'Rewind':
          this.rewind_count += 1;
          break;
        case 'Generic linux':
          this.generic_linux_count += 1;
          break;
        case 'Battery':
          this.battery_count += 1;
          break;
        case 'Application':
          this.application_count += 1;
          break;
      }
    });
  }
}
export class AdminDevice {
  public id: number = -1;
  public sn: string = '';
  public name: string = '';
  public is_online: boolean = false;
  public type: number = -1;
  public devInfo!: AdminDeviceInfo;
  public user_count: number = 0;

  constructor() {}
}

export class AdminDeviceInfo {
  public last_seen: string = '';
  public last_transmission: string = '';
  public first_transmission: string = '';

  constructor() {}
}
