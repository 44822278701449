/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { AppDate } from 'src/model/app.date';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DeviceManager } from 'src/model/app.deviceManager';
import { calculateRangeLimit, intervalType } from 'src/model/app.record_map';

dayjs.extend(utc);
dayjs.extend(timezone);

export class CalendarEvent {
  public events: Date[] = [];
  public update = () => {};
  private request: Request = Request.DEFAULT;
  private eventsOverMonths: EventsOverMonths[] = [];
  constructor(request: Request) {
    this.request = request;
  }

  public getMonthEvents(
    date: Date,
    http: HttpClient,
    devMan: DeviceManager,
    app: AppRoutingModule
  ) {
    this.events = [];

    let tmp = this.monthAlreadyLoaded(date);
    if (tmp != null) {
      this.events = tmp.events;
      this.update();
      return;
    }

    let request = {};
    if (this.request == Request.EVENTS)
      request = this.eventsRequest(date, devMan, app);
    else if (this.request == Request.CHARTS)
      request = this.chartsRequest(date, devMan, app);
    else return;

    http.post<number[]>(app.server_domain + '/battery.php', request).subscribe(
      (result) => {
        if (typeof result == 'boolean') {
          console.log('Internal server error');
          return;
        }
        result.forEach((x) => {
          this.events.push(new Date(x));
        });

        let tmpDate = new Date(date);
        let tmp: EventsOverMonths = new EventsOverMonths(tmpDate, this.events);
        this.eventsOverMonths.push(tmp);
        this.update();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private monthAlreadyLoaded(date: Date): EventsOverMonths | null {
    let tmp = this.eventsOverMonths.find(
      (x) => AppDate.isSameMonth(date, x.data) == true
    );
    if (tmp == undefined) return null;
    return tmp;
  }

  private eventsRequest(
    date: Date,
    devMan: DeviceManager,
    app: AppRoutingModule
  ) {
    // Set start of month
    const startOfMonth = dayjs(date)
      .startOf('month')
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
    // Set end of month
    const endOfMonth = startOfMonth
      .endOf('month')
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59);

    // Get the UTC offset in minutes and convert to hours
    const offsetHours = startOfMonth.utcOffset() / 60;
    const offset = offsetHours >= 0 ? `-${offsetHours}h` : `${offsetHours}h`;
    return {
      request: this.request,
      token: app.token,
      sn: devMan.device.sn,
      num_battery: devMan.battery_num,
      start_date: startOfMonth.unix(),
      end_date: endOfMonth.unix(),
      offset: offset,
    };
  }

  private chartsRequest(
    date: Date,
    devMan: DeviceManager,
    app: AppRoutingModule
  ) {
    let offset = 0;
    let now = new Date();
    const date1StartOfMonth = AppDate.startOfMonth(now);
    const date2StartOfMonth = AppDate.startOfMonth(date);

    const yearDiff =
      date2StartOfMonth.getUTCFullYear() - date1StartOfMonth.getUTCFullYear();
    const monthDiff =
      date2StartOfMonth.getUTCMonth() - date1StartOfMonth.getUTCMonth();
    offset = yearDiff * 12 + monthDiff;

    let calcRange = calculateRangeLimit(intervalType.Monthly, offset);

    // Get Unix timestamps (seconds since the epoch)
    const startSec = dayjs(calcRange.start).unix();
    const stopSec = dayjs(calcRange.end).unix();

    const systemTimeZone = dayjs.tz.guess(); // Get the system's default timezone
    const zonedDateTime = dayjs(calcRange.start).tz(systemTimeZone); // Get ZonedDateTime equivalent: the current time in the system's timezone
    const offsetSeconds = zonedDateTime.utcOffset() * 60; // Calculate the total offset in seconds
    const offsetHours = offsetSeconds / 3600; // Calculate the offset in hours

    // Format the offset as a string
    const windowOffset =
      offsetHours >= 0 ? `-${offsetHours}h` : `${offsetHours}h`;

    return {
      request: this.request,
      token: app.token,
      sn: devMan.device.sn,
      num_battery: devMan.battery_num,
      start_date: startSec,
      end_date: stopSec,
      increment: '1d',
      offset: windowOffset,
    };
  }
}

class EventsOverMonths {
  public data: Date = new Date();
  public events: Date[] = [];
  constructor(data: Date, events: Date[]) {
    this.data = data;
    this.events = events;
  }
}
export enum Request {
  DEFAULT = '',
  EVENTS = 'device_battery_calendar_event',
  LOGS = '',
  CHARTS = 'device_battery_history_calendar_events',
}
