<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div id="body" *ngIf="this.app.pages.types[2]">
  <div class="logo">
    <img src="../../assets/logo-archimede-b.png" alt="" />
  </div>

  <div class="central_block" (window:keypress)="this.keyDownFunction($event)">
    <div>
      <p class="title">Forgot password</p>
    </div>
    <div class="form" *ngIf="!this.end_forgot_password">
      <p class="label">Email address</p>
      <p class="required">*</p>
      <input
        id="email"
        type="text"
        class="input"
        (input)="this.required(); this.warning.setViewWarning(false)"
      />
      <p class="message" *ngIf="this.email_message">This field is required</p>
      <div>
        <button class="submit" (click)="onSubmit()">Send</button>
      </div>
    </div>
    <div class="form" *ngIf="this.end_forgot_password">
      <p class="end_label">
        If the entered email address exists, an email will be sent to the
        provided address. Please check your email inbox for the password reset
        link.
      </p>
      <p class="end_label">
        Did not receive an email? Resend the email by clicking the button below.
      </p>

      <div>
        <button class="submit" (click)="onSubmit()">Resend</button>
      </div>
    </div>
  </div>

  <app-warning
    [title]="'Server response'"
    [background_color]="this.warning.getWarningBackgroundColor()"
    [message]="this.warning.getWarningMessage()"
    [warning]="this.warning.getViewWarning()"
    (response)="this.warning.setViewWarning($event)"
  ></app-warning>
</div>
