/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.css'],
})
export class OptionComponent {
  @Input() title: string = '';
  @Input() subTitle: string = '';

  @Input() button_index: number[] = [];

  @Input() tools_imgs: string[] = [];
  @Input() tools_imgs_over: string[] = [];
  @Input() is_over: boolean[] = [];
  @Input() tools_title: string[] = [];
  @Input() tools_label: string[] = [];
  @Input() disable: boolean[] = [];

  @Output() close = new EventEmitter<boolean>();
  @Output() command = new EventEmitter<string>();

  public clickCommand(index: number) {
    let command = this.tools_title[index];
    if (!this.disable[index]) {
      this.command.emit(command);
      this.close.emit(false);
    }
  }
}
