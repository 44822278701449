/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { HttpClient } from '@angular/common/http';
import { Input, OnChanges } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppRoutingModule } from '../../app-routing.module';
import { DeviceListComponent } from '../../device-list/device-list.component';
import { AppDate } from 'src/model/app.date';

@Component({
  selector: 'app-admin-device-options',
  templateUrl: './admin-device-options.component.html',
  styleUrls: ['./admin-device-options.component.css'],
})
export class AdminDeviceOptionsComponent implements OnInit, OnChanges {
  @Input() id: number = -1;
  @Input() sn: string = '';
  @Input() option_type_input: string = '';

  @Input() first_transmission: Date = new Date();
  @Input() last_transmission: Date = new Date();

  @Output() close = new EventEmitter<boolean>();

  public calendar_start: boolean = false;
  public calendar_end: boolean = false;
  public startValueDate: Date = new Date();
  public endValueDate: Date = new Date();

  public title: string = '';
  public confirm_delete: boolean = false;

  public button_index: number[] = [deviceOptionType.delete];

  public tools_imgs: string[] = ['../../assets/icon/device-list/delete.svg'];
  public tools_imgs_over: string[] = [
    '../../assets/icon/device-list/delete_white.svg',
  ];
  public is_over: boolean[] = [false, false, false];
  public tools_title: string[] = ['Delete log data'];
  public tools_label: string[] = ['Delete device log data'];

  constructor(
    public app: AppRoutingModule,
    public http: HttpClient,
    public devices_list: DeviceListComponent
  ) {}
  ngOnChanges(): void {
    this.change_view(this.option_type_input);
  }

  ngOnInit(): void {
    this.startValueDate = new Date(this.first_transmission);
    this.endValueDate = new Date(this.last_transmission);
  }

  public async command(command: string) {
    switch (command) {
      case 'Delete log data':
        this.change_view('delete log data');
        break;
    }
  }

  public change_view(type_view: string) {
    switch (type_view) {
      case 'delete log data':
        if (this.confirm_delete)
          this.title = 'This is an irreversible command. Are you sure?';
        else this.title = 'Do you want to delete device log data?';
        this.option_type_input = 'delete log data';
        break;
    }
  }
  public delete_device_log_data() {
    console.log(this.startValueDate.toISOString());
    console.log(this.endValueDate.toISOString());
    console.log(this.sn);

    // this.http
    //   .post<boolean>(this.app.server_domain + '/device.php', {
    //     request: 'delete_device_log_data',
    //     token: this.app.token,
    //     sn: this.sn,
    //     start: this.startValueDate.toISOString(),
    //     end: this.endValueDate.toISOString()
    //   })
    //   .subscribe(
    //     (result) => {
    //       console.log(result);

    //       if (result) {
    //         this.devices_list.get_devices_list();
    //         this.close.emit(false);
    //         return;
    //       }
    //       this.close.emit(false);
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );
  }

  private get_input() {
    const input = document.getElementById('option_input')! as HTMLInputElement;
    if (input != null) {
      let result = input.value;
      if (result == '') {
      }
      return result;
    }
    return '';
  }
  public formatDate(date: Date) {
    return AppDate.stringFromDate(date, AppDate.format_day2_monthshort_yearnum);
  }
  public formatTime(date: Date) {
    return AppDate.stringFromDate(date, AppDate.format_hour2_minute2);
  }
}

enum deviceOptionType {
  delete = 0,
}
