<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<app-dashboard-menu
  class="dashboard_menu"
  [mark_color]="'#50a17c'"
  [button_active]="'multi'"
  [string_buttons]="this.menu_string_option"
  [view_labels]="true"
  [icon_width]="['29px']"
  [icon_height]="['29px']"
  [icon_margin]="['-2px 0px 0px 0px']"
  [icon_padding]="['0px 0px 0px 0px']"
  [buttons]="this.menu_options"
  [icons]="this.menu_icons"
  [is_active]="[this.devMan.tools.wait_sync]"
  (response)="this.command($event)"
></app-dashboard-menu>
<div class="fixed_table">
  <table class="list">
    <tr>
      <th colspan="5">State of charge</th>
    </tr>
    <tr>
      <td colspan="5">
        <div class="state_of_charge"><canvas id="gauge"></canvas></div>
        <div class="battery_number">
          <table>
            <tr>
              <td
                colspan="3"
                [ngStyle]="{ opacity: this.is_cluster ? '1' : '0.5' }"
              >
                CLUSTER
              </td>
            </tr>
            <tr>
              <td>{{ this.devMan.battery_num + 1 }}</td>
              <td style="background-color: #343334; color: white">|</td>
              <td>{{ this.devMan.batteries_tot_num }}</td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="5">
        <table class="switch">
          <tr>
            <td></td>
            <td class="switch_left" (click)="this.devMan.switch('left')">
              <img src="../../../assets/icon/arrow/left.svg" alt="" />
            </td>
            <td class="switch_right" (click)="this.devMan.switch('right')">
              <img src="../../../assets/icon/arrow/right.svg" alt="" />
            </td>
            <td></td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <th colspan="5">Information</th>
    </tr>
    <tr>
      <td colspan="2" class="td_data td_left">Serial number</td>
      <td colspan="3" class="td_data td_right">
        {{ this.devMan.getCurrentBattery().sn }}
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td_data td_left">Data as of</td>
      <td colspan="3" class="td_data td_right">
        {{ this.lastUpdate(this.devMan.getCurrentBattery().last_update) }}
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td_data td_left">Base ID</td>
      <td colspan="3" class="td_data td_right">
        {{ this.state(this.devMan.getCurrentBattery().baseid.toString(), 4) }}
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td_data td_left">Cycles</td>
      <td colspan="3" class="td_data td_right">
        {{ this.devMan.getCurrentBattery().cycles }}
      </td>
    </tr>
    <tr>
      <th colspan="5">Data</th>
    </tr>
    <tr>
      <td class="td_data td_left td_icon">
        <img class="icon" src="../../assets/icon/battery-details/voltage.svg" />
      </td>
      <td colspan="2" class="td_data td_left">Voltage</td>
      <td class="td_data td_right">
        {{ this.devMan.getCurrentBattery().voltage.toFixed(2) }}
      </td>
      <td class="td_data td_unit">V</td>
    </tr>
    <tr>
      <td class="td_data td_left td_icon">
        <img class="icon" src="../../assets/icon/battery-details/current.svg" />
      </td>
      <td colspan="2" class="td_data td_left">Current</td>
      <td class="td_data td_right">
        {{ this.devMan.getCurrentBattery().current.toFixed(2) }}
      </td>
      <td class="td_data td_unit">A</td>
    </tr>
    <tr>
      <td class="td_data td_left td_icon">
        <img
          class="icon"
          src="../../assets/icon/battery-details/temperature.svg"
        />
      </td>
      <td colspan="2" class="td_data td_left">Average temperature</td>
      <td class="td_data td_right">
        {{
          (this.devMan.getCurrentBattery().t_min +
            this.devMan.getCurrentBattery().t_max) /
            2
        }}
      </td>
      <td class="td_data td_unit">°C</td>
    </tr>
    <tr>
      <td class="td_data td_left td_icon">
        <img
          class="icon"
          src="../../assets/icon/battery-details/cell_voltage_range.svg"
        />
      </td>
      <td colspan="2" class="td_data td_left">Cell voltage range</td>
      <td class="td_data td_right">
        {{ this.devMan.getCurrentBattery().v_min.toFixed(2) }} -
        {{ this.devMan.getCurrentBattery().v_max.toFixed(2) }}
      </td>
      <td class="td_data td_unit">V</td>
    </tr>
    <tr>
      <th colspan="5">Errors/Warnings</th>
    </tr>
    <tr>
      <td class="td_data td_left td_icon">
        <img class="icon" src="../../assets/icon/battery-details/state.png" />
      </td>
      <td colspan="2" class="td_data td_left">State</td>
      <td
        colspan="2"
        class="td_data td_right"
        *ngIf="
          !this.checkAlarm(this.devMan.getCurrentBattery()) &&
          this.devMan.getCurrentBattery().canbus_timeout == 0
        "
      >
        OK
      </td>
      <td
        colspan="2"
        class="td_data td_right"
        *ngIf="
          this.checkAlarm(this.devMan.getCurrentBattery()) &&
          this.devMan.getCurrentBattery().canbus_timeout == 0
        "
      >
        ERROR
      </td>
      <td
        colspan="2"
        class="td_data td_right"
        *ngIf="this.devMan.getCurrentBattery().canbus_timeout == 1"
      >
        CAN TIMEOUT
      </td>
    </tr>
  </table>
  <table id="status_word" class="list">
    <tr>
      <th class="sw_th sw_left" colspan="2">Status word</th>
      <th class="sw_th sw_right" colspan="2">{{ this.sw_hex }}</th>
    </tr>
    <tr *ngFor="let num of [0, 1, 2, 3, 4, 5, 6, 7]">
      <td class="td_svg">
        <svg>
          <rect
            style="
              fill: #ffffff;
              stroke: #ffffff;
              stroke-width: 4.59433;
              stroke-linecap: round;
              stroke-linejoin: round;
              fill-opacity: 1;
            "
            id="rect341"
            width="15.405674"
            height="15.40567"
            x="2.2971649"
            y="2.2971649"
            [ngStyle]="{
              fill: this.sw[num] == '0' ? 'rgb(75, 75, 75)' : '#50a17c',
              stroke: this.sw[num] == '0' ? 'rgb(75, 75, 75)' : '#50a17c'
            }"
          />
        </svg>
      </td>
      <td class="sw_td">{{ this.sw_labels[num] }}</td>
      <td class="td_svg">
        <svg>
          <rect
            style="
              fill: #ffffff;
              stroke: #ffffff;
              stroke-width: 4.59433;
              stroke-linecap: round;
              stroke-linejoin: round;
              fill-opacity: 1;
            "
            id="rect341"
            width="15.405674"
            height="15.40567"
            x="2.2971649"
            y="2.2971649"
            [ngStyle]="{
              fill: this.sw[num + 8] == '0' ? 'rgb(64, 64, 64)' : '#50a17c',
              stroke: this.sw[num + 8] == '0' ? 'rgb(64, 64, 64)' : '#50a17c'
            }"
          />
        </svg>
      </td>
      <td class="sw_td">{{ this.sw_labels[num + 8] }}</td>
    </tr>
    <tbody *ngIf="!this.is_cluster">
      <tr>
        <th class="sw_th sw_left" colspan="2">Status word 2</th>
        <th class="sw_th sw_right" colspan="2">{{ this.sw2_hex }}</th>
      </tr>
      <tr *ngFor="let num of [0, 1, 2, 3, 4, 5, 6, 7]">
        <td class="td_svg">
          <svg>
            <rect
              style="
                fill: #ffffff;
                stroke: #ffffff;
                stroke-width: 4.59433;
                stroke-linecap: round;
                stroke-linejoin: round;
                fill-opacity: 1;
              "
              id="rect341"
              width="15.405674"
              height="15.40567"
              x="2.2971649"
              y="2.2971649"
              [ngStyle]="{
                fill: this.sw2[num] == '0' ? 'rgb(75, 75, 75)' : '#50a17c',
                stroke: this.sw2[num] == '0' ? 'rgb(75, 75, 75)' : '#50a17c'
              }"
            />
          </svg>
        </td>
        <td class="sw_td">{{ this.sw2_labels[num] }}</td>
        <td class="td_svg">
          <svg>
            <rect
              style="
                fill: #ffffff;
                stroke: #ffffff;
                stroke-width: 4.59433;
                stroke-linecap: round;
                stroke-linejoin: round;
                fill-opacity: 1;
              "
              id="rect341"
              width="15.405674"
              height="15.40567"
              x="2.2971649"
              y="2.2971649"
              [ngStyle]="{
                fill: this.sw2[num + 8] == '0' ? 'rgb(64, 64, 64)' : '#50a17c',
                stroke: this.sw2[num + 8] == '0' ? 'rgb(64, 64, 64)' : '#50a17c'
              }"
            />
          </svg>
        </td>
        <td class="sw_td">{{ this.sw2_labels[num + 8] }}</td>
      </tr>
    </tbody>
  </table>
</div>
