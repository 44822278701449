/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
enum userType {
  enduser = 1,
  superuser = 2,
  manufacturer = 3,
}
export enum userTypeString {
  enduser = 'Enduser',
  superuser = 'Superuser',
  manufacturer = 'Manufacturer',
}
export class User {
  static user_type_from_number_to_string(type: number) {
    switch (type) {
      case 1:
        return userTypeString.enduser;
      case 2:
        return userTypeString.superuser;
      case 3:
        return userTypeString.manufacturer;
    }
    return userTypeString.enduser;
  }

  static user_type_from_string_to_number(type: string) {
    switch (type) {
      case 'Enduser':
        return userType.enduser;
      case 'Superuser':
        return userType.superuser;
      case 'Manufacturer':
        return userType.manufacturer;
    }
    return userType.enduser;
  }
}
