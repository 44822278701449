/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule, Message } from '../app/app-routing.module';

export class Device_shared {
  constructor(public app: AppRoutingModule, public http: HttpClient) {}

  //http://localhost:4200/device_shared?alias=new_device&sn=01_03237745
  public save() {
    const url = location.href;
    if (url.includes('device_shared')) {
      var start_pos_alias = url.search('alias=') + 6;
      var start_pos_sn = url.search('sn=') + 3;
      var json_obj = {
        alias: url.substring(start_pos_alias, start_pos_sn - 4),
        sn: url.substring(start_pos_sn),
      };
      sessionStorage.setItem('device_shared', JSON.stringify(json_obj));
    }
  }
}
