/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Command } from 'src/app/dashboard-menu/command';
import { HomeComponent } from 'src/app/home/home.component';
import { User } from 'src/app/advanced/advanced-user/user';
import { CommentObj, DeviceSettings, ExtendedObj } from './device-settings';
import { DeviceManager, DeviceType } from 'src/model/app.deviceManager';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.css'],
})
export class DeviceSettingsComponent implements OnInit, OnDestroy {
  public menu_string_option: string[] = ['import', 'save'];
  public menu_options: boolean[] = [false, false];
  public is_active: boolean[] = [false, false];
  public menu_icons: string[] = [
    '../../assets/icon/advanced/square.and.arrow.up.fill.svg',
    '../../assets/icon/advanced/square.and.arrow.down.svg',
  ];
  // INFO, STATE, GENERAL, LOG, TEMPERATURES, VOLTAGES, CURRENT, SOC, SOH, CANBUS, CHARGER, CLUSTER, VIASAT, CLIMATIC
  public categories: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  public objList: ExtendedObj[] = [];
  public commentsList: CommentObj[] = [];
  public comment: CommentObj = new CommentObj('', '');
  public wait_variables: boolean = true;
  public interval_list_variables: any;
  // Flag to disable state machine when there is in pending a write variable command
  public waitWrite: boolean = false;
  private writeVariablesQueue: {
    type: DeviceType;
    id: string;
    name: string;
    value: string;
  }[] = [];
  public interval_write_variables: any;

  // Flag to check when client wait the update of list variables
  public waitRead: boolean = false;

  public rowIndex: number = -1;

  // Variable to track when the user selects the "editable" column.
  // If the editable column is selected and the user navigates up or down with the arrow keys,
  // when the user reaches an editable row, the input field is automatically selected.
  public isInputSelected: boolean = false;

  // The encoded message to improve readVariables speed
  private encodedDescriptionList: string | null = null;

  // Flag to verify that the user has performed at least one write to avoid unnecessary synchronizations
  private atLeastOneWrite: boolean = false;

  // Track focus state for each input
  inputFocusState: { [key: string]: boolean } = {};

  public waitComment: string = 'Downloading device parameters...';
  constructor(
    public home: HomeComponent,
    public devMan: DeviceManager,
    public devSet: DeviceSettings
  ) {
    this.commentsList = this.devSet.getCommentsObjs();
  }

  ngOnInit(): void {
    this.devMan.tools.onTimeoutListReadVariables = () => {
      this.waitComment = 'Failed to download device parameters';
      this.objList = [];
      this.wait_variables = true;
    };
    this.devMan.tools.onListVariables = (
      str: string,
      encodedDescriptionList: string
    ) => {
      if (str == '') {
        this.waitComment = 'Failed to download device parameters';
        return;
      }
      this.encodedDescriptionList = encodedDescriptionList;
      this.objList = this.devSet.getExtendedObjs(str);
      this.wait_variables = false;
      this.waitRead = false;
    };
    this.devMan.tools.listVariables(DeviceType.battery);

    this.devMan.tools.onReadVariable = (str: string) => {
      if (str == '') {
        this.waitComment = 'Failed to download device parameters';
        this.objList = [];
        this.wait_variables = true;
        return;
      }
      let all_tmp = str.includes(',') ? str.split(',') : [str];
      all_tmp.forEach((y: string) => {
        let first_space = y.indexOf(' ');

        // Check if there is a space, if not, continue to the next iteration
        if (first_space === -1) return;

        // Extract id and value from the string
        let id = y.substring(0, first_space);
        let value = y.substring(first_space).trim().replace(/'/g, '');

        // Find the corresponding object in objList
        let index = this.objList.findIndex((x) => x.id === Number(id));
        if (index !== -1) {
          let name = this.objList[index].name;

          // Check if the input associated with the name is focused
          if (!this.inputFocusState[name]) {
            // Update value only if the input is not focused
            this.objList[index].value = value;
            this.objList[index].waitUpdate = false;
          }
        }
      });
      this.waitRead = false;
    };

    this.devMan.tools.onWriteVariable = () => {
      this.writeVariablesQueue.shift();
      this.waitWrite = false;
      // When we write last variables is possible restart read variebles
      if (this.writeVariablesQueue.length == 0) {
        // Read all variables
        this.readAllVariables();
        // Set interval for read variables
        this.resetReadVariablesInterval();
        // Clean interval for write variables because is unnecessary
        clearInterval(this.interval_write_variables);
      }
    };
    this.resetReadVariablesInterval();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_list_variables);
    clearInterval(this.interval_write_variables);
    if (this.atLeastOneWrite) this.devMan.tools.synchronize(false);
  }

  // Update focus state when input is focused or blurred
  public updateFocusState(name: string, isFocused: boolean) {
    this.inputFocusState[name] = isFocused;
  }

  // Utility function to find input by name
  private getInputByName(name: string): HTMLInputElement | null {
    return document.getElementById(`inputVariable_${name}`) as HTMLInputElement;
  }

  // Utility function to find row by name
  private getRowByName(name: string): HTMLTableRowElement | null {
    return document.getElementById(`trVariable_${name}`) as HTMLTableRowElement;
  }

  // Common method for handling input blur and reset
  private resetInput(input: HTMLInputElement, old_value: string) {
    input.value = old_value.trim();
    input.blur();
  }

  // Keydown event handler for inputs
  public onKeydownInput(
    event: { key: string; preventDefault: () => void },
    id: number,
    name: string,
    old_value: string
  ): void {
    const input = this.getInputByName(name);
    if (!input) return;

    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      const value = input.value.trim();

      // Add variable to the write queue
      this.writeVariablesQueue.push({
        type: DeviceType.battery,
        id: id.toString(),
        name: name,
        value: value,
      });

      // Update object list status
      const index = this.objList.findIndex((x) => x.id == id);
      if (index !== -1) this.objList[index].waitUpdate = true;

      // Reset input and handle focus
      this.resetInput(input, old_value);
      this.comment = new CommentObj('', '');

      const rowElement = this.getRowByName(name);
      if (rowElement) rowElement.focus();

      // Manage intervals for variable writes
      clearInterval(this.interval_list_variables);
      if (this.writeVariablesQueue.length === 1) {
        clearInterval(this.interval_write_variables);
        this.clearWriteVariablesQueue();
        this.interval_write_variables = setInterval(
          () => this.clearWriteVariablesQueue(),
          1000
        );
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      this.resetInput(input, old_value);
    }
  }

  // Blur handler to reset input if focus is lost
  public handleBlur(event: FocusEvent, name: string, old_value: string): void {
    const input = event.target as HTMLInputElement;
    if (input.value.trim() !== old_value.trim()) {
      this.resetInput(input, old_value);
    }
  }

  // Keydown event handler for row navigation
  public onKeydownRow(event: {
    key: string;
    preventDefault: () => void;
  }): void {
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') return;

    let rowIndex = this.rowIndex;
    if (event.key === 'ArrowUp' && rowIndex > 0) rowIndex--;
    else if (event.key === 'ArrowDown' && rowIndex < this.objList.length - 1)
      rowIndex++;
    else return;

    event.preventDefault();

    this.rowIndex = rowIndex;
    const objName = this.objList[this.rowIndex].name;
    const table = document.getElementById(
      'table_device_parameters'
    ) as HTMLTableElement;

    const input = this.getInputByName(objName);
    if (input && this.isInputSelected) {
      if (this.isElementOutOfView(input, table)) this.scrollRowIntoView(input);
      this.focusOnInput(objName);
    } else {
      const rowElement = this.getRowByName(objName);
      if (rowElement) {
        rowElement.focus({ preventScroll: true });
        if (this.isElementOutOfView(rowElement, table))
          this.scrollRowIntoView(rowElement);
      }
    }

    this.setComment(objName);
  }

  // Focus on the input element and set selection range
  public focusOnInput(name: string): void {
    if (this.comment.name === name) return;

    const input = this.getInputByName(name);
    if (input) {
      input.focus({ preventScroll: true });
      input.setSelectionRange(0, input.value.length);
    }
  }

  public filter() {
    let apply_categories: string[] = [];
    let list: ExtendedObj[] = [];
    list = this.objList;

    list = list.filter((x) => !this.notVisibleRow(x.permission));

    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i]) apply_categories.push(this.getCategories(i));
    }

    if (apply_categories.length > 0) {
      list = list.filter((x) => apply_categories.includes(x.category));
    }
    let search = (
      document.getElementById('search_objs_input')! as HTMLInputElement
    ).value
      .trim()
      .toLowerCase();
    if (search != '') {
      let tmp = search.split('|');
      let names: string[] = [];
      tmp.forEach((x) => {
        if (x != '') names.push(x.trim());
      });
      list = list.filter((x) => names.some((y) => x.name.includes(y)));
    }
    return list;
  }

  public setComment(str: string) {
    let tmp = this.commentsList.filter((x) => x.name == str);
    if (tmp.length == 1) {
      this.comment = tmp[0];
    } else {
      this.comment = new CommentObj('', '');
    }
  }
  private getCategories(index: number) {
    switch (index) {
      case 0:
        return 'INFO';
      case 1:
        return 'STATE';
      case 2:
        return 'GENERAL';
      case 3:
        return 'LOG';
      case 4:
        return 'TEMPERATURES';
      case 5:
        return 'VOLTAGES';
      case 6:
        return 'CURRENT';
      case 7:
        return 'SOC';
      case 8:
        return 'SOH';
      case 9:
        return 'CANBUS';
      case 10:
        return 'CHARGER';
      case 11:
        return 'CLUSTER';
      case 12:
        return 'VIASAT';
      case 13:
        return 'CLIMATIC';
      default:
        return 'INFO';
    }
  }
  public command(command: Command = new Command('', [false, false])) {
    this.is_active = command.active;
    switch (command.command) {
      case 'save':
        this.saveFile();
        this.is_active[1] = false;
        break;
      case 'import':
        break;
    }
  }
  public saveFile() {
    let obj = this.objList.filter((x) => x.name == 'sn');
    let name = obj.length == 1 ? obj[0].value : 'values';
    name = name != '' ? name : 'values';
    const xmlContent: string = this.devSet.fromExtendedObjToXMLStr(
      this.objList
    );

    const blob = new Blob([xmlContent], { type: 'text/xml' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.params';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public blueRow(permission: number) {
    return (
      (permission &
        (0x1 <<
          ((User.user_type_from_string_to_number(this.home.user_type) - 1) *
            2))) ==
      0
    );
  }
  public editableRow(permission: number) {
    return (
      (permission &
        (0x1 <<
          ((User.user_type_from_string_to_number(this.home.user_type) - 1) *
            2))) !=
      0
    );
  }
  public notVisibleRow(permission: number) {
    return (
      (permission &
        (0x2 <<
          ((User.user_type_from_string_to_number(this.home.user_type) - 1) *
            2))) ==
      0
    );
  }
  private resetReadVariablesInterval() {
    this.interval_list_variables = setInterval(() => {
      if (this.objList.length == 0) {
        // Retry to download battery parameters
        this.waitComment = 'Retry to download device parameters...';
        this.devMan.tools.listVariables(DeviceType.battery);
        return;
      }
      console.log('read.variables');
      this.readAllVariables();
    }, 10 * 1000);
  }
  private readAllVariables() {
    let listID = 0 + '..' + this.objList[this.objList.length - 1].id;
    this.devMan.tools.readVariable(
      DeviceType.battery,
      listID,
      this.objList.length.toString(),
      this.encodedDescriptionList
    );
    this.waitRead = true;
  }
  private clearWriteVariablesQueue() {
    if (this.waitWrite || this.writeVariablesQueue.length == 0) return;
    let cmd = this.writeVariablesQueue[0];
    this.devMan.tools.writeVariable(
      cmd.type,
      cmd.name,
      cmd.value.trim(),
      this.objList.length.toString(),
      this.encodedDescriptionList
    );
    this.waitWrite = true;
    this.atLeastOneWrite = true;
  }
  private isElementOutOfView(
    element: HTMLElement,
    container: HTMLElement
  ): boolean {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const isOutOfView =
      elementRect.bottom >= containerRect.top ||
      elementRect.top <= containerRect.bottom;

    return isOutOfView;
  }
  private scrollRowIntoView(row: HTMLElement) {
    row.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
