/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public_component/login/login.component';
import { RegistrationComponent } from './public_component/registration/registration.component';
import { ForgotPasswordComponent } from './public_component/forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { HomeMenuButtonComponent } from './home-menu-button/home-menu-button.component';
import { HomeMenuComponent } from './home-menu/home-menu.component';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { WarningComponent } from './warning/warning.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { BatteryListComponent } from './battery-list/battery-list.component';
import { ProfileComponent } from './user_setting/profile/profile.component';
import { ChangePasswordComponent } from './public_component/change-password/change-password.component';
import { AddDeviceComponent } from './add-device/add-device.component';
import { DeviceOptionsComponent } from './device-options/device-options.component';
import { BatteryGeneralComponent } from './battery/battery-general/battery-general.component';
import { PasswordComponent } from './user_setting/password/password.component';
import { BatteryChartComponent } from './battery/battery-chart/battery-chart.component';
import { BatteryInfoComponent } from './battery/battery-info/battery-info.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AccountDeleteComponent } from './public_component/account-delete/account-delete.component';
import { BatteryHistoryComponent } from './battery/battery-history/battery-history.component';
import { GeneralEventsComponent } from './battery/general-events/general-events.component';
import { CalendarComponent } from './calendar/calendar.component';
import { AdvancedGeneralComponent } from './advanced/advanced-general/advanced-general.component';
import { AdvancedOptionComponent } from './advanced/advanced-option/advanced-option.component';
import { ClockComponent } from './clock/clock.component';
import { XtermComponent } from './console/xterm/xterm.component';
import { TerminalComponent } from './console/terminal/terminal.component';
import { AnnularLayerComponent } from './step-indicator-view/annular-layer.component';
import { LineLayerComponent } from './step-indicator-view/line-layer.component';
import { StepIndicatorViewComponent } from './step-indicator-view/step-indicator-view/step-indicator-view.component';
import { AdvancedUserComponent } from './advanced/advanced-user/advanced-user.component';
import { LogAccessComponent } from './admin-area-tools/log-access/log-access.component';
import { DeviceSettingsComponent } from './advanced/device-settings/device-settings.component';
import { AccountDeletedComponent } from './public_component/account-deleted/account-deleted.component';
import { ManageUsersComponent } from './admin-area-tools/manage-users/manage-users.component';
import { DashboardComponent } from './admin-area-tools/dashboard/dashboard.component';
import { ManageDevicesComponent } from './admin-area-tools/manage-devices/manage-devices.component';
import { OptionComponent } from './option/option.component';
import { AdminDeviceOptionsComponent } from './admin-area-tools/admin-device-options/admin-device-options.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    HomeComponent,
    HomeMenuButtonComponent,
    HomeMenuComponent,
    DashboardMenuComponent,
    WarningComponent,
    DeviceListComponent,
    BatteryListComponent,
    ProfileComponent,
    ChangePasswordComponent,
    AddDeviceComponent,
    DeviceOptionsComponent,
    BatteryGeneralComponent,
    PasswordComponent,
    BatteryChartComponent,
    BatteryInfoComponent,
    AccountDeleteComponent,
    BatteryHistoryComponent,
    GeneralEventsComponent,
    CalendarComponent,
    AdvancedGeneralComponent,
    AdvancedOptionComponent,
    ClockComponent,
    XtermComponent,
    TerminalComponent,
    StepIndicatorViewComponent,
    AnnularLayerComponent,
    LineLayerComponent,
    AdvancedUserComponent,
    LogAccessComponent,
    DeviceSettingsComponent,
    AccountDeletedComponent,
    ManageUsersComponent,
    DashboardComponent,
    ManageDevicesComponent,
    OptionComponent,
    AdminDeviceOptionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleMapsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
