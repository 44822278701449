<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
-->
<div class="block">
  <div>
    <div class="list_message">Total logs: {{ this.events.length }}</div>
    <div class="list_message date_message">{{ this.date }}</div>
    <app-calendar
      *ngIf="this.calendar"
      (return)="
        this.valueDate = $event; this.rows_offset = 0; this.updateGeneralEvent()
      "
      (close)="this.calendar = $event"
      [value]="this.valueDate"
      [max]="this.max_date"
    ></app-calendar>
    <app-dashboard-menu
      class="dashboard_menu"
      [mark_color]="'#50a17c'"
      [button_active]="'multi'"
      [string_buttons]="this.menu_string_option"
      [icon_width]="['29px', '25px']"
      [icon_height]="['29px', '25px']"
      [icon_margin]="['-2px 0px 0px 0px', '0px 3px 0px 0px']"
      [icon_padding]="['0px 0px 0px 0px', '0px 0px 0px 0px']"
      [buttons]="this.menu_options"
      [icons]="this.menu_icons"
      [is_active]="[this.devMan.tools.wait_sync, this.calendar]"
      (response)="this.command($event)"
    ></app-dashboard-menu>
  </div>
  <div class="fixed_table">
    <table class="list">
      <thead>
        <tr>
          <th>Time</th>
          <th class="small_td">SW</th>
          <th class="small_td sw2_td">SW2</th>
          <th class="small_small_td soc_td">SOC</th>
          <th class="small_td cycles_td">Cycles</th>
          <th class="small_td current_td">Current</th>
          <th class="small_td voltage_td">Voltage</th>
          <th class="small_small_td voltage2_td">VMax</th>
          <th class="small_small_td voltage2_td">VMin</th>
          <th class="small_small_td temperature2_td">TMax</th>
          <th class="small_small_td temperature2_td">TMin</th>
          <th class="can_timeout">Can Timeout</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of this.event_filter(); let i = index">
          <td [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }">
            {{ this.event_date(event._time) }}
          </td>
          <td
            class="small_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ this.state(event.sw.toString(), 4) }}
          </td>
          <td
            class="small_td sw2_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ this.state(event.sw2.toString(), 4) }}
          </td>
          <td
            class="small_small_td soc_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ event.soc }}
          </td>
          <td
            class="small_td cycles_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ event.cycles }}
          </td>
          <td
            class="small_td current_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{
              event.current == "-"
                ? "-"
                : this.stringToNumber(event.current).toFixed(2)
            }}
          </td>
          <td
            class="small_td voltage_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{
              event.voltage == "-"
                ? "-"
                : this.stringToNumber(event.voltage).toFixed(2)
            }}
          </td>
          <td
            class="small_small_td voltage2_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{
              event.v_max == "-"
                ? "-"
                : this.stringToNumber(event.v_max).toFixed(2)
            }}
          </td>
          <td
            class="small_small_td voltage2_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{
              event.v_min == "-"
                ? "-"
                : this.stringToNumber(event.v_min).toFixed(2)
            }}
          </td>
          <td
            class="small_small_td temperature2_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ event.t_max }}
          </td>
          <td
            class="small_small_td temperature2_td"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ event.t_min }}
          </td>

          <td
            class="can_timeout"
            style="text-align: center"
            [ngStyle]="{ background: i % 2 != 0 ? 'rgb(60,60,60)' : '' }"
          >
            {{ event.canbus_timeout }}
          </td>
        </tr>
      </tbody>
      <tr class="last_row">
        <td>{{ this.range_rows }} / {{ this.events.length }}</td>
        <td></td>
        <td></td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.left.svg"
            alt=""
            (click)="this.change_offset(false)"
          />
        </td>
        <td class="switch_right">
          <img
            src="../../../assets/icon/arrow/chevron.right.svg"
            alt=""
            (click)="this.change_offset(true)"
          />
        </td>
      </tr>
    </table>
  </div>
</div>
