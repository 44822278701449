/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023-2024 Vincenzo Barbato (vincenzo.barbato@archimede-energia.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */
import {
  Component,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { AppDate } from 'src/model/app.date';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css'],
})
export class ClockComponent implements AfterViewInit {
  @Input() min: Date | null = null;
  @Input() max: Date | null = null;

  @Input() value: Date = new Date();

  public _minutes: number = 0;
  public _hours: number = 0;
  @Output() return = new EventEmitter<Date>();
  @Output() close = new EventEmitter<boolean>();

  constructor() {}
  ngAfterViewInit(): void {
    this.setTimeLabel();
  }

  checkMinHours(){
    return this.min != null && AppDate.isSameDay(this.min, this.value)
  }

  checkMaxHours(){
    return this.max != null && AppDate.isSameDay(this.max, this.value)
  }

  public setTimeLabel() {
    let hours = document.getElementById('set_hours')! as HTMLInputElement;
    let minutes = document.getElementById('set_minutes')! as HTMLInputElement;

    hours.value = this.value.getHours().toString();
    minutes.value = this.value.getMinutes().toString();
  }

  public setTime() {
    let hours = document.getElementById('set_hours')! as HTMLInputElement;
    let minutes = document.getElementById('set_minutes')! as HTMLInputElement;
    this._hours = Number(hours.value);
    this._minutes = Number(minutes.value);
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit() {
    let date = this.value;
    date.setHours(this._hours);
    date.setMinutes(this._minutes);
    this.return.emit(date);
    this.close.emit(false);
  }
}
