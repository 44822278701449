<!--
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 -->

<div id="body" *ngIf="this.app.pages.types[0]">
  <div class="logo">
    <img src="../../assets/logo-archimede-b.png" alt="" />
  </div>
  <div class="central_block">
    <div>
      <p class="title" *ngIf="!this.app.email_validation">Login</p>
      <p class="title" *ngIf="this.app.email_validation">Email validation</p>
    </div>
    <div class="form" (window:keypress)="this.keyDownFunction($event)">
      <div class="true_message" *ngIf="this.app.email_validation">
        <p>Email validated successfully</p>
      </div>
      <div *ngIf="!this.app.email_validation">
        <p class="label">Email address or Username</p>
        <p class="required">*</p>
        <input
          id="user"
          type="text"
          class="input"
          (input)="
            this.data_login.user_message = false; this.app.invalid_login = false
          "
        />
        <p class="message" *ngIf="this.data_login.user_message">
          This field is required
        </p>
        <p class="label">Password</p>
        <p class="required">*</p>
        <input
          id="password"
          type="password"
          class="input"
          (input)="
            this.data_login.password_message = false;
            this.app.invalid_login = false
          "
        />
        <p class="message" *ngIf="this.data_login.password_message">
          This field is required
        </p>
        <div class="check">
          <div class="checkbox">
            <input id="remember_me" type="checkbox" name="checkbox" />
          </div>
          <div class="checkbox_text">
            <p>Remember me</p>
          </div>
        </div>
        <div>
          <button class="submit" (click)="onSubmit()">Login</button>
        </div>
      </div>
    </div>
    <div id="f_password" *ngIf="!this.app.email_validation">
      <a
        [routerLink]="['/forgot_password']"
        (click)="this.app.page_access('forgot_password')"
        >Forgot password</a
      >
    </div>
    <div id="register" *ngIf="!this.app.email_validation">
      <button
        (click)="
          this.router.navigate(['/register']); this.app.page_access('register')
        "
      >
        Register for free
      </button>
    </div>
    <div *ngIf="this.app.email_validation">
      <button
        class="submit"
        style="width: 100%"
        (click)="
          this.app.email_validation = false; this.router.navigate(['/login'])
        "
      >
        Go to login
      </button>
    </div>
    <p class="version" *ngIf="!this.app.email_validation">
      {{ this.app.version }}
    </p>

    <app-warning
      [title]="'Warning'"
      [background_color]="'rgba(255, 86, 86, 0.8)'"
      [message]="['Invalid credential']"
      [warning]="this.app.invalid_login"
      (response)="this.app.invalid_login = $event"
    ></app-warning>
  </div>
  <div class="footer" *ngIf="!this.app.email_validation">
    <a href="https://www.archimede-energia.com/privacy-policy/" target="_blank"
      >Privacy Policy</a
    ><a>|</a
    ><a href="https://www.archimede-energia.com/cookie-policy/" target="_blank"
      >Cookie Policy</a
    >
  </div>
</div>
